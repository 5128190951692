import React, { useCallback, useEffect } from "react";
import { Button, Tooltip, Typography } from "antd";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { debounce } from "lodash";
import styled from "styled-components";

import { AllPatientsTable } from "./TableSection/AllPatientsTable";
import ACTIONS from "../store/action";
import { decodeNumbersForSearch } from "../utils/formaters";
import { GRAY_500, GRAY_900, WHITE } from "../constant/colors";
import { fontWeights, sizes } from "../constant/styles";
import ArrowBack from "../assets/svg/ArrowBack";

const { Text } = Typography;

const PHONE = "phone_number";
const SSN = "social_security_number";

const ContainerStyle = styled.div`
  margin: 0px 15px 0px 15px;
  position: relative;
  background: ${WHITE};
  border-radius: 10px;
  padding: 10px 15px 0px 15px;
`;

const BackButtonStyle = styled(Button)`
  background-color: transparent;
  border: none;
  padding: 0px;
`;

const SearchResultLblStyle = styled(Text)`
  font-size: ${sizes.medium_xlarge};
  font-weight: ${fontWeights.semibold};
  color: ${GRAY_900};
`;

const SearchResultValueStyle = styled(Text)`
  font-size: ${sizes.medium_xlarge};
  font-weight: ${fontWeights.regular};
  color: ${GRAY_500};
  margin-left: 5px;
`;

const formatSearchQuery = (searchText, searchBy) =>
  searchBy === SSN || searchBy === PHONE ? decodeNumbersForSearch(searchText) : searchText;

const SearchResultsComponent = ({ t, searchPatientAct }) => {
  const { search, state } = useLocation();
  const searchText = state?.searchText;

  const searchParams = new URLSearchParams(search);
  const searchBy = searchParams.get("searchBy");

  useEffect(() => {
    if (searchText && searchBy) {
      debouncedSearch(1, searchText, searchBy);
    }
  }, [searchText, searchBy]);

  const debouncedSearch = useCallback(
    debounce(
      (page, searchText, searchBy, sorter) =>
        searchPatientAct(page, formatSearchQuery(searchText, searchBy), searchBy, sorter),
      500
    ),
    []
  );

  return (
    <ContainerStyle>
      <Link to={`/`}>
        <Tooltip title={t("back")}>
          <BackButtonStyle type="text">
            <ArrowBack />
          </BackButtonStyle>
        </Tooltip>
      </Link>

      <div>
        <SearchResultLblStyle>{t("search_results")}:</SearchResultLblStyle>
        <SearchResultValueStyle>{searchText}</SearchResultValueStyle>
      </div>

      <AllPatientsTable
        isSearch={true}
        fetchOrders={(page, sorter) => debouncedSearch(page, searchText, searchBy, sorter)}
      />
    </ContainerStyle>
  );
};

const mapDispatchToProps = (dispatch) => ({
  searchPatientAct: (page, searchText, searchBy, sorter) =>
    dispatch(ACTIONS.searchPatientsAction(page, searchText, searchBy, sorter))
});

const SearchResults = compose(withTranslation(), connect(null, mapDispatchToProps))(SearchResultsComponent);
export { SearchResults };
