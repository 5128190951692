import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";

import ACTION from "../store/action";
import {
  selectIsFetching,
  selectFilterList,
  selectFilterListStatuses,
  selectIsFilterListFiltersModified,
  selectTableColumnsModified
} from "../store/selector";
import { AllPatientsTable } from "../component/TableSection/AllPatientsTable";
import { VisitScheduleTable } from "../component/TableSection/VisitScheduleTable";
import { HighPrioritiesTable } from "../component/TableSection/HighPrioritiesTable";
import { PendingRenewalTable } from "../component/TableSection/PendingRenewalTable";
import { AllApplicationsTable } from "../component/TableSection/AllApplicationsTable";
import { FilterListControls } from "../component/FilterList/FilterListControls";
import { ApiNames } from "../api/api";
import TableFilters from "../component/TableFilters";
import { selectedViews } from "../constant/table";
import { FilterListForm } from "../component/FormPopups/FilterListForm";
import { AllClaimsTable } from "../component/TableSection/AllClaimsTable";
import ACTIONS from "../store/action";
import { WHITE } from "../constant/colors";
import { Skeleton } from "antd";

const DashboardScreen = styled.div`
  margin: 0px 15px 0px 15px;
  position: relative;
  background: ${WHITE};
  border-radius: 10px;
  padding: 10px 15px 0px 15px;
`;

const FilterSectionWrapper = styled.div`
  flex: 0 0 288px;
  max-width: ${(props) => {
    if (props.filtersVisible) {
      return "288px";
    }
    return "0px";
  }};
  transition: max-width 0.3s ease;
`;

const TableSectionWrapper = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`;

const DEFAULT_PAGE = 1;
const DEFAULT_SORTER = {};

const FilterListViewComponent = ({
  isFetching,
  searchSavedListByIdAct,
  filterList,
  filterListStatuses,
  markSavedFilterAsChanged,
  filterListFiltersModified,
  tableColumnsModified,
  updateSavedFilterViewParameters
}) => {
  const [filtersVisible, setFiltersVisible] = useState(false);
  const filterListId = parseInt(useParams().filterListId);
  const updateSavedFilterViewParametersAct = updateSavedFilterViewParameters(filterListId);
  useEffect(() => {
    if (filterListId && filterListStatuses.id !== filterListId) {
      //Note: Reset the filter parameters when changing the view to another filter page.
      updateSavedFilterViewParametersAct(DEFAULT_PAGE, DEFAULT_SORTER);
    }

    // Note: we need to fetch data always to make data consistent for the user.
    searchSavedListByIdAct(filterListId);

    if (filterListId) {
      // Note: Reset the filter modified status to mark it as not modified.
      markSavedFilterAsChanged(filterListId, false);
    }
  }, [filterListId]);

  useEffect(() => {
    if (filterList?.id && (tableColumnsModified || filterListFiltersModified)) {
      markSavedFilterAsChanged(filterList?.id, true);
    }
  }, [tableColumnsModified, filterListFiltersModified]);

  return (
    <DashboardScreen direction={"vertical"}>
      {!isFetching ? (
        <>
          <FilterListControls />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              transition: "all 0.3s ease"
            }}
          >
            <FilterSectionWrapper filtersVisible={filtersVisible}>
              <TableFilters
                filtersVisible={filtersVisible}
                setFiltersVisible={setFiltersVisible}
                showSaveNewListBtn={false}
                isFilterListView={true}
              />
            </FilterSectionWrapper>

            <TableSectionWrapper>
              {filterList?.selectedView === selectedViews.allPatients && (
                <AllPatientsTable
                  isSearch={false}
                  updateSavedFilterViewParameters={updateSavedFilterViewParametersAct}
                  isFilterListView={true}
                />
              )}
              {filterList?.selectedView === selectedViews.visitSchedule && (
                <VisitScheduleTable
                  updateSavedFilterViewParameters={updateSavedFilterViewParametersAct}
                  isFilterListView={true}
                />
              )}
              {filterList?.selectedView === selectedViews.pendingRenewal && (
                <PendingRenewalTable
                  updateSavedFilterViewParameters={updateSavedFilterViewParametersAct}
                  isFilterListView={true}
                />
              )}
              {filterList?.selectedView === selectedViews.highPriority && (
                <HighPrioritiesTable
                  updateSavedFilterViewParameters={updateSavedFilterViewParametersAct}
                  isFilterListView={true}
                />
              )}
              {filterList?.selectedView === selectedViews.allApplications && (
                <AllApplicationsTable
                  updateSavedFilterViewParameters={updateSavedFilterViewParametersAct}
                  isFilterListView={true}
                />
              )}
              {filterList?.selectedView === selectedViews.allClaims && (
                <AllClaimsTable
                  updateSavedFilterViewParameters={updateSavedFilterViewParametersAct}
                  isFilterListView={true}
                />
              )}
            </TableSectionWrapper>
            <FilterListForm />
          </div>
        </>
      ) : (
        <>
          <Skeleton.Button style={{ width: 100, height: 40 }} active />
          <Skeleton style={{ paddingTop: 16 }} active paragraph={{ rows: 6 }} />
          <Skeleton style={{ paddingTop: 32 }} active paragraph={{ rows: 6 }} />
        </>
      )}
    </DashboardScreen>
  );
};

const mapDispatchToProps = (dispatch) => ({
  searchSavedListByIdAct: (filterListId) => dispatch(ACTION.searchSavedListByIdAction(filterListId)),
  markSavedFilterAsChanged: (filterListID, isModified) =>
    dispatch(ACTIONS.markSavedFilterAsChanged(filterListID, isModified)),
  updateSavedFilterViewParameters: (filterListID) => (page, sorter) =>
    dispatch(ACTION.updateSavedFilterViewParameters(filterListID, page, sorter))
});
const mapStateToProps = createStructuredSelector({
  isFetching: selectIsFetching([ApiNames.FindSavedListById]),
  filterList: selectFilterList,
  filterListStatuses: selectFilterListStatuses,
  filterListFiltersModified: selectIsFilterListFiltersModified,
  tableColumnsModified: selectTableColumnsModified
});

const FilterListView = compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(FilterListViewComponent);
export { FilterListView };
