import React, { useEffect } from "react";

import { Switch } from "react-router-dom";
import { SecureRoute } from "@okta/okta-react";
import { useIdleTimer } from "@tailormed/common-client/services/idleTimer";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { Layout } from "antd";

import { EnvironmentHeader } from "../component/EnvironmentHeader";
import { SearchResults } from "../component/SearchResults";
import Dashboard from "../screen/Dashboard";
import { Patient } from "../../src/screen/patient/Patient";
import { FilterListView } from "../../src/screen/FilterListView";
import { MainHeader } from "../component/MainHeader";
import ManagePatientDetails from "../screen/ManagePatientDetails";
import { TopOpportunities } from "../component/TopOpportunities";
import { PatientConfirmationAlertContextProvider } from "../context/PatientConfirmationAlertContext";
import { AppLoader } from "../component/AppLoader";
import { PatientReport } from "../screen/patient/PatientReport";
import CoverageConflicts from "../screen/CoverageConflicts";
import { handleReturnFromSleep, renewTokenTimer } from "@tailormed/common-client/services/auth";
import { selectUser, selectIDPConfig } from "../store/selector";
import { oktaConfig } from "../constant/okta";
import { ChromeExtensionListener } from "../component/ChromeExtensionListener";
import { BRAND_TURQUOISE, GRAY_50 } from "../constant/colors";
import { SidebarMenu } from "../component/SidebarMenu";
import { Uploader } from "../component/Uploader";

const { Header } = Layout;

const SecuredRouterComponent = ({ user, idpConfig }) => {
  useIdleTimer(user, idpConfig, oktaConfig.loginURI);

  useEffect(async () => {
    await renewTokenTimer(user, idpConfig, oktaConfig.loginURI);
    await handleReturnFromSleep(user, idpConfig, oktaConfig.loginURI);
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <AppLoader />
      <Switch>
        <SecureRoute path={"/patient/:patientId/journey/:journeyId/report"} component={PatientReport} />
        <SecureRoute path={"/"} component={SecuredRouterWithAppLayouts} />
      </Switch>
    </Layout>
  );
};

const SecuredRouterWithAppLayouts = () => (
  <PatientConfirmationAlertContextProvider>
    <EnvironmentHeader />
    <Layout style={{ minHeight: "100vh" }}>
      <SidebarMenu />
      <Layout style={{ backgroundColor: GRAY_50 }}>
        <Header
          style={{
            padding: 0,
            background: BRAND_TURQUOISE,
            height: "6px"
          }}
        />
        <MainHeader />
        <Switch>
          <SecureRoute
            path={"/patient/:patientId/journey/:journeyId/coverages/conflicts"}
            component={CoverageConflicts}
          />
          <SecureRoute path={"/"} component={Dashboard} exact />
          <SecureRoute path={"/patient/:patientId/journey/:journeyId/edit"} component={ManagePatientDetails} exact />
          <SecureRoute path={"/patient/:patientId/journey/:journeyId"} component={Patient} />
          <SecureRoute path={"/filterList/:filterListId"} component={FilterListView} exact />
          <SecureRoute path={"/top-opportunities/:notificationId"} component={TopOpportunities} />
          <SecureRoute path={"/search-results"} component={SearchResults} />
        </Switch>
        <Uploader />
      </Layout>
    </Layout>
    <ChromeExtensionListener />
  </PatientConfirmationAlertContextProvider>
);

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  idpConfig: selectIDPConfig
});

const SecuredRouter = connect(mapStateToProps, null)(SecuredRouterComponent);
export { SecuredRouter };
