import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { createStructuredSelector } from "reselect";
import { Select, Space, Typography } from "antd";
import styled from "styled-components";

import { selectPatientDetails, selectUser } from "../../../store/selector";
import { GRAY7 } from "../../../constant/colors";
import { updatePatientStatusAPI } from "../../../api/api";
import { useGetSetting } from "../../../hooks/getSetting";
import ErrorMessage from "../../customComponent/customMessages/ErrorMessage";
import SuccessMessage from "../../customComponent/customMessages/SuccessMessage";
import ACTIONS from "../../../store/action";

const { Text } = Typography;
const { Option } = Select;

const CustomSelect = styled(Select)`
  div.ant-select-selector {
    padding-left: 0 !important;
    padding-right: 12 !important;
    background-color: ${(props) =>
      props.style.hover || props.style.isFetching ? `${GRAY7} !important` : `transparent !important`};
  }
  ${(props) => ({ ...props.inputStyle })};
`;

const PatientStatusComponent = ({
  updatePatientDetailsAct,
  t,
  user,
  patientId,
  patientDetails,
  selectorId,
  inputStyle,
  secondaryTextStyle
}) => {
  const [hover, setHover] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [statuses] = useGetSetting(["statuses"]);
  const { status } = patientDetails || {};

  const updateStatus = async (status) => {
    try {
      setIsFetching(true);
      const result = await updatePatientStatusAPI(patientId, status);
      if (result && result.data && result.data.success) {
        SuccessMessage(t("successMessages.updated_patient_status_successfully"));
        updatePatientDetailsAct({ ...patientDetails, status });
      } else {
        ErrorMessage(t("errorMessages.failed_to_update_patient_status"));
      }
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Space direction="vertical" size={0} style={{ width: "100%" }}>
      <Text style={secondaryTextStyle}>{t("patientTopSection.status")}</Text>
      <div id={selectorId}>
        <CustomSelect
          loading={isFetching}
          value={status}
          bordered={false}
          dropdownMatchSelectWidth={false}
          style={{ ...inputStyle, hover, isFetching }}
          showArrow={hover || isFetching}
          onSelect={(status) => updateStatus(status)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          {statuses?.length &&
            statuses
              .filter((status) => !status.roleRequirement || status.roleRequirement == user?.role)
              .map((status) => (
                <Option key={status.value} value={status.value}>
                  {status.text}
                </Option>
              ))}
        </CustomSelect>
      </div>
    </Space>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updatePatientDetailsAct: (patientDetails) => dispatch(ACTIONS.updatePatientDetailsAction(patientDetails))
});

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  patientDetails: selectPatientDetails
});

const PatientStatus = compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(PatientStatusComponent);

export { PatientStatus };
