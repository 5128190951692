import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { PopupStyledModal } from "../customComponentNewDesign/CustomPopupModal";
import { OutlinedButton, PrimaryButton } from "../customComponent/Button";

import { CustomForm as Form } from "../customComponentNewDesign/CustomForm";

import { BENEFIT_TYPE } from "../../constant/coverages";
import InsuranceForm from "./InsuranceForm";

const AddNewCoveragePopupStyle = styled(PopupStyledModal)`
  width: 710px !important;
  .ant-modal-content {
    width: 710px;
  }
`;

const AddNewCoveragePopup = ({ showPopupRef, onCoverageAdded, coverages, validEbvPayers }) => {
  const [popupShown, setPopupShown] = useState(false);
  const [isAddCoverageDisable, setIsAddCoverageDisable] = useState(false);

  const [form] = Form.useForm();

  const { t } = useTranslation();
  const showPopup = () => setPopupShown(true);

  const handleCreateNewCoverageEvent = (values, isUnknown) => {
    const { payerName } = values;
    if (isUnknown) {
      onCoverageAdded({
        ...values,
        customPayer: payerName,
        isUnknown: isUnknown
      });
    } else {
      onCoverageAdded({ ...values });
    }
    setPopupShown(false);
    form.resetFields();
  };

  const onClose = () => {
    setIsAddCoverageDisable(false);
    setPopupShown(false);
    form.resetFields();
  };

  showPopupRef.current = showPopup;
  return (
    <AddNewCoveragePopupStyle
      open={popupShown}
      title={t("patientClassicCoverageDetailsOverview.add_new_coverage")}
      onCancel={onClose}
      footer={[
        <OutlinedButton key="cancelButton" onClick={onClose}>
          {t("managePatientDetails.cancel")}
        </OutlinedButton>,
        <PrimaryButton form="addCoverageForm" key="submit" htmlType="submit" disabled={isAddCoverageDisable}>
          {t("patientClassicCoverageDetailsOverview.add_coverage")}
        </PrimaryButton>
      ]}
      destroyOnClose={true}
    >
      <Form
        form={form}
        id="addCoverageForm"
        initialValues={{ benefitType: BENEFIT_TYPE.MEDICAL }}
        onFinish={handleCreateNewCoverageEvent}
      >
        <InsuranceForm
          form={form}
          validEbvPayers={validEbvPayers}
          initialValues={{
            benefitType: BENEFIT_TYPE.MEDICAL
          }}
          coveragesData={coverages}
          setIsAddCoverageDisable={setIsAddCoverageDisable}
        ></InsuranceForm>
      </Form>
    </AddNewCoveragePopupStyle>
  );
};

export default AddNewCoveragePopup;
