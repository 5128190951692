import React, { useEffect, useState } from "react";
import styled from "styled-components";
import download from "downloadjs";
import { Trans, useTranslation } from "react-i18next";
import { Button, Card, Col, Divider, Modal, Row, Skeleton, Space, Tooltip, Typography, Upload } from "antd";
import { CloudUploadOutlined, DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import { useFetch } from "../../../hooks/fetch";
import SuccessMessage from "../../../component/customComponent/customMessages/SuccessMessage";
import ErrorMessage from "../../../component/customComponent/customMessages/ErrorMessage";
import { fontWeights, sizes } from "../../../constant/styles";
import AttachmentPermissionsPopover from "../../AttachmentPermissionsPopover";
import { MAX_FILE_SIZE, MB, fileTypesAll } from "../../Uploader";
import {
  ApiBaseUrl,
  ApiNames,
  deleteApplicationAttachmentApi,
  downloadApplicationAttachmentApi,
  uploadApplicationAttachmentApi
} from "../../../api/api";
import ACTIONS from "../../../store/action";
import { ExpressEnrollButton, FileLink } from "./enrollTab/utils/components";
import { ApplicationButton } from "./enrollTab/utils/styledComponents";
import { selectApplicationAttachmentsData, selectIsFetching } from "../../../store/selector";
import { BLACK, GRAY_400, GRAY_500, GRAY_700, GRAY_900, PRIMARY_600, RED, WHITE } from "../../../constant/colors";

import { ReactComponent as NoAttachmentsIcon } from "../../../assets/svg/NoAttachmentsIcon.svg";
import { ReactComponent as QuestionMark } from "../../../assets/svg/question-mark.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/svg/error-msg-icon.svg";
import { dateFormatter } from "../../../utils/date";
import { getExpressEnrollmentMapping } from "../ApplicationFormEditor/configurations";
import egalePemfexyConfiguration from "../ApplicationFormEditor/Eagle/configurations";
import { useSelectedIsExpressEnrollFormOpen } from "../hooks";
import { DownloadButton } from "../../customComponent/DownloadButton/DownloadButton";

const { Text } = Typography;

const StyledButton = styled(Button)`
  padding: 0;
  font-weight: ${fontWeights.semibold};
  color: ${GRAY_500};
`;

const StyledText = styled(Text)`
  font-weight: ${fontWeights.semibold};
  color: ${GRAY_700};
`;

const NoAttachments = ({ t, isLoading, isUploading, onUpload }) => (
  <Card loading={isLoading}>
    <Row justify="center">
      <NoAttachmentsIcon alt={t("patientApplications.attachments.alt.no_attachments")} />
    </Row>
    <Row justify="center">
      <Col>
        <Text style={{ fontSize: sizes.large, fontWeight: fontWeights.semibold }}>
          {t("patientApplications.attachments.no_attachments")}
        </Text>
      </Col>
    </Row>
    <Row justify="center" style={{ marginTop: "1em" }}>
      <Col>
        <Upload customRequest={({ file }) => onUpload(file)} {...uploadProps(t)}>
          <UploadButton isLoading={isUploading} type="primary" t={t} />
        </Upload>
      </Col>
    </Row>
  </Card>
);

export const AttachmentLink = ({ attachmentId, attachmentUniqueId, fileName, maxLength }) => (
  <Space style={{ height: "100%" }} justify="center">
    <FileLink
      fileName={fileName}
      href={`${ApiBaseUrl}applicationattachments/view/${attachmentId}/${attachmentUniqueId}`}
      maxLength={maxLength}
    />
  </Space>
);

const uploadProps = (t) => ({
  name: "file",
  multiple: true,
  showUploadList: false,
  accept: fileTypesAll,
  beforeUpload: (_, fileList) => {
    fileList.map((file) => {
      if (file.size > MAX_FILE_SIZE) {
        const errorMessage = t("patientApplications.attachments.exceeds_file_size", { maxSize: MAX_FILE_SIZE / MB });
        ErrorMessage(errorMessage, { width: 500, textAlign: "left" });
        throw new Error(errorMessage);
      }
    });
  }
});

const formsAndInstructionsLink =
  "https://apps.irs.gov/app/picklist/list/formsInstructions.html?value=1040&criteria=formNumber&submitSearch=Find";
const aboutFormW2Link = "https://www.irs.gov/forms-pubs/about-form-w-2";

const CustomToolTip = ({ t, link }) => (
  <Tooltip
    placement="right"
    title={
      <div
        style={{ display: "flex", flexDirection: "column", fontWeight: fontWeights.semibold, fontSize: sizes.small }}
      >
        <Text>{t("patientApplications.attachments.express_enroll.no_attachments.tooltip_form_instruction")}</Text>
        <a href={link} target="_blank" rel="noreferrer">
          <Text style={{ color: PRIMARY_600, fontWeight: fontWeights.semibold, fontSize: sizes.small }}>{link}</Text>
        </a>
      </div>
    }
    color={WHITE}
    overlayInnerStyle={{ width: 343 }}
  >
    <QuestionMark />
  </Tooltip>
);

const UploadButton = ({ isLoading, t, ...props }) => {
  const isExpressEnrollForm = useSelectedIsExpressEnrollFormOpen();

  return isExpressEnrollForm ? (
    <ExpressEnrollButton disabled={isLoading} loading={isLoading} type="primary" style={{ minWidth: 134 }} {...props}>
      {!isLoading && (
        <>
          <CloudUploadOutlined style={{ fontSize: 20, paddingRight: 5 }} />
          {t("patientApplications.attachments.upload_pdf_file")}
        </>
      )}
    </ExpressEnrollButton>
  ) : (
    <ApplicationButton
      disabled={isLoading}
      loading={isLoading}
      type="primary"
      style={{ minWidth: 134 }}
      {...props}
      id="upload_file_btn"
    >
      {!isLoading && (
        <>
          <CloudUploadOutlined style={{ fontSize: 20, paddingRight: 5 }} />
          {t("patientApplications.attachments.upload_file")}
        </>
      )}
    </ApplicationButton>
  );
};

const EagleExpressEnrollBody = ({ uploadFile = () => {}, isUploading = false, showUpload, t }) => (
  <Space direction="vertical" style={{ whiteSpace: "pre-line", paddingBottom: 0 }}>
    <StyledText>{t("patientApplications.attachments.express_enroll.no_attachments.eagle.sub_title")}</StyledText>
    <StyledText style={{ paddingTop: "10px" }}>
      {t("patientApplications.attachments.express_enroll.no_attachments.eagle.sub_title_line_one")}
    </StyledText>
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Text style={{ color: GRAY_700 }}>
        {t("patientApplications.attachments.express_enroll.no_attachments.eagle.content_first_line")}
      </Text>
      <Text style={{ color: GRAY_700 }}>
        {t("patientApplications.attachments.express_enroll.no_attachments.eagle.content_second_line")}
      </Text>
    </div>
    {showUpload && (
      <Col>
        <Upload customRequest={({ file }) => uploadFile(file)} {...uploadProps(t)}>
          <UploadButton isLoading={isUploading} block={true} t={t} />
        </Upload>
      </Col>
    )}
    <Text style={{ color: GRAY_400 }}>{t("patientApplications.attachments.express_enroll.no_attachments.footer")}</Text>
  </Space>
);

const ExpressEnrollBody = ({ uploadFile = () => {}, isUploading = false, showUpload, t }) => (
  <Space direction="vertical" style={{ whiteSpace: "pre-line", paddingBottom: 150 }}>
    <Text style={{ color: GRAY_900 }}>
      {t("patientApplications.attachments.express_enroll.no_attachments.sub_title")}
    </Text>
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Text style={{ color: GRAY_700 }}>
        {t("patientApplications.attachments.express_enroll.no_attachments.content_first_line")}
      </Text>
      <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
        <Text style={{ color: GRAY_700 }}>
          {t("patientApplications.attachments.express_enroll.no_attachments.federal_tax_Return")}
        </Text>
        <CustomToolTip link={formsAndInstructionsLink} t={t} />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Text style={{ color: GRAY_700, paddingRight: 5 }}>
          {t("patientApplications.attachments.express_enroll.no_attachments.w_two_form")}
        </Text>
        <CustomToolTip link={aboutFormW2Link} t={t} />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Text style={{ color: GRAY_700, paddingRight: 5 }}>
          {t("patientApplications.attachments.express_enroll.no_attachments.prescription_optional")}
        </Text>
        <ESignatureToolTip>
          <QuestionMark />
        </ESignatureToolTip>
      </div>
    </div>
    {showUpload && (
      <Col>
        <Upload customRequest={({ file }) => uploadFile(file)} {...uploadProps(t)}>
          <UploadButton isLoading={isUploading} block={true} t={t} />
        </Upload>
      </Col>
    )}
    <Text style={{ color: GRAY_400 }}>{t("patientApplications.attachments.express_enroll.no_attachments.footer")}</Text>
  </Space>
);

export const ESignatureToolTip = ({ children, ...props }) => (
  <Tooltip
    arrowPointAtCenter
    placement="rightTop"
    title={
      <Trans
        i18nKey="patientApplications.attachments.express_enroll.no_attachments.prescription_tooltip"
        components={{
          bold: <strong />,
          br: <br />,
          span: <span style={{ color: BLACK, fontSize: sizes.small }} />
        }}
      />
    }
    color={WHITE}
    overlayInnerStyle={{ width: 416, padding: "9px 8px 13px 10px" }}
    {...props}
  >
    {children}
  </Tooltip>
);

const ExpressEnrollNoAttachments = ({ t, isUploading, onUpload, isEagleExpressEnroll = false }) => {
  const isExpressEnrollForm = useSelectedIsExpressEnrollFormOpen();

  return (
    <Space direction="vertical">
      {!isExpressEnrollForm && (
        <Text style={{ color: GRAY_900, fontWeight: fontWeights.semibold, fontSize: sizes.medium_large }}>
          {t("patientApplications.attachments.express_enroll.no_attachments.title")}
        </Text>
      )}
      {isEagleExpressEnroll ? (
        <EagleExpressEnrollBody uploadFile={onUpload} isUploading={isUploading} showUpload={true} t={t} />
      ) : (
        <ExpressEnrollBody uploadFile={onUpload} isUploading={isUploading} showUpload={true} t={t} />
      )}
    </Space>
  );
};

const DeleteModal = ({ isOpen, onBackClick, onSubmitClick, t }) => (
  <Modal
    open={isOpen}
    footer={[
      <Button key="back" onClick={onBackClick}>
        {t("patientApplications.attachments.delete_modal.cancel")}
      </Button>,
      <Button key="submit" type="danger" onClick={onSubmitClick} style={{ backgroundColor: RED }}>
        {t("patientApplications.attachments.delete_modal.submit")}
      </Button>
    ]}
  >
    <Space size={10} direction="vertical">
      <ErrorIcon />
      <Text style={{ fontWeight: fontWeights.semibold, fontSize: sizes.large }}>
        {t("patientApplications.attachments.delete_modal.title")}
      </Text>

      <div style={{ marginBottom: 10 }}>
        <Text style={{ color: GRAY_500 }}>{t("patientApplications.attachments.delete_modal.first_content")}</Text>
      </div>
      <Text style={{ color: GRAY_500 }}>{t("patientApplications.attachments.delete_modal.second_content")}</Text>
    </Space>
  </Modal>
);

const ATTACHMENT_NAME_MAX_LENGTH_TO_DISPLAY = 100;

const AttachmentItem = ({ fileName, createdAt, attachmentId, attachmentUniqueId, onDelete }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteAttachment, deletedAttachment, isDeleting, deleteError, resetDelete] =
    useFetch(deleteApplicationAttachmentApi);
  const [downloadAttachment, downloadedAttachment, isDownloading, downloadError, resetDownload] = useFetch(
    downloadApplicationAttachmentApi
  );
  const isExpressEnrollForm = useSelectedIsExpressEnrollFormOpen();
  const applicationAttachments = useSelector(selectApplicationAttachmentsData);
  const hasReadFilePermission = applicationAttachments?.canViewOrDownload;

  useEffect(() => {
    if (!deletedAttachment) {
      return;
    }
    onDelete(attachmentId);
    resetDelete();
    SuccessMessage(t("patientApplications.attachments.success.delete"));
  }, [deletedAttachment]);

  useEffect(() => {
    if (deleteError) {
      ErrorMessage(t("patientApplications.attachments.error.delete"));
    }
  }, [deleteError]);

  useEffect(() => {
    if (!downloadedAttachment) {
      return;
    }
    download(downloadedAttachment, fileName);
    resetDownload();
    SuccessMessage(t("patientApplications.attachments.success.download"));
  }, [downloadedAttachment]);

  useEffect(() => {
    if (downloadError) {
      ErrorMessage(t("patientApplications.attachments.error.download"));
    }
  }, [downloadError]);

  return (
    <Row align="space-between" justify="center">
      <Col>
        <AttachmentLink
          attachmentId={attachmentId}
          attachmentUniqueId={attachmentUniqueId}
          fileName={fileName}
          maxLength={ATTACHMENT_NAME_MAX_LENGTH_TO_DISPLAY}
        />
        <Text style={{ color: GRAY_500 }}>
          {t("patientApplications.attachments.uploaded_at")} {dateFormatter(createdAt)}
        </Text>
      </Col>
      <Col span={isExpressEnrollForm ? 6 : 4}>
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <AttachmentPermissionsPopover>
            <DownloadButton
              onClick={() => downloadAttachment(attachmentId, attachmentUniqueId)}
              type="text"
              icon={<DownloadOutlined />}
              disabled={!hasReadFilePermission}
              isLoading={isDownloading}
              buttonComponent={StyledButton}
            />
          </AttachmentPermissionsPopover>
          <Divider type="vertical" />
          <AttachmentPermissionsPopover>
            <StyledButton
              type="text"
              icon={<DeleteOutlined />}
              loading={isDeleting}
              disabled={!hasReadFilePermission}
              onClick={() => setIsModalOpen(true)}
            >
              {t("patientApplications.attachments.delete")}
            </StyledButton>
          </AttachmentPermissionsPopover>
        </Space>
      </Col>
      <Divider style={{ margin: "18px 0px 0px 0px" }} />
      <DeleteModal
        isOpen={isModalOpen}
        onBackClick={() => setIsModalOpen(false)}
        t={t}
        onSubmitClick={() => {
          deleteAttachment(attachmentId, attachmentUniqueId);
          setIsModalOpen(false);
        }}
      />
    </Row>
  );
};

const AttachmentsList = ({ attachments, onDelete }) => {
  return attachments.map((attachment) => {
    return (
      <AttachmentItem
        key={attachment.attachmentUniqueId}
        attachmentId={attachment.id}
        attachmentUniqueId={attachment.attachmentUniqueId}
        fileName={attachment.filename}
        createdAt={attachment.createdAt}
        onDelete={onDelete}
      />
    );
  });
};

const AttachmentTabSkeleton = () => <Skeleton active paragraph={{ rows: 3 }} />;

export default function PatientApplicationAttachments({
  applicationId,
  patientId,
  onListChange = () => {},
  hasExpressEnroll = false
}) {
  const [isInInitialLoading, setIsInInitialLoading] = useState(true);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const applicationAttachments = useSelector(selectApplicationAttachmentsData);
  const isFetchingAttachments = useSelector(selectIsFetching([ApiNames.ApplicationAttachments]));
  const expressEnrollMapping = getExpressEnrollmentMapping();
  const [uploadAttachment, uploadedAttachment, isUploading, uploadAttachmentError, resetUpload] =
    useFetch(uploadApplicationAttachmentApi);

  const uploadFile = (file) => uploadAttachment(applicationId, patientId, file);

  const attachments = applicationAttachments?.data["phi-attachments"];
  const hasAttachments = !!attachments?.length;
  const isEagleExpressEnroll = expressEnrollMapping?.formId === egalePemfexyConfiguration.formId;

  const isExpressEnrollForm = useSelectedIsExpressEnrollFormOpen();

  useEffect(() => {
    if (!applicationAttachments) {
      dispatch(ACTIONS.getApplicationAttachments(applicationId));
    } else {
      setIsInInitialLoading(false);
    }
  }, [applicationAttachments]);

  useEffect(() => {
    if (!uploadedAttachment) {
      return;
    }

    if (uploadedAttachment?.data?.status) {
      dispatch(ACTIONS.getApplicationAttachments(applicationId));
      resetUpload();
      SuccessMessage(t("patientApplications.attachments.success.upload"));
    } else if (uploadedAttachment?.data?.errors[0]?.message) {
      const errorMessage = uploadedAttachment?.data?.errors[0]?.message;
      ErrorMessage(t("patientApplications.attachments.error.upload_server", { errorMessage }));
    } else {
      ErrorMessage(t("patientApplications.attachments.error.upload_generic"));
    }
  }, [uploadedAttachment]);

  useEffect(() => {
    // We need to include this as well for 404 type errors from the server
    if (!uploadAttachmentError) {
      return;
    }
    ErrorMessage(t("patientApplications.attachments.error.upload_generic"));
  });

  useEffect(() => {
    setIsInInitialLoading(isFetchingAttachments);
  }, [isFetchingAttachments]);

  useEffect(() => {
    if (!attachments) {
      return;
    }
    onListChange(attachments?.length);
  }, [attachments]);

  return (
    <>
      {isInInitialLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <AttachmentTabSkeleton />
        </div>
      ) : (
        <>
          {!isExpressEnrollForm && (
            <Row align="middle" justify="space-between" style={{ marginBottom: "16px" }}>
              <Col span={8}>
                <Text
                  style={{
                    fontSize: sizes.large,
                    fontWeight: fontWeights.semibold,
                    display: hasAttachments ? "inline" : "none"
                  }}
                >
                  {t("patientApplications.attachments.application_attachments")}
                </Text>
              </Col>
              <Col span={3} style={{ textAlign: "right" }}>
                {hasAttachments && (
                  <Upload customRequest={({ file }) => uploadFile(file)} {...uploadProps(t)}>
                    <UploadButton isLoading={isUploading} block={true} t={t} />
                  </Upload>
                )}
              </Col>
            </Row>
          )}
          {hasAttachments ? (
            <div style={{ display: "flex", flexDirection: "column", gap: isExpressEnrollForm ? 0 : 22 }}>
              <AttachmentsList
                attachments={attachments}
                onListChange={onListChange}
                onDelete={() => {
                  dispatch(ACTIONS.getApplicationAttachments(applicationId));
                  setIsInInitialLoading(true);
                }}
              />

              {hasExpressEnroll && (
                <>
                  {!isEagleExpressEnroll &&
                    (isExpressEnrollForm ? (
                      <ExpressEnrollBody uploadFile={uploadFile} isUploading={isUploading} showUpload={true} t={t} />
                    ) : (
                      <ExpressEnrollBody t={t} />
                    ))}

                  {isEagleExpressEnroll &&
                    (isExpressEnrollForm ? (
                      <EagleExpressEnrollBody
                        uploadFile={uploadFile}
                        isUploading={isUploading}
                        showUpload={true}
                        t={t}
                      />
                    ) : (
                      <EagleExpressEnrollBody t={t} />
                    ))}
                </>
              )}
            </div>
          ) : hasExpressEnroll ? (
            <ExpressEnrollNoAttachments
              t={t}
              isUploading={isUploading}
              onUpload={uploadFile}
              isEagleExpressEnroll={isEagleExpressEnroll}
            />
          ) : (
            <NoAttachments t={t} isLoading={isFetchingAttachments} isUploading={isUploading} onUpload={uploadFile} />
          )}
        </>
      )}
    </>
  );
}
