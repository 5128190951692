import React from "react";
import NovartisCopayPatient from "./form/formSections/Patient";
import NovartisCopayCondition from "./form/formSections/Condition";
import NovartisCopayEligibilityAndAttestation from "./form/formSections/EligibilityAndAttestation";
import NovartisCopaySupportOptions from "./form/formSections/SupportOptions";
import { useSelector } from "react-redux";
import { selectCurrentApplication, selectExpressEnrollmentConfiguration } from "../../../../../store/selector";

const SECTIONS = {
  patient: <NovartisCopayPatient />,
  condition: <NovartisCopayCondition />,
  eligibilityAndAttestation: <NovartisCopayEligibilityAndAttestation />,
  supportOptions: <NovartisCopaySupportOptions />
};

export const DRUGS = {
  COSENTYX: "cosentyx",
  TAFINLAR: "tafinlar",
  MEKINIST: "mekinist",
  PIQRAY: "piqray",
  KISQALI: "kisqali",
  SCEMBLIX: "scemblix"
};
const SECTION_NAME = {
  PATIENT: "patient",
  CONDITION: "condition",
  ELIGIBILITY_AND_ATTESTATION: "eligibilityAndAttestation",
  SUPPORT_OPTIONS: "supportOptions"
};

const getSectionConfig = (isEligibleForCombo = false) => {
  return {
    [DRUGS.COSENTYX]: [SECTION_NAME.PATIENT, SECTION_NAME.CONDITION, SECTION_NAME.ELIGIBILITY_AND_ATTESTATION],
    [DRUGS.TAFINLAR]: [
      ...(isEligibleForCombo ? [SECTION_NAME.SUPPORT_OPTIONS] : []),
      SECTION_NAME.PATIENT,
      SECTION_NAME.ELIGIBILITY_AND_ATTESTATION
    ],
    [DRUGS.MEKINIST]: [
      ...(isEligibleForCombo ? [SECTION_NAME.SUPPORT_OPTIONS] : []),
      SECTION_NAME.PATIENT,
      SECTION_NAME.ELIGIBILITY_AND_ATTESTATION
    ],
    [DRUGS.PIQRAY]: [SECTION_NAME.PATIENT, SECTION_NAME.ELIGIBILITY_AND_ATTESTATION],
    [DRUGS.KISQALI]: [SECTION_NAME.PATIENT, SECTION_NAME.CONDITION, SECTION_NAME.ELIGIBILITY_AND_ATTESTATION],
    [DRUGS.SCEMBLIX]: [SECTION_NAME.PATIENT, SECTION_NAME.ELIGIBILITY_AND_ATTESTATION]
  };
};
export const novartisSectionsController = () => {
  const currentApplication = useSelector(selectCurrentApplication);
  const associatedDrug = currentApplication?.drugDisplayName.toLowerCase();
  const expressEnrollmentConfiguration = useSelector(selectExpressEnrollmentConfiguration);

  // Function to get section keys by drug
  const getSectionKeysByDrug = (drug = associatedDrug) =>
    getSectionConfig(expressEnrollmentConfiguration?.isComboEligible)[drug] || [];

  // Function to get section components by drug
  const getSectionsByDrug = (drug = associatedDrug) => {
    const sectionKeys = getSectionKeysByDrug(drug);
    return sectionKeys.map((key) => SECTIONS[key]);
  };

  // Function to get sections based on drug
  const getNovartisCopaySections = (drug = associatedDrug) => {
    return getSectionsByDrug(drug);
  };
  return {
    getNovartisCopaySections: getNovartisCopaySections,
    getSectionsByDrug: getSectionKeysByDrug,
    associatedDrug: associatedDrug,
    drugsList: DRUGS,
    isEligibleForCombo: expressEnrollmentConfiguration?.isComboEligible
  };
};
