import React, { useState, useEffect } from "react";
import { useParams, useLocation, matchPath, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Space, Divider, Tabs, Skeleton, Typography, Button } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import styled from "styled-components";

import ACTIONS from "../../store/action";
import PatientTopSection from "../../component/Patient/PatientTopSection";
import { LIGHT_GRAY, WHITE } from "../../constant/colors";
import { Attachments } from "../../component/Patient/Attachments";
import { Tasks } from "../../component/Tasks/Tasks";
import {
  selectIsFetching,
  selectIsPhiAllowed,
  selectPatientOpportunity,
  selectPatientJourney,
  isCopyPastePopoverOpen,
  selectIsSidebarCollapsed
} from "../../store/selector";
import { ApiNames } from "../../api/api";
import { useGetSetting } from "../../hooks/getSetting";
import lazyLoadComponent from "../../component/LazyGenerator";

import "./Patient.css";

const LazyNotes = lazyLoadComponent(() => import("../../component/Notes"));

const LazyAddAssistanceProgramForm = lazyLoadComponent(() =>
  import("../../component/FormPopups/AddAssistanceProgramForm")
);
const LazyPatientJourney = lazyLoadComponent(() => import("../../component/Patient/PatientJourney/PatientJourney"));

const LazyPatientOverview = lazyLoadComponent(() => import("../../component/Patient/PatientOverview/PatientOverview"));

const LazyPatientCoverageDetails = lazyLoadComponent(() =>
  import("../../component/Patient/PatientCoverageDetails/PatientCoverageDetails")
);
const LazyPatientPotentialSavings = lazyLoadComponent(() =>
  import("../../component/Patient/PatientPotentialSavings/PatientPotentialSavings")
);

const LazyPatientPotantialSavingsTabHeader = lazyLoadComponent(() =>
  import("../../component/Patient/PatientPotentialSavings/PatientPotantialSavingsTabHeader")
);

const LazyPatientApplicationsView = lazyLoadComponent(() =>
  import("../../component/Patient/PatientApplications/PatientApplicationsView")
);
const { TabPane } = Tabs;
const { Text } = Typography;

const PatientScreen = styled.div`
  margin: 0 15px;
  position: relative;
  background: ${WHITE};
  border-radius: 10px;
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${(props) => (props.isSidebarCollapsed ? "10px 21px 10px 15px" : "10px 35px 10px 15px")};

  @media (min-width: 1600px) {
    padding: 10px 21px 10px 15px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: ${(props) => (props.isSidebarCollapsed ? "0 0 0 32px" : "0 0 0 20px")};
  }
`;

export const TabsMap = Object.freeze({
  overview: {
    key: "overview",
    tabPath: "overview",
    title: (t) => t("patientOverview.overview"),
    path: (patientId, journeyId) => `/patient/${patientId}/journey/${journeyId}`,
    component: <LazyPatientOverview />,
    display: (viewSetting) => viewSetting.includes(TabsMap.overview.key)
  },
  journey: {
    key: "journey",
    tabPath: "journey",
    title: (t) => t("patientOverview.patient_journey"),
    path: (patientId, journeyId) => `/patient/${patientId}/journey/${journeyId}/${TabsMap.journey.tabPath}`,
    component: <LazyPatientJourney />,
    display: (viewSetting) => viewSetting.includes(TabsMap.journey.key)
  },
  coverage_details: {
    key: "coverage",
    tabPath: "coverage",
    title: (t) => t("patientOverview.coverage_details"),
    path: (patientId, journeyId) => `/patient/${patientId}/journey/${journeyId}/${TabsMap.coverage_details.tabPath}`,
    component: <LazyPatientCoverageDetails />,
    display: (viewSetting) => viewSetting.includes(TabsMap.coverage_details.key)
  },
  optimizations: {
    key: "potentialSavings",
    tabPath: "optimizations",
    title: () => <LazyPatientPotantialSavingsTabHeader />,
    path: (patientId, journeyId) => `/patient/${patientId}/journey/${journeyId}/${TabsMap.optimizations.tabPath}`,
    component: <LazyPatientPotentialSavings />,
    display: (viewSetting) => viewSetting.includes(TabsMap.optimizations.key)
  },
  applications: {
    key: "applications",
    tabPath: "applications",
    title: (t) => <span id="enrollments_tab">{t("patientOverview.enrollments")}</span>,
    path: (patientId, journeyId) => `/patient/${patientId}/journey/${journeyId}/${TabsMap.applications.tabPath}`,
    component: <LazyPatientApplicationsView />,
    display: (viewSetting) => viewSetting.includes(TabsMap.applications.key)
  }
});

const PatientTopSectionSkelaton = () => (
  <div>
    <Space>
      <Skeleton.Button active size={"large"} />
      <Skeleton.Button active size={"large"} />
      <Skeleton.Button active size={"large"} />
      <Skeleton.Button active size={"large"} />
      <Skeleton.Button active size={"large"} />
    </Space>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        padding: 64
      }}
    >
      <Skeleton.Button style={{ width: 1150, height: 250 }} active size={"large"} />
    </div>
    <Skeleton active paragraph={{ rows: 10 }} />
  </div>
);

const PatientTabsSkeleton = () => (
  <div>
    <Skeleton active paragraph={{ rows: 10 }} />
  </div>
);

const PatientTabs = () => {
  const [activeKey, setActiveKey] = useState(TabsMap.overview.key);
  const patientOpportunity = useSelector(selectPatientOpportunity);
  const isPhiAllowed = useSelector(selectIsPhiAllowed);
  const { patientId, journeyId } = useParams();

  const [viewSetting] = useGetSetting(["fe.patient-page-view-settings"]);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const setFormPopupsAct = (visible, contentKey) => dispatch(ACTIONS.setFormPopups(visible, contentKey));

  useEffect(() => {
    const currTab = Object.values(TabsMap)
      .reverse()
      .filter((tab) => {
        return matchPath(pathname, {
          path: tab.path(patientId, journeyId),
          exact: false
        });
      });
    if (currTab && currTab.length && viewSetting.includes(currTab[0].key)) {
      setActiveKey(currTab[0].key);
      currTab[0].key !== activeKey && window.scrollTo(0, 0);
    }
  }, [pathname, viewSetting]);

  const onContactSupportClick = () => dispatch(ACTIONS.setShowSupport(true));

  const tabPanes = Object.values(TabsMap).map(
    ({ title, display, key, component }) =>
      display(viewSetting || "", {
        patientOpportunity
      }) && (
        <TabPane tab={title(t)} key={key}>
          {component}
        </TabPane>
      )
  );

  return (
    <Tabs
      activeKey={activeKey}
      onTabClick={(key) => {
        setFormPopupsAct(false, null);
        // NOTE: we want applications tab to show applications page from single app page
        if (key === activeKey && key !== TabsMap.applications.key) {
          return;
        }
        history.push(
          Object.values(TabsMap)
            .find((tab) => tab.key === key)
            .path(patientId, journeyId)
        );
      }}
      tabBarExtraContent={{
        right: (
          <Space size={10}>
            {isPhiAllowed && (
              <>
                <Attachments patientId={patientId} />
                <LazyNotes patientId={patientId} />
                <Tasks isPatientView={true} getPopupContainer={(trigger) => trigger.parentNode} />
              </>
            )}
            <Divider type="vertical" style={{ borderColor: LIGHT_GRAY }} />
            <Button type="text" onClick={onContactSupportClick} style={{ padding: 0 }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <MessageOutlined style={{ fontSize: 16, paddingRight: 3 }} />
                <Text>{t("contact_support.title")}</Text>
              </div>
            </Button>
          </Space>
        )
      }}
    >
      {tabPanes}
    </Tabs>
  );
};

const Patient = () => {
  const dispatch = useDispatch();
  const { patientId, journeyId } = useParams();

  const patientJourney = useSelector(selectPatientJourney);
  const isCopyPastePopoverOpened = useSelector(isCopyPastePopoverOpen);

  const isFetchingPotentialSavings = useSelector(
    selectIsFetching([ApiNames.PotentialSavings, ApiNames.PotentialSavingsNonPhi])
  );
  const isFetching = useSelector(
    selectIsFetching([ApiNames.PatientDetails, ApiNames.PatientDetailsNonPhi, ApiNames.Settings, ApiNames.LoggedUser])
  );
  const isSidebarCollapsed = useSelector(selectIsSidebarCollapsed);

  const shouldShowTab = (patientJourney && !isFetchingPotentialSavings && !isFetching) || isCopyPastePopoverOpened;

  useEffect(() => {
    return function cleanup() {
      dispatch(ACTIONS.clearPatient());
    };
  }, []);

  useEffect(() => {
    if (!patientId || !journeyId) return;
    dispatch(ACTIONS.getPatientAction(patientId, journeyId));
    // Scroll to top when component fetches data
    window.scrollTo(0, 0);
  }, [patientId, journeyId]);

  return (
    <PatientScreen isSidebarCollapsed={isSidebarCollapsed}>
      {!isFetching ? <PatientTopSection /> : <PatientTopSectionSkelaton />}
      {shouldShowTab ? <PatientTabs /> : <PatientTabsSkeleton />}
      <LazyAddAssistanceProgramForm />
    </PatientScreen>
  );
};

export { Patient };
