import _ from "lodash";

import { calculateAge } from "../../../utils";
import { getConditionOptions } from "./conditionOptions";

const DEFAULT_PHONE_TYPE = "mobile";

const hasCommonIcdCode = (array1, array2) => {
  return _.intersection(array1, array2).length > 0;
};

export const getNovartisCopayFormInitialValues = (applicationForm) => {
  const formData = applicationForm?.formData ?? {};

  const patientState = formData?.patient?.state === "" ? null : formData?.patient?.state;

  /**
   * This function determines the primary condition of a patient based on their diagnoses,
   * age, associated drug, and checks if the form contains related options using the getConditionOptions function.
   *
   * getConditionOptions returns the options based on the selected drug and the patient age (under or above 18).
   *
   * @returns {string|undefined} The primary condition if found, otherwise undefined.
   */
  const getPrimaryCondition = () => {
    const sortedDiagnosesNewerFirst = formData?.condition?.diagnoses?.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    const patientDiagnoses = sortedDiagnosesNewerFirst?.map((obj) => obj.icd);
    const isBelow18 = calculateAge(formData?.patient?.dob) < 18;
    const associatedDrug = formData?.patient?.patientDrugName?.toLowerCase();
    const conditionIndicationsAllowedByDrugAndAge = getConditionOptions(associatedDrug, isBelow18);
    if (patientDiagnoses?.length && conditionIndicationsAllowedByDrugAndAge?.length) {
      for (const icd of patientDiagnoses) {
        for (const diagnosis in diagnosisMap) {
          const { requiredIcdCodes, disallowedIcdCodes, allowedIcdCodes } = diagnosisMap[diagnosis];
          if (requiredIcdCodes.includes(icd)) {
            if (disallowedIcdCodes && hasCommonIcdCode(disallowedIcdCodes, patientDiagnoses)) {
              continue;
            }
            if (allowedIcdCodes && !hasCommonIcdCode(allowedIcdCodes, patientDiagnoses)) {
              continue;
            }
            if (conditionIndicationsAllowedByDrugAndAge.some((element) => element.value === diagnosis)) {
              return diagnosis;
            }
          }
        }
      }
    }

    return;
  };

  const initialValues = {
    ...formData,
    patient: {
      ...formData?.patient,
      firstName: formData?.patient?.firstName?.substring?.(0, 49),
      lastName: formData?.patient?.lastName?.substring?.(0, 49),
      address: formData?.patient?.address?.substring?.(0, 49),
      city: formData?.patient?.city?.substring?.(0, 29),
      state: patientState,
      phoneType: formData?.patient?.phoneType || DEFAULT_PHONE_TYPE,
      caregiver: {
        ...formData?.patient?.caregiver,
        phoneType: formData?.patient?.caregiver?.phoneType || DEFAULT_PHONE_TYPE
      }
    },
    condition: {
      ...formData.condition,
      primaryCondition: formData.condition?.primaryCondition || getPrimaryCondition()
    }
  };

  return initialValues;
};

/* eslint-disable */
const diagnosisMap = Object.freeze({
  "Enthesitis Arthritis": { 
    requiredIcdCodes: ["M08.8", "M08.80", "M08.81", "M08.82", "M08.83", "M08.84", "M08.85", "M08.86", "M08.87", "M08.88", "M08.89"] 
  },
  "Juvenile Psoriatic Arthritis (JPsA)": { 
    requiredIcdCodes:  ["L40.54"] 
  },
  "Moderate to Severe Plaque Psoriasis": { 
    requiredIcdCodes:  ["L40.0"],
    disallowedIcdCodes: ["L40.50", "L40.51", "L40.53", "L40.59"]
  },
  "Hidradenitis Suppurativa": { 
    requiredIcdCodes:  ["L73.2"] 
  },
  "Psoriatic Arthritis": { 
    requiredIcdCodes:  ["L40.50", "L40.51", "L40.53", "L40.59"],
    disallowedIcdCodes: ["L40.0"]
  },
  "Moderate to Severe Plaque Psoriasis and Psoriatic Arthritis": { 
    requiredIcdCodes:  ["L40.0"],
    allowedIcdCodes: ["L40.50", "L40.51", "L40.53", "L40.59"]
  },
  "Ankylosing Spondylitis": { 
    requiredIcdCodes:  ["M45.0", "M45.1", "M45.2", "M45.3", "M45.4", "M45.5", "M45.6", "M45.7", "M45.8", "M45.9"] 
  },
  "Non-Radiographic Axial Spondyloarthritis": { 
    requiredIcdCodes:  ["M45.A0", "M45.A1","M45.A2","M45.A3","M45.A4","M45.A5","M45.A6","M45.A7","M45.A8","M45.AB"] 
  },
  "HR+/HER2- Metastatic Breast Cancer": { 
    requiredIcdCodes:  ["C50", "C50.0", "C50.01", "C50.011", "C50.012", "C50.019", "C50.02", "C50.021", "C50.022", "C50.029", "C50.1", "C50.11", "C50.111", "C50.112", "C50.119", "C50.12", "C50.121", "C50.122", "C50.129", "C50.2", "C50.21", "C50.211", "C50.212", "C50.219", "C50.22", "C50.221", "C50.222", "C50.229", "C50.3", "C50.31", "C50.311", "C50.312", "C50.319", "C50.32", "C50.321", "C50.322", "C50.329", "C50.4", "C50.41", "C50.411", "C50.412", "C50.419", "C50.42", "C50.421", "C50.422", "C50.429", "C50.5", "C50.51", "C50.511", "C50.512", "C50.519", "C50.52", "C50.521", "C50.522", "C50.529", "C50.6", "C50.61", "C50.611", "C50.612", "C50.619", "C50.62", "C50.621", "C50.622", "C50.629", "C50.8", "C50.81", "C50.811", "C50.812", "C50.819", "C50.82", "C50.821", "C50.822", "C50.829", "C50.9", "C50.91", "C50.911", "C50.912", "C50.919", "C50.92", "C50.921", "C50.922", "C50.929", "C79.81"]
  },
  "Pediatric Plaque Psoriasis": {
    requiredIcdCodes: ["L40.0"]
  }
});
