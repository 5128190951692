import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Checkbox, Col, Divider, Form, Input, InputNumber, Radio, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";

import { selectApplicationForm, selectExpressEnrollmentConfiguration } from "../../../../store/selector";
import { buildFieldValueMap } from "../../../../utils/form";
import { FormDividerText } from "./styledComponents";
import { GRAY_300 } from "../../../../constant/colors";
import IneligiblePatientWarning from "./IneligiblePatientWarning";
import { capitalizeEachFirstLetter } from "../../../../utils/string";
import { SiteOfTreatment } from "../Genentech/freeDrug/form/formSections/SiteOfTreatment";
import DateFormItem from "./DateFormItem";
import { ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME } from "../constants";
import { onlyWhitespacesRule } from "../utils";
import { digitsOnlyRule } from "../../../../utils/formValidation";

const { Option } = Select;

const ENABLE_BEHAVIOR_ALL = "all";
const ENABLE_BEHAVIOR_ANY = "any";

export const QUESTION_TYPE_DATE = "date";
const QUESTION_TYPE_CHOICE = "choice";
const QUESTION_TYPE_INPUT = "string";
const DECIMAL_TYPE_CHOICE = "decimal";
const CHECKED_ANSWER = "Checked";
// Special type for Genentech free drug specific reference element.
const REFERENCE_TYPE_CHOICE = "reference";

const MAX_RADIO_BUTTON_ANSWERS = 10;

const HTML_REGEX = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;

const onlyNumbersLinkIds = ["household-income-amount"];

const FieldsToKeep = Object.freeze({
  "genentech-free-drug": ["household-income-amount", "eligibility-reason", "household-size"]
});
// Link-id for the site of treatment question for the gene free drug questionnaire.
// It is needed to show the site of treatment question below the questionnaire.
const SITE_OF_TREATMENT_LINK_ID_GENE_FREE_DRUG = "site-of-treatment-2";
const shouldShowSiteOfTreatment = (item) => {
  return item.linkId === SITE_OF_TREATMENT_LINK_ID_GENE_FREE_DRUG;
};

export const getFormItemName = (formItemName) => [ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME, formItemName];
const QuestionTypes = {
  INPUT: QUESTION_TYPE_INPUT,
  DATE: QUESTION_TYPE_DATE,
  DECIMAL: DECIMAL_TYPE_CHOICE,
  REFERENCE: REFERENCE_TYPE_CHOICE
};
const QUESTIONS_WITHOUT_LOGIC_CONFLICTS = [
  QuestionTypes.INPUT,
  QuestionTypes.DATE,
  QuestionTypes.DECIMAL,
  QuestionTypes.REFERENCE
];

const checkIsSameQuestion = (question, dependentLinkId, expectedValue) => {
  const isSameLinkId = doesStringMatch(question.linkId, dependentLinkId);
  if (!isSameLinkId) return false;

  if (QUESTIONS_WITHOUT_LOGIC_CONFLICTS.includes(question.type)) return true;

  const isChoiceType = doesStringMatch(question.type, QUESTION_TYPE_CHOICE);
  const foundQuestion =
    isChoiceType &&
    question.enableWhen.find(
      ({ answerString, answerCoding }) =>
        (answerString && doesStringMatch(answerString, expectedValue)) ||
        (answerCoding && doesStringMatch(answerCoding.code, expectedValue))
    );

  return !!foundQuestion;
};

export const findQuestionByCondition = (questions, condition) => {
  return questions.find((question) => {
    return checkIsSameQuestion(question, condition.dependentLinkId, condition.expectedValue);
  });
};

export const findQuestionIndexByCondition = (questions, condition) => {
  return questions.findIndex((question) =>
    checkIsSameQuestion(question, condition.dependentLinkId, condition.expectedValue)
  );
};

export const doesStringMatch = (stringA, stringB) => stringA?.toLowerCase().trim() === stringB?.toLowerCase().trim();

export const enableQuestion = ({ condition, newEnabledQuestions, changedQuestionIndex, parsedQuestionnaire, form }) => {
  let isQuestionEnabled = true;
  const questionToEnable = findQuestionByCondition(parsedQuestionnaire, condition);
  // when enableBehavior is 'all' it means that if one condition doesn't apply - we don't need to enable the question
  if (ENABLE_BEHAVIOR_ALL === questionToEnable.enableBehavior && questionToEnable.enableWhen.length > 1) {
    isQuestionEnabled = questionToEnable.enableWhen.every((condition) => checkIfConditionApplies({ condition, form }));
  }
  if (!isQuestionEnabled) return;
  if (questionToEnable.type === QuestionTypes.REFERENCE) {
    newEnabledQuestions.push(questionToEnable);
    return;
  }
  newEnabledQuestions.splice(changedQuestionIndex + 1, 0, questionToEnable);
};

export const updateEnabledQuestions = ({
  dependencies,
  newValue,
  newEnabledQuestions,
  changedQuestionIndex,
  parsedQuestionnaire,
  form
}) => {
  dependencies.forEach(({ dependentLinkId, expectedValue }) => {
    // if the newValue matches the expected value we need to make sure the question is enabled
    const condition = { dependentLinkId, expectedValue };
    if (doesStringMatch(newValue, expectedValue)) {
      let existingEnabledQuestion = findQuestionByCondition(newEnabledQuestions, condition);

      // if the question isn't enabled - add it to the enabledQuestions
      if (!existingEnabledQuestion) {
        enableQuestion({
          condition,
          newEnabledQuestions,
          changedQuestionIndex,
          parsedQuestionnaire,
          form
        });
      }
      return;
    }

    // when the newValue doesn't match the expected value - make sure that the question is disabled
    let questionIndexToRemove = findQuestionIndexByCondition(newEnabledQuestions, condition);

    // if the question is enabled - removed it from the enabledQuestions
    if (questionIndexToRemove !== -1) {
      disableQuestion({
        newEnabledQuestions,
        dependentLinkId,
        condition,
        questionIndexToRemove,
        parsedQuestionnaire,
        form
      });
    }
  });
};

export const checkIfConditionApplies = ({ condition, form }) => {
  const expectedAnswer = condition.answerString || condition.answerCoding?.code;
  const dependentFormItemValue = form.getFieldValue(getFormItemName(condition.question));

  if (!dependentFormItemValue) {
    return false;
  }

  const isCorrectValue = doesStringMatch(expectedAnswer, dependentFormItemValue);
  return isCorrectValue;
};

const getFormItemStyle = (answerOptions) =>
  answerOptions.length > 2 ? { display: "flex", flexDirection: "column" } : {};

export const disableQuestion = ({
  newEnabledQuestions,
  condition,
  dependentLinkId,
  questionIndexToRemove,
  parsedQuestionnaire,
  form
}) => {
  const questionToRemove = findQuestionByCondition(newEnabledQuestions, condition);
  if (!questionToRemove.enableWhen) return;

  let shouldLeaveQuestionEnabled = false;
  // when enableBehavior is 'any' it means that if any of the other Condition apply - we don't need to disable the question
  if (ENABLE_BEHAVIOR_ANY === questionToRemove.enableBehavior) {
    shouldLeaveQuestionEnabled = questionToRemove.enableWhen.some((condition) =>
      checkIfConditionApplies({ condition, form })
    );
  }
  if (shouldLeaveQuestionEnabled) {
    return;
  }

  newEnabledQuestions.splice(questionIndexToRemove, 1);
  form.setFieldsValue(buildFieldValueMap([[getFormItemName(dependentLinkId), null]]));

  const currentQuestion = findQuestionByCondition(parsedQuestionnaire, condition);
  updateEnabledQuestions({
    dependencies: currentQuestion.dependencies,
    newValue: null,
    newEnabledQuestions,
    parsedQuestionnaire,
    form
  });
};

export const DynamicQuestionnaire = ({ DividerText, ...props }) => {
  const [hide, setHide] = useState(true);
  const [chosenQuestionnaire, setChosenQuestionnaire] = useState([]);

  const { t } = useTranslation();
  const expressEnrollmentConfiguration = useSelector(selectExpressEnrollmentConfiguration);

  const form = Form.useFormInstance();
  const questionnaireOption = Form.useWatch("questionnaireOption", form);

  useEffect(() => {
    if (questionnaireOption) {
      setHide(false);
      handleChange(questionnaireOption, false);
    }
  }, [questionnaireOption]);

  const expressEnrollmentQuestionnaireConfig = useMemo(() => {
    const chosenQuestionnaire = expressEnrollmentConfiguration?.expressEnrollmentQuestionnaire;
    // For the case when there is only one questionnaire we should set it by default
    // When we have multiple questionnaires we should set it when the user selects one and default to empty list
    if (chosenQuestionnaire.length === 1) {
      setChosenQuestionnaire(chosenQuestionnaire?.[0]?.questionnaire || []);
    }
    return chosenQuestionnaire || [];
  }, [expressEnrollmentConfiguration?.expressEnrollmentQuestionnaire]);
  const applicationForm = useSelector(selectApplicationForm);

  const handleChange = (chosenDrugName, resetFields = true) => {
    const chosenConfig = expressEnrollmentQuestionnaireConfig.find(({ drugName }) => drugName === chosenDrugName);
    setChosenQuestionnaire(chosenConfig?.questionnaire);

    if (resetFields) {
      const currentValues = form.getFieldsValue();
      const { eligibilityQuestionnaire = {} } = currentValues;

      const newEligibilityQuestionnaire = Object.keys(eligibilityQuestionnaire).reduce((result, key) => {
        if (FieldsToKeep[applicationForm?.applicationTemplateName]?.includes(key)) {
          result[key] = eligibilityQuestionnaire[key];
        } else {
          result[key] = undefined;
        }
        return result;
      }, {});

      form.setFieldsValue({
        eligibilityQuestionnaire: newEligibilityQuestionnaire
      });
    }

    form.validateFields();
  };

  return (
    <div
      id={props?.dynamicQuestionnaireSectionId || "enrollment-form-eligibility_questionnaire"}
      style={{ display: "flex", flexDirection: "column" }}
    >
      {expressEnrollmentQuestionnaireConfig?.length === 1 ? (
        <>
          <Divider orientation="left" orientationMargin="0" style={{ color: GRAY_300 }}>
            <FormDividerText>{t(DividerText)}</FormDividerText>
          </Divider>
          <DynamicQuestionnaireComponent {...props} parsedQuestionnaire={chosenQuestionnaire} />
        </>
      ) : (
        <>
          <Divider orientation="left" orientationMargin="0" style={{ color: GRAY_300 }}>
            <FormDividerText>{t(DividerText)}</FormDividerText>
          </Divider>
          <Form.Item
            name={"questionnaireOption"}
            rules={[{ required: true }]}
            label={t(
              "application_form_editor.genentech.free_drug.eligibilityQuestionnaire.questionnaire_differentiation_question"
            )}
          >
            <Select showSearch onChange={handleChange} style={{ width: "100%" }}>
              {expressEnrollmentQuestionnaireConfig.map(({ drugName }, index) => (
                <Option key={index} value={drugName}>
                  {capitalizeEachFirstLetter(drugName)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <DynamicQuestionnaireComponent parsedQuestionnaire={chosenQuestionnaire} {...props} hide={hide} />
        </>
      )}
    </div>
  );
};

function DynamicQuestionnaireComponent({
  hide = false,
  ineligibleAnswerError,
  ineligibleCheckboxAnswerError,
  parsedQuestionnaire
}) {
  const [enabledQuestions, setEnabledQuestions] = useState([]);
  const applicationForm = useSelector(selectApplicationForm);
  const expressEnrollmentConfiguration = useSelector(selectExpressEnrollmentConfiguration);

  const form = Form.useFormInstance();
  const { t } = useTranslation();

  useEffect(() => {
    const data = applicationForm?.formData.eligibilityQuestionnaire;
    if (!data) {
      const filteredQuestions = parsedQuestionnaire.filter((question) => !question.enableWhen);
      setEnabledQuestions(filteredQuestions);
      return;
    }

    const filteredQuestions = parsedQuestionnaire.filter((question) => {
      if (!question.enableWhen) return true;
      let isEnabled;

      if (ENABLE_BEHAVIOR_ALL === question.enableBehavior) {
        isEnabled = question.enableWhen.every((condition) => checkIsQuestionEnabled(condition, data));
      } else if (ENABLE_BEHAVIOR_ANY === question.enableBehavior) {
        isEnabled = question.enableWhen.some((condition) => checkIfConditionApplies({ condition, form }));
      }

      return isEnabled;
    });
    setEnabledQuestions(filteredQuestions);
  }, [applicationForm?.formData, parsedQuestionnaire]);

  const checkIsQuestionEnabled = ({ question, operator, answerString, answerCoding }, data) => {
    const expectedAnswer = answerString || answerCoding?.code;
    const isCorrectOperator = operator === "=";
    const questionnaireAnswer = data?.[question?.toLowerCase().trim()];
    return isCorrectOperator && doesStringMatch(questionnaireAnswer, expectedAnswer);
  };

  const handleChange = useCallback(
    (changedQuestion, changedQuestionIndex) => () => {
      const formValues = form.getFieldsValue();
      const newValue = formValues.eligibilityQuestionnaire[changedQuestion.linkId];
      const newEnabledQuestions = JSON.parse(JSON.stringify(enabledQuestions));

      updateEnabledQuestions({
        dependencies: changedQuestion.dependencies,
        newValue,
        newEnabledQuestions,
        changedQuestionIndex,
        parsedQuestionnaire,
        form
      });

      setEnabledQuestions(newEnabledQuestions);
    },
    [parsedQuestionnaire, enabledQuestions]
  );

  // ineligibleQuestionnaireAnswers schema:
  //
  // "ineligibleQuestionnaireAnswers": {
  //   linkId: [ineligibile answers],
  //   linkId: [ineligibile answers]
  // }
  //
  // for example:
  // "ineligibleQuestionnaireAnswers": {
  //   "gazyva-fda-approved-indications": [
  //     "none-of-the-above",
  //     "unsure",
  //     "no"
  // ],
  // }
  const isEligibleAnswer = (linkId, questionType) => {
    const ineligibleAnswers = expressEnrollmentConfiguration?.ineligibleQuestionnaireAnswers || {};
    const currentAnswer = form.getFieldValue(getFormItemName(linkId));

    if (questionType === "checkbox" && !currentAnswer?.length) {
      return false;
    }

    const linkIdIneligibleAnswers = ineligibleAnswers[linkId];
    if (linkIdIneligibleAnswers && currentAnswer) {
      return (
        linkIdIneligibleAnswers.filter(
          (ineligibleAnswers) =>
            String(ineligibleAnswers).toLowerCase().trim() === String(currentAnswer)?.toLowerCase().trim()
        ).length === 0
      );
    }
    return true;
  };

  const eligibilityValidator = (linkId, questionType) => [
    {
      validator: async function () {
        if (!isEligibleAnswer(linkId, questionType)) {
          return Promise.reject();
        }
      },
      message: ""
    }
  ];

  useEffect(() => {
    form.validateFields();
  }, [enabledQuestions]);

  const parseHtmlString = (htmlString) => {
    const sanitizedHtml = DOMPurify.sanitize(htmlString, { ADD_ATTR: ["target"], USE_PROFILES: { html: true } });
    return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
  };

  return (
    <div>
      {hide ? (
        <></>
      ) : (
        enabledQuestions.map((item, index) => {
          let isHtml = HTML_REGEX.test(item.text);
          // html strings will add the asterisk after the div, and it doesn't look good. Add asterisk inside the html item.text if needed.
          const itemLabel = parseHtmlString(item.required && !isHtml ? item.text + " *" : item.text);
          const itemPlacholder = `Enter ${item.text}`;
          if (item.type === "choice") {
            if (item.answerOption.length > MAX_RADIO_BUTTON_ANSWERS) {
              return (
                <Form.Item label={itemLabel} key={index}>
                  <Form.Item
                    noStyle
                    key={item.linkId}
                    name={getFormItemName(item.linkId)}
                    rules={[{ required: item.required }, ...eligibilityValidator(item.linkId, item.type)]}
                  >
                    <Select showSearch onChange={handleChange(item, index)} style={{ width: "100%" }}>
                      {item.answerOption.map(({ valueString, valueCoding }) =>
                        valueCoding ? (
                          <Option key={valueCoding.code} value={valueCoding.code}>
                            {valueCoding.display}
                          </Option>
                        ) : (
                          <Option key={valueString} value={valueString}>
                            {valueString}
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                  {!isEligibleAnswer(item.linkId, item.type) && (
                    <IneligiblePatientWarning ineligibleAnswerError={ineligibleAnswerError} />
                  )}
                </Form.Item>
              );
            }
            return (
              <Form.Item label={itemLabel} key={index}>
                <Form.Item
                  noStyle
                  key={item.linkId}
                  name={getFormItemName(item.linkId)}
                  rules={[{ required: item.required }, ...eligibilityValidator(item.linkId, item.type)]}
                >
                  <Radio.Group onChange={handleChange(item, index)} style={getFormItemStyle(item.answerOption)}>
                    {item.answerOption.map(({ valueString, valueCoding }) =>
                      valueCoding ? (
                        <Radio key={valueCoding.code} value={valueCoding.code} style={{ marginBottom: 10 }}>
                          {valueCoding.display}
                        </Radio>
                      ) : (
                        <Radio key={valueString} value={valueString} style={{ marginBottom: 10 }}>
                          {valueString}
                        </Radio>
                      )
                    )}
                  </Radio.Group>
                </Form.Item>
                {!isEligibleAnswer(item.linkId, item.type) && (
                  <IneligiblePatientWarning ineligibleAnswerError={ineligibleAnswerError} />
                )}
              </Form.Item>
            );
          } else if (item.type === "string") {
            const rules = [{ required: item.required }, ...eligibilityValidator(item.linkId, item.type)];
            item.required && rules.push(onlyWhitespacesRule);
            if (onlyNumbersLinkIds.includes(item.linkId)) {
              rules.push(digitsOnlyRule(t));
            }

            return (
              <Form.Item label={itemLabel} key={index}>
                <Form.Item noStyle key={item.linkId} name={getFormItemName(item.linkId)} rules={rules}>
                  <Input placeholder={itemPlacholder} />
                </Form.Item>
                {!isEligibleAnswer(item.linkId, item.type) && (
                  <IneligiblePatientWarning ineligibleAnswerError={ineligibleAnswerError} />
                )}
              </Form.Item>
            );
          } else if (item.type === "checkbox") {
            return (
              <Form.Item label={itemLabel} key={index}>
                <Form.Item
                  noStyle
                  key={item.linkId}
                  name={getFormItemName(item.linkId)}
                  rules={[{ required: item.required }, ...eligibilityValidator(item.linkId, item.type)]}
                >
                  <Checkbox.Group>
                    <Row gutter={14}>
                      {item.answerOption.map(({ valueString, valueCoding }, checkboxIndex) => {
                        if (valueCoding) {
                          return (
                            <Col key={checkboxIndex}>
                              <Checkbox value={valueCoding.code}>{valueCoding.display}</Checkbox>
                            </Col>
                          );
                        } else {
                          return (
                            <Col key={checkboxIndex}>
                              {/*
                            Currently, this checkbox implementation uses a static answer ("Checked").
                            In the future, when non-static values will be required (multiple checkboxes etc),
                            a dynamic solution should be implemented.
                            We did not concatenate the question text, as it is very long, and can sometimes use html,
                            so a different approach needs to be taken, and it was out of scope, as of now.
                            */}
                              <Checkbox value={CHECKED_ANSWER}>
                                {isHtml ? parseHtmlString(valueString) : valueString}
                              </Checkbox>
                            </Col>
                          );
                        }
                      })}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
                {!isEligibleAnswer(item.linkId, item.type) && (
                  <IneligiblePatientWarning
                    ineligibleAnswerError={ineligibleCheckboxAnswerError || ineligibleAnswerError}
                  />
                )}
              </Form.Item>
            );
          } else if (item.type === QuestionTypes.REFERENCE) {
            // For Genentech free drug specific reference element Site of treatment we have to display it below
            // the questionnaire and to do so we have to check if the current item is the site of treatment question
            // and of reference type.
            if (shouldShowSiteOfTreatment(item)) {
              return <SiteOfTreatment />;
            }
          } else if (item.type === QuestionTypes.DATE) {
            return (
              <Form.Item label={itemLabel} key={index}>
                <DateFormItem
                  noStyle
                  key={item.linkId}
                  name={getFormItemName(item.linkId)}
                  rules={[{ required: item.required }, ...eligibilityValidator(item.linkId, item.type)]}
                />
              </Form.Item>
            );
          } else if (item.type === QuestionTypes.DECIMAL) {
            return (
              <Form.Item label={itemLabel} key={index}>
                <Form.Item
                  noStyle
                  key={item.linkId}
                  name={getFormItemName(item.linkId)}
                  rules={[{ required: item.required }, ...eligibilityValidator(item.linkId, item.type)]}
                >
                  <InputNumber placeholder={itemPlacholder} min={0} style={{ width: "100%" }} />
                </Form.Item>
              </Form.Item>
            );
          } else {
            return null;
          }
        })
      )}
    </div>
  );
}
