export const getPopupPlacement = (setPlacement, elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // if the bottom of the element is too close to the bottom of the screen, open the Popover upwards
    if (windowHeight - rect.bottom < 418) {
      setPlacement("left");
    } else {
      setPlacement("bottomRight");
    }
  }
};
