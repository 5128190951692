export const UNSPECIFIED_PAYER_NAME = "UNSPECIFIED_PAYER_NAME";
export const UNSPECIFIED_MEMBER_ID = "UNSPECIFIED_MEMBER_ID";
export const UNSPECIFIED_RX_MEMBER_ID = "UNSPECIFIED_RX_MEMBER_ID";

export const UNSPECIFIED_PAYER_POLICY = "UNSPECIFIED_PAYER_POLICY";
export const MANUAL_AUTOMATION_SOURCES = {
  FORCE_POKITDOK: "manually_update_coverage_from_pokidok",
  FORCE_BENEFIT_INVESTIGATION: "manually_force_running_the_step",
  MANUAL_REGULAR_AUTOMATION: "manual_regular_automation"
};
export const BENEFIT_TYPE = Object.freeze({
  MEDICAL: "medical",
  MEDICAL_AND_PRESCRIPTION: "medical_and_prescription",
  PRESCRIPTION: "prescription"
});

export const PRESCRIPTION_FIELDS = ["rxPlanName", "rxMemberId", "rxBin", "rxPcn", "rxGrp"];
export const MEDICAL_ONLY_FIELDS = ["memberId", "planName", "group"];

export const COVERAGE_FORM_ITEMS_NAMES = Object.freeze({
  MEMBER_ID: "memberId",
  RX_MEMBER_ID: "rxMemberId",
  PAYER_NAME: "payerName",
  BENEFIT_TYPE: "benefitType",
  PLAN_MARKET: "planMarket",
  PLAN_NAME: "planName",
  GROUP: "group",
  ACTIVE_DATE: "activeDate",
  RX_PLAN_NAME: "rxPlanName",
  RX_BIN: "rxBin",
  RX_PCN: "rxPcn",
  RX_GRP: "rxGrp"
});
