import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { Menu, Space, Tooltip, Typography } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectIsDevEnvironment } from "@tailormed/common-client/store/selector";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { PRIMARY_550 } from "../../constant/colors";
import { TailormedLogoIcon } from "../AppLayout";
import { ReactComponent as TailormedVisualIcon } from "../../assets/svg/TailormedVisualIcon.svg";
import { generalMenusItems, MenuItemKeys } from "./MenuItems";
import { history } from "../../router/history";
import {
  selectBadgeCounts,
  selectFiltersModified,
  selectFormPopups,
  selectSelectedView,
  selectShowSupport,
  selectFilterListStatuses
} from "../../store/selector";
import { Routes } from "../../constant/routes";
import { PatientConfirmationAlertContext } from "../../context/PatientConfirmationAlertContext";
import ACTIONS from "../../store/action";
import { defaultFilters } from "../../constant/filters";
import {
  CustomSider,
  LogoButton,
  LogoContainer,
  FinancialNavigationText,
  CollapsibleButton,
  MenuTitle,
  DisabledMenuItemText
} from "./Styles";
import { useGetSetting } from "../../hooks/getSetting";
import { ContactSupport } from "../../component/ContactSupport";
import AddNewPatientButton from "../addNewPatient/AddNewPatientButton";
import { formPopupsContentKeys } from "../../constant/formPopupsContentKeys";
import { selectedViews } from "../../constant/table";

const { Text } = Typography;
const SIDE_BAR_COLLAPSED = "sidebarCollapsed";
const ALL_ENROLLMENTS = "all-enrollments";

const SidebarMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const isDevEnvironment = useSelector(selectIsDevEnvironment);
  const selectedView = useSelector(selectSelectedView);
  const filtersModified = useSelector(selectFiltersModified);
  const filterListStatuses = useSelector(selectFilterListStatuses);
  const badgeCounts = useSelector(selectBadgeCounts);
  const formPopups = useSelector(selectFormPopups);
  const showContactSupport = useSelector(selectShowSupport);

  const isContactSupportVisible = showContactSupport.visible;

  const { showCancelConfirm } = useContext(PatientConfirmationAlertContext);

  const [collapsed, setCollapsed] = useState(() => {
    const storedValue = localStorage.getItem(SIDE_BAR_COLLAPSED);
    return storedValue ? JSON.parse(storedValue) : false;
  });
  const [selectedSubMenuKey, setSelectedSubMenuKey] = useState([]);

  const updateFilterListStatusesAct = (filterListID, canModify, showDiscardModal, nextSelectedView) =>
    dispatch(ACTIONS.updateFilterListStatuses(filterListID, canModify, showDiscardModal, nextSelectedView));
  const updateIsSidebarCollapsedAct = (value) => dispatch(ACTIONS.setIsSidebarCollapsed(value));

  const sideBarMenus = generalMenusItems(t, badgeCounts, dispatch, selectedView, filtersModified, useGetSetting);

  const updateSelectedSubMenuKey = (prevKeys, selectedKey) => {
    let keyToKeep = null;

    if (isContactSupportVisible) {
      keyToKeep = MenuItemKeys.help_center.subItems.contact_support;
    }

    if (formPopups.visible) {
      keyToKeep =
        formPopups.contentKey === formPopupsContentKeys.NOTIFICATIONS
          ? MenuItemKeys.home.subItems.notifications
          : formPopups.contentKey === formPopupsContentKeys.TASKS
          ? MenuItemKeys.home.subItems.tasks
          : null;
    }
    const updatedKeys = selectedKey ? [keyToKeep, selectedKey].filter(Boolean) : [keyToKeep].filter(Boolean);
    return updatedKeys.length ? updatedKeys : !isContactSupportVisible ? [] : prevKeys;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const selectedKey = queryParams.get("selectedView");

    setSelectedSubMenuKey((prevKeys) => {
      return updateSelectedSubMenuKey(prevKeys, selectedKey);
    });
  }, [search, formPopups, isContactSupportVisible]);

  useEffect(() => {
    localStorage.setItem(SIDE_BAR_COLLAPSED, JSON.stringify(collapsed));
    updateIsSidebarCollapsedAct(collapsed);
  }, [collapsed]);

  const handleDashboardClick = useCallback(() => {
    if (pathname.includes(Routes.NEW_PATIENT)) {
      return showCancelConfirm(Routes.ROOT);
    }

    if (pathname.includes(Routes.FILTER_LIST) && filterListStatuses?.canModify) {
      updateFilterListStatusesAct(filterListStatuses?.id, filterListStatuses.canModify, true, Routes.ROOT);
      return;
    }

    dispatch(ACTIONS.clearSorterAction(selectedView));
    filtersModified && dispatch(ACTIONS.setFiltersAction(defaultFilters));
    dispatch(ACTIONS.clearSelectedView());
    dispatch(ACTIONS.clearFiltersInputs());
    dispatch(ACTIONS.markSavedFilterAsChanged(undefined, false));
    history.push(Routes.ROOT);
  }, [pathname, filtersModified, filterListStatuses]);

  return (
    <>
      <CustomSider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        trigger={null}
        width="300px"
      >
        <LogoContainer direction="vertical">
          <Space>
            {!collapsed && (
              <LogoButton type="text" onClick={handleDashboardClick} id="tailormed_logo">
                <TailormedLogoIcon />
              </LogoButton>
            )}
          </Space>

          <LogoButton type="text" onClick={handleDashboardClick} id="financial_navigation">
            <Space style={{ position: "relative", bottom: collapsed ? 21 : 0 }}>
              <TailormedVisualIcon />
              {!collapsed && <FinancialNavigationText>{t("financial_navigation")}</FinancialNavigationText>}
            </Space>
          </LogoButton>
        </LogoContainer>

        <Menu mode="inline" id="side_menu" selectedKeys={selectedSubMenuKey}>
          {sideBarMenus.length
            ? sideBarMenus.map((menu) => (
                <Menu.ItemGroup
                  key={menu.key}
                  id={menu.key}
                  title={
                    <Tooltip title={menu.title} placement="right" overlayStyle={{ display: !collapsed && "none" }}>
                      <MenuTitle
                        collapsed={collapsed}
                        id={menu.key}
                        onClick={() => {
                          menu.onClick();
                        }}
                      >
                        {menu.icon}
                        {!collapsed && <Text>{menu.title}</Text>}
                        {menu.key === MenuItemKeys.patients.key && (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <AddNewPatientButton />
                          </div>
                        )}
                      </MenuTitle>
                    </Tooltip>
                  }
                >
                  {menu.subMenus
                    .filter((subMenu) => subMenu.display)
                    .map((subMenu) => (
                      <Menu.Item
                        icon={subMenu.icon}
                        key={subMenu.key}
                        onClick={() => {
                          subMenu.onClick();
                        }}
                        id={`${subMenu.key === selectedViews.allApplications ? ALL_ENROLLMENTS : subMenu.key}_sub_menu`}
                        disabled={subMenu.disabled}
                      >
                        {!collapsed &&
                          (subMenu.disabled ? (
                            <DisabledMenuItemText>{subMenu.title}</DisabledMenuItemText>
                          ) : (
                            <Text>{subMenu.title}</Text>
                          ))}
                      </Menu.Item>
                    ))}
                </Menu.ItemGroup>
              ))
            : []}
          {!collapsed && <Text id="footer">{t("footer")}</Text>}
        </Menu>
      </CustomSider>

      <CollapsibleButton
        type="text"
        id="collapse_sidebar_button"
        ghost
        icon={
          collapsed ? (
            <DoubleRightOutlined style={{ color: PRIMARY_550 }} />
          ) : (
            <DoubleLeftOutlined style={{ color: PRIMARY_550 }} />
          )
        }
        onClick={() => setCollapsed(!collapsed)}
        collapsed={collapsed}
        isDevEnvironment={isDevEnvironment}
      />
      <ContactSupport />
    </>
  );
};

export { SidebarMenu };
