const Routes = Object.freeze({
  ROOT: "/",
  LOGIN: "login",
  LOGOUT: "logout",
  PATIENTS: "patients/",
  SEARCH_PROGRAMS: "search-programs",
  CALLBACK: "/callback",
  PATIENT: "patient/",
  UPLOADER: "/uploader",
  SEARCH_RESULTS: "/search-results",
  NEW_PATIENT: "/new-patient",
  FILTER_LIST: "/filterList",
  CLAIMS: "claims",
  NEW_CLAIM: "new",
  EDIT_CLAIM: "edit",
  DUPLICATE_CLAIM: "duplicate",
  ORDER: "order",
  TOP_OPPORTUNITIES: "top-opportunities/"
});

export { Routes };
