import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Col, Row, Space, Typography, Radio, Modal, DatePicker } from "antd";
import _ from "lodash";
import { SingleSelect } from "../customComponentNewDesign/CustomSelect";
import { CustomInputWithTooltip } from "../customComponentNewDesign/CustomInputWithTooltip";
import { ReactComponent as WarningMark } from "../../assets/svg/warning-mark.svg";
import { PLAN_MARKETS_OPTIONS } from "../../constant/planMarkets";
import { DATE_FORMAT } from "../../utils/date";
import CustomSelectAutocomplete from "../customComponentNewDesign/CustomSelectAutocomplete";
import { getBenfitTypeOptions } from "../../utils/coverages";
import {
  BENEFIT_TYPE,
  PRESCRIPTION_FIELDS,
  MEDICAL_ONLY_FIELDS,
  COVERAGE_FORM_ITEMS_NAMES
} from "../../constant/coverages";
import { CustomForm as Form } from "../customComponentNewDesign/CustomForm";
import { ReactComponent as WarningIcon } from "../../assets/svg/warning-icon-popup.svg";
import { generateDefaultMemberID } from "../../utils/coverages";
import { ERROR_25, ERROR_700 } from "../../constant/colors";
import { ReactComponent as AlertCircle } from "../../assets/svg/alert-circle.svg";
const { Option } = SingleSelect;
const { Text } = Typography;

const marginBottom = "14px";

const ManullyPayerAlert = styled(Space)`
  color: #667085;
  align-items: flex-start;
  padding-bottom: 24px;
`;

const ManullyPayerAlertText = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
`;

const PrescriptionBenfitText = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 14px;
  margin-top: 8px;
  font-weight: 600;
  paragraph: 60px;
`;

const BenefitTypeFormItem = styled(Form.Item)`
  width: 100%;
  margin-bottom: ${marginBottom};
  .ant-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .ant-form-item-label {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    padding: 0px !important;
  }
  .ant-form-item-control {
    flex: 1;
    left: 24px !important;
  }
  .ant-form-item-required::before {
    display: none !important;
  }

  .ant-form-item-label > label::after {
    content: ":*" !important;
    display: inline-block !important;
  }
`;
const StayledFormItem = styled(Form.Item)`
  margin-bottom: ${marginBottom};

  .ant-form-item-required::before {
    display: none !important;
  }
  .ant-form-item-label > label::after {
    content: "*" !important;
  }
`;

const PlanMarketStyledFormItem = styled(StayledFormItem)`
  .ant-form-item-label > label::after {
    content: "*" !important;
    display: inline-block !important;
  }
`;

const InsuranceForm = ({
  form,
  validEbvPayers,
  initialValues,
  fieldNamePrefix = [],
  skipBenefitInvestigation,
  coveragesData,
  index,
  setIsAddCoverageDisable
}) => {
  const [isUnknown, setIsUnknown] = useState(initialValues?.customPayer);
  const benefitTypeWatch =
    Form.useWatch([...fieldNamePrefix, COVERAGE_FORM_ITEMS_NAMES.BENEFIT_TYPE], form) || initialValues?.benefitType;
  const [isMemberIdAndPayerNameConflict, setConflict] = useState(false);
  const { patientId } = useParams();
  const { t } = useTranslation();
  const benefitTypeOptions = getBenfitTypeOptions(t);

  const confirmDataLossModal = (newBenefitType, fieldsToClear) => {
    const dataLossType = newBenefitType === BENEFIT_TYPE.MEDICAL ? "prescription" : "medical";
    Modal.confirm({
      centered: true,
      icon: <WarningIcon />,
      title: t("patientClassicCoverageDetailsOverview.lose_filled_data_title"),
      content: t("patientClassicCoverageDetailsOverview.lose_filled_data_content", { benefitType: dataLossType }),

      okText: t("patientClassicCoverageDetailsOverview.lose_filled_data_confirmation"),
      cancelText: t("patientClassicCoverageDetailsOverview.lose_filled_data_cancel"),
      onOk: () => {
        clearFields(fieldsToClear);
        form.setFieldValue([...fieldNamePrefix, COVERAGE_FORM_ITEMS_NAMES.BENEFIT_TYPE], newBenefitType);
      },
      onCancel: () => {
        form.setFieldValue([...fieldNamePrefix, COVERAGE_FORM_ITEMS_NAMES.BENEFIT_TYPE], benefitTypeWatch);
      }
    });
  };

  const handleBenfitTypeChange = (newBenefitType) => {
    const isUserFilledFields = (fields) => {
      return fields.some((field) => form.getFieldValue([...fieldNamePrefix, field]));
    };
    if (isUserFilledFields(PRESCRIPTION_FIELDS) && newBenefitType === BENEFIT_TYPE.MEDICAL) {
      confirmDataLossModal(newBenefitType, PRESCRIPTION_FIELDS);
    } else if (isUserFilledFields(MEDICAL_ONLY_FIELDS) && newBenefitType === BENEFIT_TYPE.PRESCRIPTION) {
      confirmDataLossModal(newBenefitType, MEDICAL_ONLY_FIELDS);
    } else {
      form.setFieldValue([...fieldNamePrefix, COVERAGE_FORM_ITEMS_NAMES.BENEFIT_TYPE], newBenefitType);
    }
  };

  const clearFields = (fields) => {
    const updatedFields = fields.map((field) => ({
      name: [...fieldNamePrefix, field],
      value: null // Set value to null in order to send it to the server
    }));

    form.setFields(updatedFields);
  };

  const getCoverageType = () => {
    const insurancesListLength = coveragesData.length;
    switch (insurancesListLength) {
      case 0:
        return "primary";
      case 1:
        return "secondary";
      default:
        return "tertiary";
    }
  };

  const generateMemberId = (isRx) => {
    const fieldName = isRx ? COVERAGE_FORM_ITEMS_NAMES.RX_MEMBER_ID : COVERAGE_FORM_ITEMS_NAMES.MEMBER_ID;
    const coverageType = _.isInteger(index) ? coveragesData[index].coverageType : getCoverageType();
    const generatedMemberId = generateDefaultMemberID({ coverageType, patientId, isRx });
    form.setFieldValue([...fieldNamePrefix, fieldName], generatedMemberId);
  };

  const handleMemberIdBlur = (event) => {
    const { name, value } = event.target;
    if (!value) {
      const isRx = name === COVERAGE_FORM_ITEMS_NAMES.RX_MEMBER_ID;
      generateMemberId(isRx);
    }
  };

  const duplicateKeyValidator = useCallback(
    ({ fieldName, fieldValue, fieldToValidateWith }) => {
      const fieldValueToValidateWith = form.getFieldValue([...fieldNamePrefix, fieldToValidateWith]);

      if (coveragesData && fieldValueToValidateWith) {
        const isDuplicateValuesExist = coveragesData.some(
          (coverage, coverageIndex) =>
            (!_.isInteger(index) || index !== coverageIndex) &&
            coverage[fieldName] === fieldValue &&
            coverage[fieldToValidateWith] === fieldValueToValidateWith
        );
        if (isDuplicateValuesExist) {
          if (setIsAddCoverageDisable) {
            setIsAddCoverageDisable(true);
          }
          setConflict(true);
          return Promise.reject();
        } else {
          if (setIsAddCoverageDisable) {
            setIsAddCoverageDisable(false);
          }
          setConflict(false);
          form.setFields([{ name: [...fieldNamePrefix, fieldToValidateWith], errors: [] }]);

          return Promise.resolve();
        }
      }
      return Promise.resolve();
    },
    [coveragesData, index, fieldNamePrefix, form, setIsAddCoverageDisable, t]
  );

  const DuplicateMemberIdAndPayerErrorRow = () => (
    <div
      style={{
        backgroundColor: ERROR_25,
        padding: "16px",
        border: "1px solid #FDA29B",
        borderRadius: "8px",
        marginBottom: "8px",
        marginTop: "6px",
        display: "flex",
        alignItems: "center",
        gap: "12px"
      }}
    >
      <AlertCircle size={20} color={ERROR_700} />
      <span style={{ color: ERROR_700 }}>
        {t("patientClassicCoverageDetailsOverview.coverage_with_the_same_payer_name_and_member_ID")}
      </span>
    </div>
  );

  return (
    <>
      {isMemberIdAndPayerNameConflict && <DuplicateMemberIdAndPayerErrorRow />}
      <Row>
        <BenefitTypeFormItem
          label={t("patientClassicCoverageDetailsOverview.benefit_type")}
          name={[...fieldNamePrefix, COVERAGE_FORM_ITEMS_NAMES.BENEFIT_TYPE]}
          rules={[{ required: true, message: t("patientClassicCoverageDetailsOverview.choose_benefit_type") }]}
          required
        >
          <Radio.Group
            options={Object.keys(benefitTypeOptions).map((key) => ({ value: key, label: benefitTypeOptions[key] }))}
            defaultValue={BENEFIT_TYPE.MEDICAL}
            onChange={(e) => {
              const value = e.target.value;
              handleBenfitTypeChange(value);
            }}
          />
        </BenefitTypeFormItem>
      </Row>

      <Row gutter={[10, 0]} wrap={false}>
        <Col span={12}>
          <PlanMarketStyledFormItem
            label={t("patientClassicCoverageDetailsOverview.plan_market")}
            name={[...fieldNamePrefix, COVERAGE_FORM_ITEMS_NAMES.PLAN_MARKET]}
            rules={[{ required: true, message: t("patientClassicCoverageDetailsOverview.select_plan_market") }]}
            required
          >
            <SingleSelect
              showArrow={true}
              width="100%"
              showSearch={true}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeholder={t("patientClassicCoverageDetailsOverview.select_plan_market")}
            >
              {PLAN_MARKETS_OPTIONS?.map((planMarket) => (
                <Option key={planMarket.key} value={planMarket.value} id={`pop_up_${planMarket.id}`}>
                  {planMarket.translationText}
                </Option>
              ))}
            </SingleSelect>
          </PlanMarketStyledFormItem>
        </Col>
        <Col span={12}>
          <StayledFormItem
            label={t("patientClassicCoverageDetailsOverview.payer_name")}
            name={[...fieldNamePrefix, COVERAGE_FORM_ITEMS_NAMES.PAYER_NAME]}
            rules={[
              {
                validator: (_, value) =>
                  duplicateKeyValidator({
                    fieldName: COVERAGE_FORM_ITEMS_NAMES.PAYER_NAME,
                    fieldValue: value,
                    fieldToValidateWith: COVERAGE_FORM_ITEMS_NAMES.MEMBER_ID
                  })
              },
              {
                validator: (_, value) =>
                  duplicateKeyValidator({
                    fieldName: COVERAGE_FORM_ITEMS_NAMES.PAYER_NAME,
                    fieldValue: value,
                    fieldToValidateWith: COVERAGE_FORM_ITEMS_NAMES.RX_MEMBER_ID
                  })
              }
            ]}
          >
            <CustomSelectAutocomplete
              placeholder={t("patientClassicCoverageDetailsOverview.search_payer")}
              setIsCustomPayer={setIsUnknown}
              showArrow={true}
              width="100%"
            >
              {validEbvPayers.map((payer) => (
                <Option key={payer.name} value={payer.name}>
                  {payer.name}
                </Option>
              ))}
            </CustomSelectAutocomplete>
          </StayledFormItem>
        </Col>
      </Row>

      {isUnknown && !skipBenefitInvestigation && (
        <Row>
          <ManullyPayerAlert>
            <WarningMark />
            <ManullyPayerAlertText>{t("new_patient.other_payer_selected_message")}</ManullyPayerAlertText>
          </ManullyPayerAlert>
        </Row>
      )}

      <Row gutter={[10, 0]} wrap={false}>
        {benefitTypeWatch !== BENEFIT_TYPE.PRESCRIPTION && (
          <>
            <Col span={6}>
              <StayledFormItem
                label={t("patientCoverageDetails.plan_name")}
                name={[...fieldNamePrefix, COVERAGE_FORM_ITEMS_NAMES.PLAN_NAME]}
              >
                <CustomInputWithTooltip width="100%" placeholder="Enter plan name" />
              </StayledFormItem>
            </Col>
            <Col span={6}>
              <StayledFormItem
                label={t("patientClassicCoverageDetailsOverview.member_id")}
                name={[...fieldNamePrefix, COVERAGE_FORM_ITEMS_NAMES.MEMBER_ID]}
                rules={[
                  {
                    validator: (_, value) =>
                      duplicateKeyValidator({
                        fieldName: COVERAGE_FORM_ITEMS_NAMES.MEMBER_ID,
                        fieldValue: value,
                        fieldToValidateWith: COVERAGE_FORM_ITEMS_NAMES.PAYER_NAME
                      })
                  }
                ]}
              >
                <CustomInputWithTooltip
                  name={COVERAGE_FORM_ITEMS_NAMES.MEMBER_ID}
                  onBlur={handleMemberIdBlur}
                  width="100%"
                  placeholder="Ex. 8PW5RY9"
                />
              </StayledFormItem>
            </Col>
            <Col span={6}>
              <StayledFormItem
                label={t("patientClassicCoverageDetailsOverview.group_id")}
                name={[...fieldNamePrefix, COVERAGE_FORM_ITEMS_NAMES.GROUP]}
              >
                <CustomInputWithTooltip width="100%" placeholder="Ex. 435323" />
              </StayledFormItem>
            </Col>
          </>
        )}
        <Col span={6}>
          <StayledFormItem
            label={t("new_patient.effective_date")}
            name={[...fieldNamePrefix, COVERAGE_FORM_ITEMS_NAMES.ACTIVE_DATE]}
          >
            <DatePicker placeholder={DATE_FORMAT} format={DATE_FORMAT} style={{ width: "100%" }} size="large" />
          </StayledFormItem>
        </Col>
      </Row>
      {benefitTypeWatch !== BENEFIT_TYPE.MEDICAL && (
        <>
          <Row>
            <PrescriptionBenfitText level={4}>
              {t("patientClassicCoverageDetailsOverview.prescription_benefit")}
            </PrescriptionBenfitText>
          </Row>
          <Row gutter={[10, 0]} wrap={false}>
            <Col>
              <StayledFormItem
                label={t("patientClassicCoverageDetailsOverview.rx_plan_name")}
                name={[...fieldNamePrefix, COVERAGE_FORM_ITEMS_NAMES.RX_PLAN_NAME]}
              >
                <CustomInputWithTooltip width="100%" placeholder="Enter plan name" />
              </StayledFormItem>
            </Col>
            <Col>
              <StayledFormItem
                label={t("patientClassicCoverageDetailsOverview.rx_member_id")}
                name={[...fieldNamePrefix, COVERAGE_FORM_ITEMS_NAMES.RX_MEMBER_ID]}
                rules={[
                  {
                    validator: (_, value) =>
                      duplicateKeyValidator({
                        fieldName: COVERAGE_FORM_ITEMS_NAMES.RX_MEMBER_ID,
                        fieldValue: value,
                        fieldToValidateWith: COVERAGE_FORM_ITEMS_NAMES.PAYER_NAME
                      })
                  }
                ]}
              >
                <CustomInputWithTooltip
                  name={COVERAGE_FORM_ITEMS_NAMES.RX_MEMBER_ID}
                  onBlur={handleMemberIdBlur}
                  width="100%"
                  placeholder="Ex. 7PW5RY9"
                />
              </StayledFormItem>
            </Col>
            <Col>
              <StayledFormItem
                label={t("patientClassicCoverageDetailsOverview.rx_bin")}
                name={[...fieldNamePrefix, COVERAGE_FORM_ITEMS_NAMES.RX_BIN]}
              >
                <CustomInputWithTooltip width="100%" placeholder="Ex. 016499" />
              </StayledFormItem>
            </Col>
            <Col>
              <StayledFormItem
                label={t("patientClassicCoverageDetailsOverview.rx_pcn")}
                name={[...fieldNamePrefix, COVERAGE_FORM_ITEMS_NAMES.RX_PCN]}
              >
                <CustomInputWithTooltip width="100%" placeholder="Ex. 36511" />
              </StayledFormItem>
            </Col>
            <Col>
              <StayledFormItem
                label={t("patientClassicCoverageDetailsOverview.rx_group")}
                name={[...fieldNamePrefix, COVERAGE_FORM_ITEMS_NAMES.RX_GRP]}
              >
                <CustomInputWithTooltip width="100%" placeholder="Ex. 80840" />
              </StayledFormItem>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default InsuranceForm;
