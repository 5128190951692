import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Space, Table, Empty } from "antd";
import styled from "styled-components";

import { fontWeights, sizes } from "../../constant/styles";
import { selectAllClaimsData, selectFiltersModified, selectIsFetching, selectTableColumns } from "../../store/selector";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE, selectedViews } from "../../constant/table";
import { ColumnSelector } from "../ColumnSelector";
import {
  AssigneeColumn,
  ClaimMedicationsColumnComponent,
  ClaimStatusColumn,
  DateOfServiceColumn,
  PatientNameColumn,
  StarColumn,
  withSorting
} from "./CommonColumns";
import AllClaimsPageCount from "./ClaimsPageCount";
import { DATE_FORMAT, dateFormatter } from "../../utils/date";
import { formatPhoneNumber, formatSSN } from "../../utils/formaters";
import moment from "moment-timezone";
import { patientInsuranceCell, PatientStatusCell } from "../TableCells";
import { SUPPORTED_COVERAGES } from "../../constant/patient";
import { TailormedSpinner } from "../TailormedSpinner/TailormedSpinner";
import { ApiNames } from "../../api/api";
import { StyledGreenValue, StyledRedValue } from "../Patient/PatientApplications/PatientApplicationClaim/styles";
import ACTION from "../../store/action";
import { useHistory } from "react-router-dom";
import { Routes } from "../../constant/routes";
import { ColumnSelectorContainer, commonTableStyle } from "./TableStyle";
import { formatToDecimal } from "../../utils/formaters";
import { isNull } from "lodash";
import { DeceasedLable } from "../DeceasedLable";

const { Text } = Typography;
const StyledEmptyText = styled(Text)`
  color: rgba(0, 0, 0, 0.4);
  font-weight: ${fontWeights.regular};
  font-size: ${sizes.medium};
`;

const StyledTable = styled(Table)`
  ${(props) => commonTableStyle(props.isFilterListView)}
`;

const ClaimsTable = ({ updateSavedFilterViewParameters, isFilterListView }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tableColumns = useSelector(selectTableColumns);
  const isFetching = useSelector(selectIsFetching([ApiNames.AllClaimsPhiSearch, ApiNames.AllClaimsSearch]));
  const tableData = useSelector(selectAllClaimsData);
  const filtersModified = useSelector(selectFiltersModified);
  const setTableDataAct = (page, sorter) => dispatch(ACTION.paginateSortChange(page, selectedViews.allClaims, sorter));
  const totalRecordsCount = tableData?.totalPatients;
  const sortingEnabled = tableData?.sortingEnabled;
  const history = useHistory();
  const formatAmount = (amount) => (!isNull(amount) ? `$${formatToDecimal(amount) || 0}` : "");

  const columns = [
    {
      ...tableColumns[selectedViews.allClaims].star,
      width: 35,
      render: (text, record) => <StarColumn record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].name_id, {
      sorterTooltipText: t("columns.sortById"),
      render: (text, record) => <PatientNameColumn record={record} />
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.isDeceased, {
      render: (_, record) => (record.isDeceased ? <DeceasedLable /> : "")
    }),
    {
      ...tableColumns[selectedViews.allClaims]?.phone_number,
      render: (text, record) => formatPhoneNumber(record.phone_number || record.home_number)
    },
    {
      ...tableColumns[selectedViews.allClaims]?.dob,
      render: (text, record) => (moment(record.dob).isValid() ? moment(record.dob).format(DATE_FORMAT) : "")
    },

    {
      ...tableColumns[selectedViews.allClaims]?.ssn,
      render: (text, record) => formatSSN(record.social_security_number) || ""
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].claimDate, {
      render: (text, record) => record.claimDate && <div>{dateFormatter(record.claimDate)}</div>
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].claimStatus, {
      render: (text, record) => record.claimStatus && <ClaimStatusColumn status={record.claimStatus} />
    }),

    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].claimFollowupDate, {
      render: (text, record) => record.claimFollowupDate && dateFormatter(record.claimFollowupDate)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].claimSubmittedDate, {
      render: (text, record) => record.claimSubmittedDate && dateFormatter(record.claimSubmittedDate)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].serviceDate, {
      render: (text, record) => <DateOfServiceColumn record={record} />
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].claimFacility, {
      render: (text, record) => record.claimProvider || ""
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].providedBy, {}),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].assistanceProgramName, {}),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].apType, {}),

    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].claimTotalAmount, {
      render: (text, record) => <div>{formatAmount(record.claimTotalAmount)}</div>
    }),

    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].claimPaidAmount, {
      render: (text, record) => (
        <StyledGreenValue disableStyle={!record.claimPaidAmount}>
          {formatAmount(record.claimPaidAmount)}
        </StyledGreenValue>
      )
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].dateReceived, {
      render: (text, record) => record.claimReceivedDate && dateFormatter(record.claimReceivedDate)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].approvedDate, {
      render: (text, record) => record.approvedDate && dateFormatter(record.approvedDate)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].claimDeniedAmount, {
      render: (text, record) => (
        <StyledRedValue disableStyle={!record.claimDeniedAmount}>
          {formatAmount(record.claimDeniedAmount)}
        </StyledRedValue>
      )
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].ClaimPatientResponsibilityAmount, {
      render: (text, record) => <div>{formatAmount(record.ClaimPatientResponsibilityAmount)}</div>
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims]?.balance, {
      render: (test, record) => <div>{!isNull(record.claimTotalAmount) && formatAmount(record.balance)}</div>
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].fundBalance, {
      render: (text, record) => <div>{`$${formatToDecimal(record.fundBalance) || 0}`}</div>
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].checkNumber_ACH, {
      render: (test, record) => record.checkNumber || ""
    }),

    {
      ...tableColumns[selectedViews.allClaims].medications,
      render: (text, record) => <ClaimMedicationsColumnComponent record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].facility, {
      render: (text, record) => record.facility || ""
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].patientStatus, {
      render: (text, record) => <PatientStatusCell value={record.status} />
    }),

    { ...tableColumns[selectedViews.allClaims].assignee, render: (text, record) => <AssigneeColumn record={record} /> },
    { ...tableColumns[selectedViews.allClaims].drug },
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].physician, {
      render: (text, record) => record.physician || ""
    }),

    {
      ...tableColumns[selectedViews.allClaims].agreementSignatureDate,
      render: (text, record) =>
        moment(record.agreement_signature_date).isValid() && dateFormatter(record.agreement_signature_date)
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.followupDate, {
      render: (text, record) => record.followupDate && dateFormatter(record.followupDate)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].primaryInsurancePlan, {
      render: patientInsuranceCell(SUPPORTED_COVERAGES.primary)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].secondaryInsurancePlan, {
      render: patientInsuranceCell(SUPPORTED_COVERAGES.secondary)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].tertiaryInsurancePlan, {
      render: patientInsuranceCell(SUPPORTED_COVERAGES.tertiary)
    })
  ];

  const fetchData = (pagination, filters, sorter) => {
    if ((!tableData?.sorter?.columnKey && sorter.columnKey) || sorter.columnKey !== tableData?.sorter?.columnKey) {
      // Note: if user try sorting another column. fetch the first page data.
      pagination.current = 1;
    }

    setTableDataAct(pagination.current, sorter);
    if (updateSavedFilterViewParameters) {
      // Note: We need to maintain the last visited paging & sorting for each saved filter view.
      updateSavedFilterViewParameters(pagination.current, sorter);
    }
    window.scrollTo(0, 0);
  };
  return (
    <>
      {!!tableData?.patients.length && (
        <ColumnSelectorContainer>
          <ColumnSelector />
        </ColumnSelectorContainer>
      )}
      <StyledTable
        size="small"
        rowClassName="cursor-pointer"
        isFilterListView={isFilterListView}
        style={{ width: "100%" }}
        loading={{ indicator: <TailormedSpinner />, spinning: isFetching }}
        columns={columns.filter((col) => tableColumns[selectedViews.allClaims]?.[col.key]?.display)}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <Space direction="vertical" size={0}>
                  <StyledEmptyText>
                    {!isFetching
                      ? filtersModified
                        ? t("patientApplications.claims_tab.no_claims_found")
                        : t("patientApplications.claims_tab.no_claims_yet")
                      : " "}
                  </StyledEmptyText>
                </Space>
              }
            ></Empty>
          )
        }}
        onChange={fetchData}
        pagination={{
          size: "small",
          showSizeChanger: false,
          current: tableData && tableData.selectedPage,
          defaultPageSize: PAGE_SIZE,
          pageSize: PAGE_SIZE,
          position: ["bottom"],
          total: totalRecordsCount,
          showTotal: (total, range) => (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <AllClaimsPageCount total={total} range={range} />
            </div>
          )
        }}
        dataSource={
          tableData && tableData.patients
            ? tableData.patients.map((claim, index) => {
                return {
                  ...claim,
                  key: index
                };
              })
            : null
        }
        onRow={(patient) => {
          return {
            onClick: () => {
              const { patientId, journeyId, papId, applicationId, claimId } = patient;
              history.push(
                `/patient/${patientId}/journey/${journeyId}/applications/${papId}/${applicationId}/${Routes.CLAIMS}/${claimId}/${Routes.EDIT_CLAIM}`
              );
            }
          };
        }}
      />
    </>
  );
};

export const AllClaimsTable = ClaimsTable;
