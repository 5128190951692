import React from "react";
import { createGlobalStyle, ThemeProvider as RawThemeProvider } from "styled-components";
import { ERROR_600 } from "../../constant/colors";
import { sizes } from "../../constant/styles";

const theme = {
  dimensions: {
    width: "100%"
  },
  ".ant-popover-inner-content": {
    padding: "0"
  },
  colors: {
    error: ERROR_600
  },
  fontSize: {
    errorText: sizes.small
  }
};

const GlobalStyle = createGlobalStyle`
  .ant-form-item-explain-error {
    color: ${(props) => props.theme.colors.error};
    font-size: ${(props) => props.theme.fontSize.errorText};
  }
`;

export const ThemeProvider = (props) => (
  <RawThemeProvider {...props} theme={theme}>
    <GlobalStyle />
    {props.children}
  </RawThemeProvider>
);
