import { CloseOutlined } from "@ant-design/icons";
import { Modal, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { ReactComponent as AddPatient } from "../../assets/svg/add-patient-icon.svg";
import { GRAY_300, GRAY_900, WHITE } from "../../constant/colors";
import DiscardChangesPopup from "../Patient/PatientCoverageDetails/DiscardChangesPopup";
import NewPatientForm from "./NewPatientForm";
import { CreatePatientButton } from "../SidebarMenu/Styles";
import { fontWeights, sizes } from "../../constant/styles";
import ACTIONS from "../../store/action";

const { Text } = Typography;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
  }
`;

const AddNewPatientButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [discardChangesPopupVisible, setDiscardChangesPopupVisible] = useState(false);
  const [destroyPopoverOnClose, setDestroyPopoverOnClose] = useState(false);
  const [discardChangesFunc, setDiscardChangesFunc] = useState();

  const setFormPopupsAct = (visible, contentKey) => dispatch(ACTIONS.setFormPopups(visible, contentKey));

  useEffect(() => {
    if (!discardChangesPopupVisible) {
      setDiscardChangesFunc(() => discardChanges);
    }
  }, [discardChangesPopupVisible]);

  const discardChanges = () => {
    setDiscardChangesPopupVisible(false);
    closePopover();
  };

  const defineDiscardFunc = (func) => {
    setDiscardChangesFunc(() => func);
  };

  const onClickCancel = () => {
    setDiscardChangesPopupVisible(false);
  };

  const setDiscardChangesPopupVisibility = (visibility) => {
    setDiscardChangesPopupVisible(visibility);
  };

  const closePopover = () => {
    setDestroyPopoverOnClose(true);
    setOpen(false);
  };

  return (
    <>
      <StyledModal
        id="create_new_patient_popover"
        open={open}
        destroyOnClose={destroyPopoverOnClose}
        zIndex={900}
        bodyStyle={{ borderRadius: 12, border: `1px solid ${GRAY_300}`, padding: 0 }}
        width={833}
        footer={null}
        closable={false}
        closeIcon={<CloseOutlined id="close_create_new_patient_popover" />}
        centered
      >
        <NewPatientForm
          closePopover={closePopover}
          setDiscardChangesPopupVisibility={setDiscardChangesPopupVisibility}
          defineDiscardFunc={defineDiscardFunc}
        />
      </StyledModal>
      <Tooltip
        placement="right"
        trigger="hover"
        title={
          <Text style={{ color: GRAY_900, fontWeight: fontWeights.semibold, fontSize: sizes.small }}>
            {t("new_patient.create_patient_tooltip")}
          </Text>
        }
        color={WHITE}
        overlayInnerStyle={{ borderRadius: "8px", boxShadow: "0px 12px 16px -4px #1018281A" }}
      >
        <CreatePatientButton
          icon={<AddPatient />}
          ghost
          type="text"
          id="add_new_patient_button"
          onClick={() => {
            setDestroyPopoverOnClose(false);
            setOpen(true);
            setFormPopupsAct(false, null);
          }}
        />
      </Tooltip>

      <DiscardChangesPopup
        visible={discardChangesPopupVisible}
        onClickDiscard={() => discardChangesFunc()}
        onClickCancel={onClickCancel}
        popupTitle={t("managePatientDetails.cancel_modal_title")}
      />
    </>
  );
};

export default withRouter(AddNewPatientButton);
