import React from "react";
import { Space } from "antd";
import { useLocation } from "react-router";

import { ReactComponent as PatientsIcon } from "../../assets/svg/patients-icon.svg";
import { ReactComponent as HomeIcon } from "../../assets/svg/home-icon.svg";
import { ReactComponent as EnrollmentsIcon } from "../../assets/svg/enrollments-icon.svg";
import { ReactComponent as ClaimsIcon } from "../../assets/svg/claims-icon.svg";
import { ReactComponent as HelpIcon } from "../../assets/svg/help-icon.svg";
import { CustomBadge } from "./Styles";
import { selectedViews } from "../../constant/table";
import { defaultFilters } from "../../constant/filters";
import ACTIONS from "../../store/action";
import { history } from "../../router/history";
import { useSelector } from "react-redux";
import { selectFilterListStatuses, selectFormPopups } from "../../store/selector";
import { Routes } from "../../constant/routes";
import { formPopupsContentKeys } from "../../constant/formPopupsContentKeys";

export const MenuItemKeys = Object.freeze({
  home: {
    key: "home",
    subItems: {
      notifications: "notifications",
      tasks: "tasks",
      saved_lists: selectedViews.savedList
    }
  },
  patients: {
    key: "patients",
    subItems: {
      all_patients: selectedViews.allPatients,
      visit_schedule: selectedViews.visitSchedule,
      high_priority: selectedViews.highPriority
    }
  },
  enrollments: {
    key: "enrollments",
    subItems: {
      all_enrollments: selectedViews.allApplications,
      pending_renewal: selectedViews.pendingRenewal
    }
  },
  claims: {
    key: "claims",
    subItems: {
      all_claims: selectedViews.allClaims
    }
  },
  help_center: {
    key: "help_center",
    subItems: {
      contact_support: "contact-support"
    }
  }
});

const MenuItemWithBadge = ({ count, title }) => (
  <Space>
    {title}
    {count > 0 && <CustomBadge>{count}</CustomBadge>}
  </Space>
);

const generalMenusItems = (t, badgeCounts, dispatch, selectedView, filtersModified, useGetSetting) => {
  const [displayVisitSchedule, displayPendingRenewal, displayOppsFe, displayAllClaims] = useGetSetting([
    "automation.visit-schedule.fe-display",
    "automation.pending-renewal.fe-display",
    "automation.opportunities.fe-display",
    "fe.show_claims"
  ]);
  const setFormPopupsAct = (visible, contentKey) => dispatch(ACTIONS.setFormPopups(visible, contentKey));
  const setShowSupportAct = () => dispatch(ACTIONS.setShowSupport(true));

  const filterListStatuses = useSelector(selectFilterListStatuses);
  const formPopups = useSelector(selectFormPopups);
  const { visible, contentKey } = formPopups;
  const isNotificationsPopoverOpen = visible && contentKey === formPopupsContentKeys.NOTIFICATIONS ? true : false;
  const isTaskPopoverOpen = visible && contentKey === formPopupsContentKeys.TASKS ? true : false;

  const { pathname } = useLocation();

  const handleMenuItemClick = (targetView) => {
    const clearSavedListRelatedDataAct = () => dispatch(ACTIONS.clearSavedListRelatedDataAction());
    const clearFiltersInputs = () => dispatch(ACTIONS.clearFiltersInputs());
    const setSelectedView = (selectedView) => dispatch(ACTIONS.setSelectedView(selectedView));
    const setFiltersAct = (filters, tableName) => dispatch(ACTIONS.setFiltersAction(filters, tableName));
    const updateFilterListStatusesAct = (filterListID, canModify, showDiscardModal, nextSelectedView) =>
      dispatch(ACTIONS.updateFilterListStatuses(filterListID, canModify, showDiscardModal, nextSelectedView));
    const resetCurrentSavedListStatus = () => dispatch(ACTIONS.markSavedFilterAsChanged(undefined, false));

    if (pathname.includes(Routes.FILTER_LIST) && filterListStatuses?.canModify) {
      updateFilterListStatusesAct(filterListStatuses?.id, filterListStatuses.canModify, true, targetView);
      return;
    }

    resetCurrentSavedListStatus();
    clearSavedListRelatedDataAct();

    setSelectedView(targetView);
    history.push({ pathname: "/", search: `?selectedView=${targetView}` });

    const shouldRefetchData = selectedView && filtersModified;

    if (shouldRefetchData) {
      clearFiltersInputs();
      if (selectedView !== selectedViews.savedList) {
        // Note: Rest the previous table view data.
        setFiltersAct(defaultFilters, selectedView);
      } else {
        // Note: fetch the data for the target view, when user is currently at the saved-list view page.
        setFiltersAct(defaultFilters, targetView);
      }
    }
  };

  return [
    {
      key: MenuItemKeys.home.key,
      title: t("sidebar_menu.home"),
      icon: <HomeIcon />,
      onClick: () => handleMenuItemClick(MenuItemKeys.home.subItems.saved_lists),
      subMenus: [
        {
          key: MenuItemKeys.home.subItems.notifications,
          title: <MenuItemWithBadge title={t("sidebar_menu.notifications")} count={badgeCounts.notifications} />,
          onClick: () => setFormPopupsAct(!isNotificationsPopoverOpen, formPopupsContentKeys.NOTIFICATIONS),
          display: true
        },
        {
          key: MenuItemKeys.home.subItems.tasks,
          title: <MenuItemWithBadge title={t("sidebar_menu.tasks")} count={badgeCounts.tasks} />,
          onClick: () => setFormPopupsAct(!isTaskPopoverOpen, formPopupsContentKeys.TASKS),
          display: true
        },
        {
          key: MenuItemKeys.home.subItems.saved_lists,
          title: t("sidebar_menu.saved_lists"),
          onClick: () => handleMenuItemClick(MenuItemKeys.home.subItems.saved_lists),
          display: true
        }
      ]
    },
    {
      key: MenuItemKeys.patients.key,
      title: t("sidebar_menu.patients"),
      icon: <PatientsIcon />,
      onClick: () => handleMenuItemClick(MenuItemKeys.patients.subItems.all_patients),
      subMenus: [
        {
          key: MenuItemKeys.patients.subItems.all_patients,
          title: t("sidebar_menu.all_patients"),
          onClick: () => handleMenuItemClick(MenuItemKeys.patients.subItems.all_patients),
          display: true
        },
        {
          key: MenuItemKeys.patients.subItems.visit_schedule,
          title: t("sidebar_menu.visit_schedule"),
          onClick: () => handleMenuItemClick(MenuItemKeys.patients.subItems.visit_schedule),
          display: displayVisitSchedule
        },
        {
          key: MenuItemKeys.patients.subItems.high_priority,
          title: t("sidebar_menu.high_priority"),
          onClick: () => handleMenuItemClick(MenuItemKeys.patients.subItems.high_priority),
          display: displayOppsFe
        }
      ]
    },
    {
      key: MenuItemKeys.enrollments.key,
      title: t("sidebar_menu.enrollments"),
      icon: <EnrollmentsIcon />,
      onClick: () => handleMenuItemClick(selectedViews.allApplications),
      subMenus: [
        {
          key: MenuItemKeys.enrollments.subItems.all_enrollments,
          title: t("sidebar_menu.all_enrollments"),
          onClick: () => handleMenuItemClick(selectedViews.allApplications),
          display: true
        },
        {
          key: MenuItemKeys.enrollments.subItems.pending_renewal,
          title: t("sidebar_menu.pending_renewals"),
          onClick: () => handleMenuItemClick(MenuItemKeys.enrollments.subItems.pending_renewal),
          display: displayPendingRenewal
        }
      ]
    },
    {
      key: MenuItemKeys.claims.key,
      title: t("sidebar_menu.claims"),
      icon: <ClaimsIcon />,
      onClick: () => handleMenuItemClick(MenuItemKeys.claims.subItems.all_claims),
      subMenus: [
        {
          key: MenuItemKeys.claims.subItems.all_claims,
          title: t("sidebar_menu.all_claims"),
          onClick: () => handleMenuItemClick(MenuItemKeys.claims.subItems.all_claims),
          display: displayAllClaims
        }
      ]
    },
    {
      key: MenuItemKeys.help_center.key,
      title: t("sidebar_menu.help_center"),
      icon: <HelpIcon />,
      onClick: () => {
        setFormPopupsAct(false, null);
        setShowSupportAct();
      },
      subMenus: [
        {
          key: MenuItemKeys.help_center.subItems.contact_support,
          title: t("sidebar_menu.contact_support"),
          onClick: () => {
            setFormPopupsAct(false, null);
            setShowSupportAct();
          },
          display: true
        }
      ]
    }
  ];
};

export { generalMenusItems };
