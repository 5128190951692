import { Divider, Space, Typography } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

import { CheckCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { noop } from "lodash";
import { GRAY_900, SUCCESS_50, SUCCESS_700 } from "../../constant/colors";
import { CREATE_NEW_PATIENT_STEPS } from "../../constant/patient";
import { PrimaryButton } from "../customComponent/Button";
import PatientDetailsSummary from "./PatientDetailsSummary";
import { useTranslation } from "react-i18next";
import { StyledNewPatientHeader } from "./styles";

const { Text } = Typography;

const WhatNextStep = ({ patient, changeStep = noop, closePopover = noop }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div>
      <StyledNewPatientHeader>
        <Text style={{ margin: 0, color: GRAY_900 }}>{t("new_patient.title")}</Text>
        <CloseOutlined id="close_create_new_patient_popover" onClick={closePopover} />
      </StyledNewPatientHeader>

      <div style={{ padding: "16px 24px 24px 24px" }}>
        <Space size={3} style={{ background: SUCCESS_50, marginBottom: 20, padding: "0px 5px" }}>
          <CheckCircleOutlined style={{ marginRight: 3, color: SUCCESS_700 }} />
          <Text id="patient_successfully_created" style={{ fontSize: 12, color: SUCCESS_700 }}>
            {t("new_patient.patient_successfully_created")}
          </Text>
        </Space>
        <PatientDetailsSummary patient={patient} />
        <Divider style={{ margin: "16px 0px" }} />
        <div style={{ marginBottom: "16px" }}>
          <Text style={{ fontSize: 18, fontWeight: 600 }}>{t("new_patient.what_next")}</Text>
        </div>
        <Space direction="vertical" align="center" style={{ width: "100%" }} size={16}>
          <Space>
            <PrimaryButton
              style={{ width: 230, height: 44, fontSize: 16 }}
              onClick={() => changeStep(CREATE_NEW_PATIENT_STEPS.findAssistancePrograms)}
              id="create_new_patient_find_ap_button"
            >
              {t("new_patient.find_ap_button")}
            </PrimaryButton>
            {patient?.id && patient?.journeyId && (
              <PrimaryButton
                style={{ width: 230, height: 44, fontSize: 16 }}
                onClick={() => {
                  closePopover();
                  history.push(`/patient/${patient.id}/journey/${patient.journeyId}/edit`);
                  window.location.reload();
                }}
                id="create_new_patient_go_mpd_button"
              >
                {t("manage_patient_details")}
              </PrimaryButton>
            )}
          </Space>
        </Space>
      </div>
    </div>
  );
};

export default WhatNextStep;
