import React from "react";
import { Form, DatePicker } from "antd";
import { noop } from "lodash";

import { DATE_DATA_FORMAT, DATE_FORMAT } from "../../../../utils/date";
import { dateParser } from "../utils";

export default ({
  onChange = noop,
  required = false,
  placeholder = DATE_FORMAT,
  defaultDate = null,
  disabledDate = null,
  disabled,
  ...props
}) => (
  <Form.Item //
    rules={[{ type: "date" }, { required }]}
    getValueProps={dateParser}
    getValueFromEvent={(momentValue) => momentValue?.format(DATE_DATA_FORMAT)}
    {...props}
  >
    <DatePicker
      onChange={onChange}
      format={[DATE_FORMAT, DATE_DATA_FORMAT]}
      placeholder={placeholder}
      defaultPickerValue={defaultDate}
      disabledDate={disabledDate}
      disabled={disabled}
    />
  </Form.Item>
);
