import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useTranslation } from "react-i18next";
import { Tooltip, Menu, Dropdown, Button, Checkbox, Typography } from "antd";
import styled from "styled-components";
import { useParams } from "react-router";

import { selectSelectedView, selectTableColumns } from "../store/selector";
import ACTIONS from "../store/action";
import ColumnSelectorIcon from "../assets/svg/ColumnSelectorIcon";
import { ColumnsKeys } from "../constant/columns";
import { selectedViews } from "../constant/table";
import { MainBucketsTrackEvents } from "../constant/filters";
import { useDashboardBucketsTrackingEvent } from "../utils/pendo/hooks";
import { WHITE } from "../constant/colors";

const { Text } = Typography;

const StyledMenu = styled(Menu)`
  overflow: auto;
  max-height: ${(props) => {
    if (props.isRelevantPatientView || props.isFilterListView) {
      return "calc(100vh - 300px)";
    }
    return "calc(100vh - 220px)";
  }};

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 15px;
    background-color: ${WHITE};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 4px solid ${WHITE};
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const ColumnSelectorComponent = ({ tableColumns, setTableColumns, selectedView, isSearchResultsView = false }) => {
  const { filterListId } = useParams();

  const [visible, setVisible] = useState(false);
  const newSelectedView = isSearchResultsView ? selectedViews.allPatients : selectedView || selectedViews.allPatients;
  const { trackEvent } = useDashboardBucketsTrackingEvent();

  const handleVisibleChange = (flag) => setVisible(flag);
  const isRelevantPatientView = selectedView === selectedViews.topOpportunity;
  const isFilterListView = !!filterListId;

  const handleMenuClick = (e) => {
    const currColumn = Object.values(tableColumns[newSelectedView])[e.key];
    const newColumn = { ...currColumn, display: !currColumn.display };
    const newTableColumns = {
      ...tableColumns,
      [newSelectedView]: {
        ...tableColumns[newSelectedView],
        [newColumn.key]: newColumn
      }
    };

    try {
      localStorage.setItem(
        ColumnsKeys.tables[newSelectedView],
        JSON.stringify({ ...tableColumns[newSelectedView], [newColumn.key]: newColumn })
      );
      newColumn?.display &&
        trackEvent(MainBucketsTrackEvents.BUCKET_COLUMN_SELECTOR_SELECTED, { columnName: newColumn.key });
    } catch (err) {
      console.error(`could not save column selection: ${err}`);
    }

    setTableColumns(newTableColumns);
  };

  const menu = () => (
    <StyledMenu
      onClick={handleMenuClick}
      isRelevantPatientView={isRelevantPatientView}
      isFilterListView={isFilterListView}
    >
      {Object.values(tableColumns[newSelectedView]).map(
        (col, index) =>
          col.key !== "hasConflicts" &&
          col.key !== "actions" &&
          col.key !== "edit" &&
          col.key !== "star" && (
            <Menu.Item disabled={col.mandatory} key={index} id={`column_selector_${col.key}`}>
              <Checkbox disabled={col.mandatory} checked={col.display} />
              <Text disabled={col.mandatory} style={{ marginLeft: 8 }}>
                {col.title}
              </Text>
            </Menu.Item>
          )
      )}
    </StyledMenu>
  );

  const { t } = useTranslation();
  return (
    <Tooltip title={<Text>{t("column_selector")}</Text>} color={"white"}>
      <Dropdown
        overlay={menu()}
        trigger="click"
        onOpenChange={handleVisibleChange}
        open={visible}
        overlayStyle={{ boxShadow: "0px 4px 8px 0px #00000000", borderRadius: "8px" }}
      >
        <Button
          id="column_selector"
          style={{ bottom: 6, marginRight: 5 }}
          type="text"
          onClick={(e) => e.preventDefault()}
          icon={<ColumnSelectorIcon />}
        />
      </Dropdown>
    </Tooltip>
  );
};

const mapStateToProps = createStructuredSelector({
  tableColumns: selectTableColumns,
  selectedView: selectSelectedView
});

const mapDispatchToProps = (dispatch) => ({
  setTableColumns: (columns) => dispatch(ACTIONS.setTableColumns(columns))
});

const ColumnSelector = connect(mapStateToProps, mapDispatchToProps)(ColumnSelectorComponent);

export { ColumnSelector };
