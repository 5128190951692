import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { Input, DatePicker, Select, Button, Space, Form } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

import { BLUE_DARK } from "../../constant/colors";
import { selectAssignees, selectPotentialSavings, selectUser } from "../../store/selector";
import { DATE_FORMAT } from "../../utils/date";
import { optionsSearch } from "../../utils/search";
import { formatAssignee } from "../../utils/formaters";
import { getPopoverHeight } from "./Tasks";

const { Option } = Select;

const TasksEdit = ({ isFetching, setIsEditMode, editTaskData, editTask, createTask, isPatientView }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();

  const { t } = useTranslation();
  const currentUser = useSelector(selectUser);
  const assignees = useSelector(selectAssignees);
  const potentialSavings = useSelector(selectPotentialSavings);

  useEffect(() => {
    if (!editTaskData || !assignees) return;

    const assignee = Object.values(assignees).find((assignee) => assignee.id === editTaskData.assignee);

    form.setFieldsValue({
      text: editTaskData.text,
      dueDate: editTaskData.dueDate && moment(editTaskData.dueDate),
      assignee: assignee?.id,
      linkedPotentialSaving: editTaskData.linkedPotentialSaving
    });
  }, [assignees, editTaskData]);

  const addTaskHandler = () => {
    const { text, dueDate, assignee, linkedPotentialSaving } = form.getFieldsValue(true);
    const taskData = {
      updatedBy: currentUser.id,
      text,
      assignee,
      dueDate,
      linkedPotentialSaving,
      patient: patientId
    };

    if (editTaskData) {
      taskData.id = editTaskData.id;
      editTask(taskData);
    } else {
      taskData.createdBy = currentUser.id;
      createTask(taskData);
    }
  };

  return (
    <Form
      form={form}
      onFinish={addTaskHandler}
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0px 16px",
        width: "100%",
        height: getPopoverHeight(isPatientView, true)
      }}
      initialValues={{
        assignee: assignees[currentUser?.id]?.id
      }}
    >
      <Button
        type="text"
        style={{ color: BLUE_DARK, padding: 0, width: "110px" }}
        icon={<ArrowLeftOutlined />}
        onClick={() => setIsEditMode(false)}
      >
        {t("patientsTasks.back_to_tasks")}
      </Button>
      <Form.Item
        name="text"
        rules={[{ required: true, message: t("patientsTasks.this_is_required") }]}
        style={{ paddingTop: 10 }}
      >
        <Input placeholder={t("patientsTasks.task")} />
      </Form.Item>
      <Space>
        <Form.Item name="dueDate">
          <DatePicker format={DATE_FORMAT} placeholder={t("patientsTasks.due_date")} />
        </Form.Item>
        <Form.Item name="assignee">
          <Select
            placeholder={t("patientsTasks.assignee")}
            allowClear
            style={{ width: "200px" }}
            showSearch={true}
            filterOption={optionsSearch}
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
          >
            {Object.values(assignees).map((assignee) => (
              <Option key={assignee.id} value={assignee.id}>
                {formatAssignee(assignee)}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Space>
      {(potentialSavings?.length && isPatientView && (
        <Form.Item name="linkedPotentialSaving">
          <Select
            dropdownMatchSelectWidth={false}
            placeholder={t("tableFilters.potential_savings")}
            style={{ width: "100%" }}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {potentialSavings.map((p, index) => (
              <Option key={index} value={p.id}>
                {p.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )) ||
        null}
      <Button loading={isFetching} type="primary" htmlType="submit" style={{ width: "140px" }}>
        {editTaskData ? t("patientsTasks.update") : t("patientsTasks.add")}
      </Button>
    </Form>
  );
};

export { TasksEdit };
