import React from "react";

const TailormedLogoIcon = () => {
  return (
    <svg
      width="115"
      height="17"
      viewBox="0 0 115 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="tailormed_logo"
    >
      <path d="M5.23939 1.46332V16.5974H4.17798V1.46332H0V0.498077H9.41381V1.46332H5.23939Z" fill="#101828" />
      <path
        d="M18.946 11.298H12.0895L9.94176 16.5837H8.78418L15.5658 0L22.2513 16.5837H21.0937L18.946 11.298ZM18.5577 10.3327L15.5409 2.70696L12.4742 10.3327H18.5577Z"
        fill="#101828"
      />
      <path d="M25.7987 0.498047V16.5973H24.7373V0.498047H25.7987Z" fill="#101828" />
      <path d="M31.2085 0.498047V15.6321H35.6964V16.5973H30.1436V0.498047H31.2085Z" fill="#101828" />
      <path
        d="M53.3132 8.57346C53.3132 13.2074 49.6695 16.8511 44.9608 16.8511C40.2557 16.8511 36.6084 13.2074 36.6084 8.57346C36.6084 3.96094 40.3483 0.221065 44.9608 0.224627C49.5769 0.224627 53.3132 3.96094 53.3132 8.57346ZM52.2055 8.57346C52.2055 4.44535 49.1174 1.23617 44.9644 1.23617C40.8113 1.23617 37.7232 4.44535 37.7232 8.57346C37.7232 12.7016 40.8113 15.8858 44.9644 15.8858C49.1174 15.8858 52.2055 12.6766 52.2055 8.57346Z"
        fill="#101828"
      />
      <path
        d="M63.6382 16.5974L58.4237 8.99295H57.9393V16.5974H56.8779V0.498077H59.1967C60.429 0.498077 61.5367 0.594247 62.6017 1.31729C63.8555 2.18637 64.2686 3.51491 64.2686 4.76866C64.2686 6.36078 63.567 8.00277 61.5652 8.67951C60.9277 8.88966 60.2581 8.99651 59.5849 8.99295L64.8457 16.5974H63.6382ZM57.9429 8.0277H59.4887C61.7825 8.0277 63.1574 6.82025 63.1574 4.74373C63.1574 3.12668 62.3595 2.28253 61.7327 1.94416C61.0559 1.55949 59.8983 1.45976 59.2715 1.45976H57.9429V8.0277Z"
        fill="#101828"
      />
      <path
        d="M66.9766 16.5973L69.5589 0.498047H72.6469L76.6539 10.1291L80.661 0.498047H83.749L86.3313 16.5973H82.6128L81.3341 6.99119L77.3022 16.5973H76.0021L71.9702 6.99119L70.6915 16.5973H66.9766Z"
        fill="#101828"
      />
      <path
        d="M98.2264 3.63601H92.8445V6.74902H97.9628V9.88696H92.8445V13.4594H98.2264V16.5974H89.126V0.498077H98.2264V3.63601Z"
        fill="#101828"
      />
      <path
        d="M106.335 0.498047C108.457 0.498047 110.705 0.811483 112.682 2.71704C114.106 4.09189 115 5.97608 115 8.48714C115 10.9982 114.131 12.8575 112.803 14.1824C110.584 16.4264 107.806 16.5973 106.456 16.5973H101.241V0.498047H106.335ZM104.956 13.4594H105.946C106.719 13.4594 108.408 13.3882 109.615 12.3731C110.484 11.65 111.182 10.2716 111.182 8.48714C111.182 6.74899 110.48 5.63771 109.854 5.01083C108.646 3.80339 107.079 3.63598 105.943 3.63598H104.953V13.4594H104.956Z"
        fill="#101828"
      />
    </svg>
  );
};

export { TailormedLogoIcon };
