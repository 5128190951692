/* eslint-disable */
import React from "react";
import moment from "moment-timezone";

import * as novartisCopayFieldNames from "./form/fieldNames";
import { getNovartisCopayFormInitialValues } from "./form/initialValues";
import { SaveButton } from "../../header";
import useBaseEEConfiguration from "../../hooks/useBaseEEConfiguration";

import { CompleteFormButton } from "../../CompleteFormButton";
import { novartisSectionsController } from "./novartisSectionsController";
import { applyTrimFields } from "../../utils/updateFormData";
import { DATE_FORMAT } from "../../../../../utils/date";
import { calculateAge } from "../../utils";
import { SignerTypes } from "../../../../../constant/ESign";
import { HEADER_SUBMIT_BUTTON_TYPES } from "../../constants";

export const useNovartisCopayConfiguration = () => {
  const vendorName = "novartis";
  const eePlanType = "copay";
  const rootPath = "application_form_editor.novartis.copay";
  const controller = novartisSectionsController();
  const sidebarItems = [...controller.getSectionsByDrug()];

  const sections = controller.getNovartisCopaySections();

  const baseConfig = useBaseEEConfiguration(vendorName, eePlanType, rootPath, sidebarItems);
  const novartisSpecificConfig = {
    activeFormSection: "",
    fieldNames: novartisCopayFieldNames,
    getFormInitialValues: getNovartisCopayFormInitialValues,
    getFormSectionComponents: () => <>{sections}</>,
    applicationFormHeaderSubmission: (props) => <CompleteFormButton id={"EE_complete_button"} {...props} />,
    headerSubmitButtonType: HEADER_SUBMIT_BUTTON_TYPES.COMPLETE_FORM,
    saveButton: (props) => (
      <SaveButton
        showPdfOnSave={false}
        onSave={(form) => applyTrimFields(form)}
        id={"EE_novartis_copay_save_form"}
        {...props}
      />
    ),
    successMessage: (t, submittedDate) =>
      t("patientApplications.enroll.application_form_submission.submit_completed_novartis", {
        submitDate: moment(submittedDate).format(DATE_FORMAT)
      }),
    afterSubmissionPayload: () => ({}),
    isModalSubmissionEnabled: false,
    shouldShowPdfInSecondStep: true,
    submissionStep: {
      description: (t) => t("patientApplications.enroll.application_form_submission.novartis.description"),
      getSubmitButtonText: (t) => t("patientApplications.enroll.application_form_submission.submit_button"),
      submitModal: {
        showNotice: true,
        attachments: {
          showAllowedFileTypesNotice: true
        }
      },
      getSubmitSuccessMessage: (t) => t("patientApplications.enroll.application_form_submission.submit_success_message")
    },
    modifyDataBeforeApplicationFormUpdate: (formData) => {
      const patientDob = formData?.patient?.dob;
      if (!patientDob) return;

      const patientCurrentAge = calculateAge(patientDob);
      if (!patientCurrentAge || typeof patientCurrentAge !== "number") return;

      const isUnderEighteen = patientCurrentAge <= 18;
      if (isUnderEighteen) {
        formData.signedByRep = true;
      }
    },

    // NOTE: for Novartis EE with eSign - Cosentyx drug
    // we want to be able to sign by patient/caregiver but
    // display the title "Patient" anyway , disable editing and hide the "- Caregiver" indication when it's caregiver (yes, weird product requirements :|)
    getSignatureWidgetSettingsBySignerType: (signerType) => {
      const signatureSettings = {
        [SignerTypes.CAREGIVER]: {
          editable: true,
          titleTranslationKey: "patientApplications.enroll.electronic_signatures.signature_step.signers.patient",
          shouldShowCaregiverIndication: false,
          shouldUseRemoteRequestCheckboxes: false
        },
        [SignerTypes.PATIENT]: {
          editable: true,
          titleTranslationKey: "patientApplications.enroll.electronic_signatures.signature_step.signers.patient",
          shouldShowCaregiverIndication: false,
          shouldUseRemoteRequestCheckboxes: false
        }
      };
      return signatureSettings[signerType];
    }
  };

  return {
    ...baseConfig,
    ...novartisSpecificConfig
  };
};

export default useNovartisCopayConfiguration;
