import React, { useState, useEffect } from "react";
import { Row, Col, Checkbox } from "antd";
import moment from "moment";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";

import { selectPatientDetails } from "../../store/selector";
import { PopupStyledModal } from "../customComponentNewDesign/CustomPopupModal";
import { CustomDatePicker } from "../customComponentNewDesign/customDatePicker";
import { CustomRadioGroup } from "../customComponentNewDesign/CustomRadioGroup";
import { SingleSelect } from "../customComponentNewDesign/CustomSelect";
import { CustomForm as Form } from "../customComponentNewDesign/CustomForm";
import { useFetch } from "../../hooks/fetch";
import { searchIcdToDiagnoses } from "../../api/api";
import { isEmpty } from "../../utils/string";
import { OutlinedButton, PrimaryButton } from "../customComponent/Button";
import { DATE_FORMAT } from "../../utils/date";

const { Option } = SingleSelect;

const AddNewDiagnosisPopup = ({ showPopupRef, onDiagnosisAdded, isICDExists }) => {
  const [popupShown, setPopupShown] = useState(false);
  const [diagnosisSearchResults, setDiagnosisSearchResults] = useState();
  const [form] = Form.useForm();
  const [searchDiagnosis, searchDiagnosisResult, searchInProgress] = useFetch(searchIcdToDiagnoses);
  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue({
      activeDate: moment(),
      isPrimary: true,
      diagnosisStatus: "Active"
    });
  }, [popupShown]);

  useEffect(() => {
    if (!searchDiagnosisResult?.data) return;

    setDiagnosisSearchResults(searchDiagnosisResult.data.ICDtoDiagnosises);
  }, [searchDiagnosisResult]);

  const handleDiagnosisSearch = (searchValue) => {
    if (isEmpty(searchValue) || searchValue.trim().length < 2) return;
    searchDiagnosis(searchValue);
  };

  const handleCreateDiagnosisEvent = (values) => {
    const diagnosisData = searchDiagnosisResult.data.ICDtoDiagnosises.find(({ code }) => code === values.icd.value);
    onDiagnosisAdded({
      isPrimary: values.isPrimary,
      activeDate: values.activeDate?.toDate(),
      isActive: values.diagnosisStatus == "Active",
      icd: values.icd.key,
      diagnosis: diagnosisData.diagnosis
    });
    setPopupShown(false);
  };

  const showPopup = () => setPopupShown(true);

  showPopupRef.current = showPopup;

  return (
    <PopupStyledModal
      destroyOnClose={true}
      open={popupShown}
      title={t("add_new_diagnosis.title")}
      onCancel={() => setPopupShown(false)}
      footer={[
        <OutlinedButton key="cancelButton" onClick={() => setPopupShown(false)}>
          {t("add_new_diagnosis.cancel")}
        </OutlinedButton>,
        <PrimaryButton id="add-new-diagnosis-submit-btn" form="addDiagnosisForm" key="submit" htmlType="submit">
          {t("add_new_diagnosis.add_diagnosis")}
        </PrimaryButton>
      ]}
    >
      <Form
        preserve={false}
        size="large"
        layout="vertical"
        id="addDiagnosisForm"
        form={form}
        onFinish={(values) => handleCreateDiagnosisEvent(values)}
      >
        <Row>
          <Form.Item
            label={t("add_new_diagnosis.icd_label")}
            name="icd"
            rules={[
              { required: true, message: t("add_new_diagnosis.icd_required_message") },
              {
                validator: (_, value) =>
                  isICDExists(value.key)
                    ? Promise.reject(t("add_new_diagnosis.icd_already_exists_message"))
                    : Promise.resolve()
              }
            ]}
          >
            <SingleSelect
              labelInValue
              showArrow
              showSearch
              allowClear
              loading={searchInProgress === true}
              onSearch={debounce(handleDiagnosisSearch, 300)}
              filterOption={false}
              notFoundContent={diagnosisSearchResults ? t("add_new_diagnosis.no_results_found") : null}
              placeholder={t("add_new_diagnosis.icd_placeholder")}
              onBlur={() => setDiagnosisSearchResults()}
            >
              {diagnosisSearchResults &&
                diagnosisSearchResults.map(({ diagnosis, code, tmDiagnosis }) => (
                  <Option key={code}>{`${code} - ${diagnosis} (${tmDiagnosis})`}</Option>
                ))}
            </SingleSelect>
          </Form.Item>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label={t("add_new_diagnosis.active_date_placeholder")} name="activeDate">
              <CustomDatePicker format={DATE_FORMAT} suffixIcon={false} placeholder={DATE_FORMAT} width="228" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t("add_new_diagnosis.diagnosis_status_placeholder")} name="diagnosisStatus">
              <CustomRadioGroup options={["Active", "Inactive"]} optionType="button" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item name="isPrimary" valuePropName="checked">
              <Checkbox>{t("add_new_diagnosis.primary_diagnosis_text")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </PopupStyledModal>
  );
};

const mapStateToProps = createStructuredSelector({
  patientDetails: selectPatientDetails
});

export default compose(connect(mapStateToProps))(AddNewDiagnosisPopup);
