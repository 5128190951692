import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e4e7ec;
  padding: 24px;
  padding-bottom: 0px;
`;

const CardContainer = (props) => {
  return <StyledDiv>{props.children}</StyledDiv>;
};

export { CardContainer };
