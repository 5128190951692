const tableColumnTypes = {
  name_id: {
    key: "name_id",
    dataIndex: "name_id",
    title: "Name / ID",
    display: true,
    mandatory: true
  },
  isDeceased: {
    key: "isDeceased",
    dataIndex: "isDeceased",
    title: "Deceased",
    display: false,
    mandatory: false
  },
  phone_number: {
    key: "phone_number",
    dataIndex: "phone_number",
    title: "Phone Number",
    display: true,
    mandatory: false
  },
  dob: {
    key: "dob",
    dataIndex: "dob",
    title: "DOB",
    display: false,
    mandatory: false
  },
  ssn: {
    key: "ssn",
    dataIndex: "ssn",
    title: "SSN",
    display: false,
    mandatory: false
  },
  member_id: {
    key: "member_id",
    dataIndex: "member_id",
    title: "Member Id",
    display: true,
    mandatory: false
  },
  approvedDate: {
    key: "approvedDate",
    dataIndex: "approvedDate",
    title: "Approved Date",
    display: false,
    mandatory: false
  },
  applicationSignatureStatus: {
    key: "applicationSignatureStatus",
    dataIndex: "applicationSignatureStatus",
    title: "Signature Status",
    display: false,
    mandatory: false
  },
  latestClaimDate: {
    key: "latestClaimDate",
    dataIndex: "latestClaimDate",
    title: "Latest Claim Date",
    display: false,
    mandatory: false
  },
  approvedAmount: {
    key: "approvedAmount",
    dataIndex: "approvedAmount",
    title: "Approved Amount",
    display: false,
    mandatory: false
  },
  fundBalance: {
    key: "fundBalance",
    dataIndex: "fundBalance",
    title: "Available Funds",
    display: true,
    mandatory: false
  },
  primaryDiagnosis: {
    key: "primaryDiagnosis",
    dataIndex: "primaryDiagnosis",
    title: "Primary Diagnosis",
    display: false,
    mandatory: false
  },
  assignee: {
    key: "assignee",
    dataIndex: "assignee",
    title: "Assignee",
    display: true,
    mandatory: true
  },
  visitFacility: {
    key: "visitFacility",
    dataIndex: "visitFacility",
    title: "Visit Facility",
    display: false,
    mandatory: false
  },
  facility: {
    key: "facility",
    dataIndex: "facility",
    title: "Patient Facility",
    display: false,
    mandatory: false
  },
  physician: {
    key: "physician",
    dataIndex: "physician_full_name",
    title: "Physician",
    display: false,
    mandatory: false
  },
  creationDate: {
    key: "creationDate",
    dataIndex: "creationDate",
    title: "Creation Date",
    display: false,
    mandatory: false
  },
  journeyEvent: {
    key: "journeyEvent",
    dataIndex: "journeyEvent",
    title: "Journey Event",
    display: false,
    mandatory: false
  },
  primaryInsurancePlan: {
    key: "primaryInsurancePlan",
    dataIndex: "primaryInsurancePlan",
    title: "Primary Ins",
    display: true,
    mandatory: true
  },
  secondaryInsurancePlan: {
    key: "secondaryInsurancePlan",
    dataIndex: "secondaryInsurancePlan",
    title: "Secondary Ins",
    display: false,
    mandatory: false
  },
  tertiaryInsurancePlan: {
    key: "tertiaryInsurancePlan",
    dataIndex: "tertiaryInsurancePlan",
    title: "Tertiary Ins",
    display: false,
    mandatory: false
  },
  adminDate: {
    key: "adminDate",
    dataIndex: "adminDate",
    title: "Visit Schedule",
    display: true,
    mandatory: true
  },
  adminType: {
    key: "adminType",
    dataIndex: "adminType",
    title: "Visit Type",
    display: false,
    mandatory: false
  },
  mrn: {
    key: "mrn",
    dataIndex: "mrn",
    title: "MRN",
    display: false,
    mandatory: false
  },
  currentCoverages: {
    key: "currentCoverages",
    dataIndex: "currentCoverages",
    title: "Current Coverages",
    display: true,
    mandatory: true
  },
  recommendedCoverages: {
    key: "recommendedCoverages",
    dataIndex: "recommendedCoverages",
    title: "Recommended Coverages",
    display: true,
    mandatory: true
  },
  enrollmentType: {
    key: "enrollmentType",
    dataIndex: "enrollmentType",
    title: "Enrollment Type",
    display: true,
    mandatory: true
  },
  status: {
    key: "status",
    dataIndex: "status",
    title: "Status",
    display: true,
    mandatory: true
  },
  patientStatus: {
    key: "patientStatus",
    dataIndex: "patientStatus",
    title: "Patient Status",
    display: false,
    mandatory: false
  },
  edit: {
    key: "edit",
    dataIndex: "edit",
    title: "Edit",
    display: true,
    mandatory: true
  },
  star: {
    key: "star",
    dataIndex: "star",
    title: "Star",
    display: true,
    mandatory: true
  },
  assistanceProgram: {
    key: "assistanceProgram",
    dataIndex: "assistanceProgram",
    title: "Assistance Program",
    display: true,
    mandatory: true
  },
  assistanceProgramName: {
    key: "assistanceProgramName",
    dataIndex: "assistanceProgramName",
    title: "Assistance Program",
    display: true,
    mandatory: true
  },
  apType: {
    key: "apType",
    dataIndex: "apType",
    title: "Type",
    display: true,
    mandatory: false
  },
  providedBy: {
    key: "providedBy",
    dataIndex: "providedBy",
    title: "Assistance Program Sponsor",
    display: true,
    mandatory: false
  },
  associatedDrug: {
    key: "associatedDrug",
    dataIndex: "associatedDrug",
    title: "Associated Drug",
    display: true,
    mandatory: false
  },
  agreementSignatureDate: {
    key: "agreementSignatureDate",
    dataIndex: "agreement_signature_date",
    title: "Patient Consent Date",
    display: false,
    mandatory: false
  },
  applicationStatus: {
    key: "applicationStatus",
    dataIndex: "name_applicationStatusid",
    title: "Enrollment Status",
    display: true,
    mandatory: true
  },
  claimFollowupDate: {
    key: "claimFollowupDate",
    dataIndex: "claimFollowupDate",
    title: "Follow-up Date",
    display: true,
    mandatory: false
  },
  followupDate: {
    key: "followupDate",
    dataIndex: "followupDate",
    title: "Patient Follow-up Date",
    display: true,
    mandatory: false
  },
  validThrough: {
    key: "validThrough",
    dataIndex: "validThrough",
    title: "Valid Through",
    display: true,
    mandatory: true
  },
  hasConflicts: {
    key: "hasConflicts",
    display: true,
    mandatory: true
  },
  listName: {
    key: "listName",
    dataIndex: "listName",
    title: "List Name",
    display: true,
    mandatory: true
  },
  lastModified: {
    key: "lastModified",
    dataIndex: "lastModified",
    title: "Last Modified",
    display: true,
    mandatory: true
  },
  numberOfRecords: {
    key: "numberOfRecords",
    dataIndex: "numberOfRecords",
    title: "# Of Records",
    display: true,
    mandatory: true
  },
  actions: {
    key: "actions",
    dataIndex: "actions",
    title: "",
    display: true,
    mandatory: true
  },
  claimStatus: {
    key: "status",
    dataIndex: "status",
    title: "Claim Status",
    display: true,
    mandatory: false
  },
  claimDate: {
    key: "claimDate",
    dataIndex: "claimDate",
    title: "Claim Date",
    display: true,
    mandatory: false
  },
  serviceDate: {
    key: "serviceDate",
    dataIndex: "serviceDate",
    title: "Date Of Service",
    display: true,
    mandatory: false
  },
  submittedDate: {
    key: "submittedDate",
    dataIndex: "submittedDate",
    title: "Submitted Date",
    display: true,
    mandatory: false
  },
  claimSubmittedDate: {
    key: "claimSubmittedDate",
    dataIndex: "claimSubmittedDate",
    title: "Submitted Date",
    display: true,
    mandatory: false
  },
  requestedAmount: {
    key: "requestedAmount",
    dataIndex: "totalClaimsAmount",
    title: "Requested Amount",
    display: true,
    mandatory: false
  },
  paymentReference: {
    key: "paymentReference",
    dataIndex: "paymentReference",
    title: "Payment Reference",
    display: true,
    mandatory: false
  },
  claimTotalAmount: {
    key: "claimTotalAmount",
    dataIndex: "claimTotalAmount",
    title: "Requested Amount",
    display: true,
    mandatory: false
  },
  receivedDate: {
    key: "receivedDate",
    dataIndex: "receivedDate",
    title: "Posted Date",
    display: true,
    mandatory: false
  },
  claimPaidAmount: {
    key: "claimPaidAmount",
    dataIndex: "claimPaidAmount",
    title: "Paid Amount",
    display: true,
    mandatory: false
  },
  claimDeniedAmount: {
    key: "claimDeniedAmount",
    dataIndex: "claimDeniedAmount",
    title: "Denied Amount",
    display: true,
    mandatory: false
  },
  ClaimPatientResponsibilityAmount: {
    key: "ClaimPatientResponsibilityAmount",
    dataIndex: "ClaimPatientResponsibilityAmount",
    title: "Patient Responsibility",
    display: true,
    mandatory: false
  },
  availableFunds: {
    key: "availableFunds",
    dataIndex: "availableFunds",
    title: "Available Funds",
    display: true,
    mandatory: false
  },
  drug: {
    key: "drugDisplayName",
    dataIndex: "drugDisplayName",
    title: "Drug",
    display: true,
    mandatory: false
  },
  dateReceived: {
    key: "dateReceived",
    dataIndex: "dateReceived",
    title: "Posted Date",
    display: true,
    mandatory: false
  },
  balance: {
    key: "balance",
    dataIndex: "balance",
    title: "Balance",
    display: true,
    mandatory: false
  },
  dosageQuantity: {
    key: "dosageQuantity",
    dataIndex: "dosageQuantity",
    title: "Dosage / Quantity",
    display: true,
    mandatory: false
  },
  drugManufacturer: {
    key: "drugManufacturer",
    dataIndex: "drugManufacturer",
    title: "Drug Manufacturer",
    display: true,
    mandatory: false
  },
  orderDate: {
    key: "orderDate",
    dataIndex: "orderDate",
    title: "Order Date",
    display: true,
    mandatory: false
  },
  statusOfOrder: {
    key: "statusOfOrder",
    dataIndex: "statusOfOrder",
    title: "Status Of Order",
    display: true,
    mandatory: false
  },
  cycleStatus: {
    key: "cycleStatus",
    dataIndex: "cycleStatus",
    title: "Cycle Status",
    display: true,
    mandatory: false
  },
  checkNumber_ACH: {
    key: "checkNumber_ACH",
    dataIndex: "checkNumber",
    title: "Check number / ACH",
    display: false,
    mandatory: false
  },
  medications: {
    key: "medications",
    dataIndex: "medications",
    title: "Medications",
    display: false,
    mandatory: false
  }
};

const tableColumns = {
  "all-patients": {
    name_id: tableColumnTypes.name_id,
    isDeceased: tableColumnTypes.isDeceased,
    phone_number: tableColumnTypes.phone_number,
    dob: tableColumnTypes.dob,
    ssn: tableColumnTypes.ssn,
    primaryDiagnosis: tableColumnTypes.primaryDiagnosis,
    assignee: tableColumnTypes.assignee,
    facility: tableColumnTypes.facility,
    physician: tableColumnTypes.physician,
    creationDate: tableColumnTypes.creationDate,
    journeyEvent: tableColumnTypes.journeyEvent,
    primaryInsurancePlan: tableColumnTypes.primaryInsurancePlan,
    secondaryInsurancePlan: tableColumnTypes.secondaryInsurancePlan,
    tertiaryInsurancePlan: tableColumnTypes.tertiaryInsurancePlan,
    edit: tableColumnTypes.edit,
    star: tableColumnTypes.star,
    hasConflicts: tableColumnTypes.hasConflicts,
    agreementSignatureDate: tableColumnTypes.agreementSignatureDate,
    patientStatus: tableColumnTypes.patientStatus,
    followupDate: tableColumnTypes.followupDate
  },
  "visit-schedule": {
    name_id: tableColumnTypes.name_id,
    isDeceased: tableColumnTypes.isDeceased,
    phone_number: tableColumnTypes.phone_number,
    dob: tableColumnTypes.dob,
    ssn: tableColumnTypes.ssn,
    primaryDiagnosis: tableColumnTypes.primaryDiagnosis,
    assignee: tableColumnTypes.assignee,
    visitFacility: tableColumnTypes.visitFacility,
    facility: tableColumnTypes.facility,
    physician: tableColumnTypes.physician,
    adminDate: tableColumnTypes.adminDate,
    adminType: tableColumnTypes.adminType,
    creationDate: tableColumnTypes.creationDate,
    primaryInsurancePlan: tableColumnTypes.primaryInsurancePlan,
    secondaryInsurancePlan: tableColumnTypes.secondaryInsurancePlan,
    tertiaryInsurancePlan: tableColumnTypes.tertiaryInsurancePlan,
    edit: tableColumnTypes.edit,
    star: tableColumnTypes.star,
    hasConflicts: tableColumnTypes.hasConflicts,
    agreementSignatureDate: tableColumnTypes.agreementSignatureDate,
    patientStatus: tableColumnTypes.patientStatus,
    followupDate: tableColumnTypes.followupDate
  },
  "pending-renewal": {
    name_id: tableColumnTypes.name_id,
    phone_number: { ...tableColumnTypes.phone_number, display: false, mandatory: false },
    dob: tableColumnTypes.dob,
    ssn: tableColumnTypes.ssn,
    assistanceProgram: tableColumnTypes.assistanceProgram,
    apType: tableColumnTypes.apType,
    assignee: { ...tableColumnTypes.assignee, display: false, mandatory: false },
    providedBy: tableColumnTypes.providedBy,
    associatedDrug: tableColumnTypes.associatedDrug,
    facility: tableColumnTypes.facility,
    physician: tableColumnTypes.physician,
    creationDate: tableColumnTypes.creationDate,
    fundBalance: tableColumnTypes.fundBalance,
    applicationStatus: tableColumnTypes.applicationStatus,
    validThrough: tableColumnTypes.validThrough,
    approvedDate: tableColumnTypes.approvedDate,
    star: tableColumnTypes.star,
    hasConflicts: tableColumnTypes.hasConflicts,
    agreementSignatureDate: tableColumnTypes.agreementSignatureDate,
    patientStatus: tableColumnTypes.patientStatus,
    followupDate: tableColumnTypes.followupDate,
    primaryInsurancePlan: tableColumnTypes.primaryInsurancePlan,
    secondaryInsurancePlan: tableColumnTypes.secondaryInsurancePlan,
    tertiaryInsurancePlan: tableColumnTypes.tertiaryInsurancePlan,
    latestClaimDate: tableColumnTypes.latestClaimDate
  },
  "high-priority": {
    name_id: tableColumnTypes.name_id,
    isDeceased: tableColumnTypes.isDeceased,
    phone_number: tableColumnTypes.phone_number,
    dob: tableColumnTypes.dob,
    ssn: tableColumnTypes.ssn,
    assignee: tableColumnTypes.assignee,
    facility: tableColumnTypes.facility,
    physician: tableColumnTypes.physician,
    creationDate: tableColumnTypes.creationDate,
    primaryInsurancePlan: tableColumnTypes.primaryInsurancePlan,
    secondaryInsurancePlan: tableColumnTypes.secondaryInsurancePlan,
    tertiaryInsurancePlan: tableColumnTypes.tertiaryInsurancePlan,
    edit: tableColumnTypes.edit,
    star: tableColumnTypes.star,
    hasConflicts: tableColumnTypes.hasConflicts,
    agreementSignatureDate: tableColumnTypes.agreementSignatureDate,
    patientStatus: tableColumnTypes.patientStatus,
    followupDate: tableColumnTypes.followupDate
  },
  "saved-list": {
    star: tableColumnTypes.star,
    name: tableColumnTypes.listName,
    updatedAt: tableColumnTypes.lastModified,
    numberOfRecords: tableColumnTypes.numberOfRecords,
    actions: tableColumnTypes.actions
  },
  "top-opportunity": {
    name_id: tableColumnTypes.name_id,
    isDeceased: tableColumnTypes.isDeceased,
    phone_number: { ...tableColumnTypes.phone_number, display: false, mandatory: false },
    dob: tableColumnTypes.dob,
    ssn: tableColumnTypes.ssn,
    patientStatus: { ...tableColumnTypes.patientStatus, display: true, mandatory: false },
    assignee: { ...tableColumnTypes.assignee, display: true, mandatory: false },
    facility: tableColumnTypes.facility,
    physician: tableColumnTypes.physician,
    primaryInsurancePlan: { ...tableColumnTypes.primaryInsurancePlan, display: true, mandatory: false },
    secondaryInsurancePlan: tableColumnTypes.secondaryInsurancePlan,
    tertiaryInsurancePlan: tableColumnTypes.tertiaryInsurancePlan,
    primaryDiagnosis: { ...tableColumnTypes.primaryDiagnosis, display: true, mandatory: false },
    applicationStatus: { ...tableColumnTypes.applicationStatus, display: true, mandatory: false },
    agreementSignatureDate: tableColumnTypes.agreementSignatureDate,
    followupDate: tableColumnTypes.followupDate,
    creationDate: tableColumnTypes.creationDate,
    edit: tableColumnTypes.edit,
    star: tableColumnTypes.star
  },
  "all-applications": {
    star: tableColumnTypes.star,
    name_id: tableColumnTypes.name_id,
    isDeceased: tableColumnTypes.isDeceased,
    phone_number: { ...tableColumnTypes.phone_number, display: false, mandatory: false },
    dob: tableColumnTypes.dob,
    ssn: tableColumnTypes.ssn,
    assistanceProgram: tableColumnTypes.assistanceProgram,
    providedBy: tableColumnTypes.providedBy,
    apType: tableColumnTypes.apType,
    associatedDrug: tableColumnTypes.associatedDrug,
    applicationStatus: tableColumnTypes.applicationStatus,
    fundBalance: tableColumnTypes.fundBalance,
    validThrough: tableColumnTypes.validThrough,
    applicationSignatureStatus: tableColumnTypes.applicationSignatureStatus,
    approvedAmount: { ...tableColumnTypes.approvedAmount, display: false, mandatory: false },
    approvedDate: tableColumnTypes.approvedDate,
    member_id: { ...tableColumnTypes.member_id, display: false, mandatory: false },
    assignee: { ...tableColumnTypes.assignee, display: false, mandatory: false },
    facility: { ...tableColumnTypes.facility, display: false, mandatory: false },
    physician: { ...tableColumnTypes.physician, display: false, mandatory: false },
    patientStatus: { ...tableColumnTypes.patientStatus, display: false, mandatory: false },
    agreementSignatureDate: { ...tableColumnTypes.agreementSignatureDate, display: false, mandatory: false },
    followupDate: { ...tableColumnTypes.followupDate, display: false, mandatory: false },
    primaryInsurancePlan: { ...tableColumnTypes.primaryInsurancePlan, display: false, mandatory: false },
    secondaryInsurancePlan: { ...tableColumnTypes.secondaryInsurancePlan, display: false, mandatory: false },
    tertiaryInsurancePlan: { ...tableColumnTypes.tertiaryInsurancePlan, display: false, mandatory: false },
    latestClaimDate: tableColumnTypes.latestClaimDate
  },
  claim: {
    status: tableColumnTypes.claimStatus,
    claimDate: tableColumnTypes.claimDate,
    followupDate: { ...tableColumnTypes.followupDate, title: "Follow-up Date" },
    serviceDate: tableColumnTypes.serviceDate,
    submittedDate: tableColumnTypes.submittedDate,
    approvedDate: { ...tableColumnTypes.approvedDate, display: true },
    requestedAmount: tableColumnTypes.requestedAmount,
    receivedDate: tableColumnTypes.receivedDate,
    balance: tableColumnTypes.balance,
    actions: tableColumnTypes.actions
  },

  "all-claims": {
    star: { ...tableColumnTypes.star, display: true, mandatory: false, title: null },
    name_id: { ...tableColumnTypes.name_id, display: true, mandatory: true },
    isDeceased: tableColumnTypes.isDeceased,
    phone_number: { ...tableColumnTypes.phone_number, display: false, mandatory: false },
    dob: { ...tableColumnTypes.dob, display: false, mandatory: false },
    ssn: { ...tableColumnTypes.ssn, display: false, mandatory: false },
    claimDate: { ...tableColumnTypes.claimDate, mandatory: true },
    claimStatus: { ...tableColumnTypes.claimStatus, key: "claimStatus", dataIndex: "claimStatus" },
    claimFollowupDate: tableColumnTypes.claimFollowupDate,
    claimSubmittedDate: tableColumnTypes.claimSubmittedDate,
    serviceDate: { ...tableColumnTypes.serviceDate },
    claimFacility: {
      ...tableColumnTypes.facility,
      display: false,
      mandatory: false,
      title: "Claim Facility",
      dataIndex: "claimProvider",
      key: "claimFacility"
    },
    providedBy: tableColumnTypes.providedBy,
    assistanceProgramName: { ...tableColumnTypes.assistanceProgramName, mandatory: false },
    apType: { ...tableColumnTypes.apType, display: false, mandatory: false },
    claimTotalAmount: tableColumnTypes.claimTotalAmount,
    claimPaidAmount: { ...tableColumnTypes.claimPaidAmount, display: false, mandatory: false },
    approvedDate: { ...tableColumnTypes.approvedDate, display: false, mandatory: false, title: "Claim Approved Date" },
    dateReceived: { ...tableColumnTypes.dateReceived, display: false, mandatory: false },
    claimDeniedAmount: { ...tableColumnTypes.claimDeniedAmount, display: false, mandatory: false },
    ClaimPatientResponsibilityAmount: {
      ...tableColumnTypes.ClaimPatientResponsibilityAmount,
      display: false,
      mandatory: false
    },
    balance: { ...tableColumnTypes.balance },
    fundBalance: { ...tableColumnTypes.fundBalance, display: false, mandatory: false },
    checkNumber_ACH: { ...tableColumnTypes.checkNumber_ACH, display: false, mandatory: false },
    medications: { ...tableColumnTypes.medications, display: false, mandatory: false },
    facility: tableColumnTypes.facility,
    patientStatus: { ...tableColumnTypes.patientStatus, mandatory: false },
    assignee: { ...tableColumnTypes.assignee, display: false, mandatory: false, title: "Patient Assignee" },
    physician: { ...tableColumnTypes.physician, display: false, mandatory: false },
    agreementSignatureDate: { ...tableColumnTypes.agreementSignatureDate, display: false, mandatory: false },
    followupDate: { ...tableColumnTypes.followupDate, display: false },
    primaryInsurancePlan: { ...tableColumnTypes.primaryInsurancePlan, display: false, mandatory: false },
    secondaryInsurancePlan: tableColumnTypes.secondaryInsurancePlan,
    tertiaryInsurancePlan: tableColumnTypes.tertiaryInsurancePlan
  },
  "pharmacy-order": {
    drug: { ...tableColumnTypes.drug, key: "drug", dataIndex: "drug" },
    dosageQuantity: tableColumnTypes.dosageQuantity,
    drugManufacturer: tableColumnTypes.drugManufacturer,
    adminDate: { ...tableColumnTypes.adminDate, title: "Admin Date" },
    orderDate: tableColumnTypes.orderDate,
    statusOfOrder: tableColumnTypes.statusOfOrder,
    actions: tableColumnTypes.actions
  }
};

export { tableColumns };
