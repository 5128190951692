import React, { useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Typography } from "antd";
import { useLocation } from "react-router";
import styled from "styled-components";

import { selectSelectedView } from "../store/selector";
import { TableSection } from "../../src/component/TableSection/TableSection";
import financialBackground from "../assets/financial-background.png";
import ACTIONS from "../store/action";
import { selectedViews } from "../constant/table";
import { WHITE } from "../constant/colors";

const { Text, Title } = Typography;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DashboardScreen = styled.div`
  margin: 0px 15px 0px 15px;
  position: relative;
  background: ${WHITE};
  border-radius: 10px;
  padding: 10px 15px 0px 15px;
`;

const DashboardComponent = ({ t, selectedView, setSelectedView }) => {
  let query = useQuery();

  useEffect(() => {
    if (!selectedView) {
      const currSelectedView = query.get("selectedView");
      if (currSelectedView && Object.values(selectedViews).includes(currSelectedView)) {
        setSelectedView(currSelectedView);
      }
    }
  }, []);

  return (
    <DashboardScreen direction={"vertical"}>
      {!selectedView ? (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", height: "83vh" }}>
          <div style={{ padding: "30px" }}>
            <Title strong>{t("welcome")}!</Title>
            <Text style={{ fontSize: "20px" }}>{t("main_subtitle")}</Text>
          </div>
          <img
            style={{ maxWidth: "100%", alignSelf: "end" }}
            className="dashboard-background"
            src={financialBackground}
            alt=""
          />
        </div>
      ) : (
        <TableSection />
      )}
    </DashboardScreen>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedView: selectSelectedView
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedView: (selectedView) => dispatch(ACTIONS.setSelectedView(selectedView))
});

const Dashboard = compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(DashboardComponent);

export default Dashboard;
