import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";

import {
  UNSPECIFIED_MEMBER_ID,
  UNSPECIFIED_RX_MEMBER_ID,
  UNSPECIFIED_PAYER_NAME,
  BENEFIT_TYPE
} from "../constant/coverages";

export const generateDefaultMemberID = ({ coverageType, patientId, isRx = false }) => {
  let coverageTypeIndex;

  switch (coverageType) {
    case "primary":
      coverageTypeIndex = 0;
      break;
    case "secondary":
      coverageTypeIndex = 1;
      break;
    case "tertiary":
      coverageTypeIndex = 2;
      break;
  }
  const memberIdType = isRx ? UNSPECIFIED_RX_MEMBER_ID : UNSPECIFIED_MEMBER_ID;
  return memberIdType.concat(`_${coverageTypeIndex}_${patientId}`);
};

export const formatCoverage = (coverage, coverageType, patientId) => {
  let formattedCoverage = {
    coverageType,
    policyType: coverage.planMarket,
    ...coverage,
    id: uuidv4(),
    activeDate: coverage.activeDate ? moment(coverage.activeDate) : null,
    memberId: coverage.memberId || generateDefaultMemberID({ coverageType, patientId }),
    payerName: coverage.payerName ? coverage.payerName : UNSPECIFIED_PAYER_NAME,
    benefitType: coverage.benefitType || BENEFIT_TYPE.MEDICAL // we default to medical when creating coverage from find assistance program -> create coverage
  };
  if (coverage?.benefitType && coverage.benefitType !== BENEFIT_TYPE.MEDICAL) {
    formattedCoverage.rxMemberId =
      coverage.rxMemberId || generateDefaultMemberID({ coverageType, patientId, isRx: true });
  }

  return formattedCoverage;
};

export const getBenfitTypeOptions = (t) => ({
  [BENEFIT_TYPE.MEDICAL]: t("patientClassicCoverageDetailsOverview.medical_benefit_type"),
  [BENEFIT_TYPE.MEDICAL_AND_PRESCRIPTION]: t(
    "patientClassicCoverageDetailsOverview.medical_and_prescription_benefit_type"
  ),
  [BENEFIT_TYPE.PRESCRIPTION]: t("patientClassicCoverageDetailsOverview.prescription_benefit_type")
});
