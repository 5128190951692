import React from "react";
import { Typography } from "antd";
const { Text } = Typography;
import { withTranslation } from "react-i18next";
import { useGetSetting } from "../../hooks/getSetting";

const PatientPageCount = ({ t, range, total, isAccurateCount, isEnrollment = false }) => {
  const [treshold] = useGetSetting(["dashboard.max-count-result-threshold"]);

  return (
    <>
      <Text>
        {t("patients_pagination.patients_page_count", {
          range_start: range[0],
          range_end: range[1] ? range[1] : range[0],
          total: isAccurateCount ? total : t("patients_pagination.many_patients", { max_count_treshold: treshold }),
          title: isEnrollment ? t("enrollments") : t("patients")
        })}
      </Text>
    </>
  );
};

export default withTranslation()(PatientPageCount);
