import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { selectPatientDetails } from "../../../store/selector";
import { useGetSetting } from "../../../hooks/getSetting";
import InsuranceForm from "../InsuranceForm";

const CardWrapper = styled.div`
  margin: 16px 0;
  width: 100%;
`;

const CardBody = styled.div`
  padding: 24px 24px 0px 24px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
`;

const Card = ({ children }) => {
  return (
    <CardWrapper>
      <CardBody>{children}</CardBody>
    </CardWrapper>
  );
};

const InsuranceCard = ({ validEbvPayers, form, index, customPayer, coveragesData }) => {
  const [skipBenefitInvestigation] = useGetSetting(["skipBenefitInvestigation"]);

  return (
    <Card>
      <InsuranceForm
        form={form}
        validEbvPayers={validEbvPayers}
        initialValues={{
          ...coveragesData[index],
          customPayer
        }}
        fieldNamePrefix={["coverages", "patientInsuranceRows", index]}
        skipBenefitInvestigation={skipBenefitInvestigation}
        coveragesData={coveragesData}
        index={index}
      />
    </Card>
  );
};

const mapStateToProps = createStructuredSelector({
  patientDetails: selectPatientDetails
});

export default compose(connect(mapStateToProps))(InsuranceCard);
