import axios from "axios";
import moment from "moment-timezone";
import _ from "lodash";

import { PAGE_SIZE, selectedViews } from "../constant/table";
import { authHeader } from "@tailormed/common-client/services/auth";
import { getAuthInstance } from "@tailormed/common-client/services/auth";
import { oktaConfig } from "../constant/okta";
import { DATE_FORMAT, setDateRange } from "../utils/date";
import { ALL_TIMES, DRUG_SEARCH_OPTIONS } from "../constant/filters";
import { MANUAL_AUTOMATION_SOURCES } from "../constant/coverages";
import { INTERNAL_PROGRAM } from "../constant/assistanceProgram";
import { buildQueryParamsSearchStrings } from "../utils/search";

const SOURCE = "financial_navigation";

const SERVER_URL = `${window.location.origin.replace("4500", "1776")}/`;
const API_VERSION_1 = "api/v1/";
const USERS = "users/";
const PATIENTS = "patients/";
const ApiBaseUrl = `${SERVER_URL}${API_VERSION_1}`;
const CLAIMS = "claims";
const CLAIM_NOTE = "claimNote";
const CLAIM_ATTACHMENTS = "claimattachments";
const PHARMACY_PATIENTS = "pharmacypatients";
const ORDERS = "orders";
const REQUIRED_SIGNER = "requiredsigners";
const REMOTE_SIGNATURE_REQUESTS = "remotesignaturerequests";
const FILL_NOW = "fillnow";

console.log("----------------------------------");
console.log("VERSION:", process.env.REACT_APP_VERSION);
console.log("SERVER_URL:", SERVER_URL);
console.log("----------------------------------");

axios.defaults.withCredentials = true;

const ApiMap = {};
const ApiCallerMap = {};

const CallerType = Object.freeze({
  Generic: 0,
  Auth: 1
});

const ApiNames = Object.freeze({
  Login: "Login",
  LoggedUser: "LoggedUser",
  AssistanceProgramSearch: "AssistanceProgramSearch",
  AssistanceProgram: "AssistanceProgram",
  CheckExpressEnrollConfigurationByApId: "CheckExpressEnrollConfigurationByApId",
  Search: "Search",
  PhiSearch: "PhiSearch",
  Settings: "Settings",
  Users: "Users",
  PhiSearchFreeText: "PhiSearchFreeText",
  ChangeProviderNetwork: "ChangeProviderNetwork",
  SearchFreeText: "SearchFreeText",
  Assignees: "Assignees",
  UserGroups: "UserGroups",
  Diagnosis: "Diagnosis",
  PatientDetails: "PatientDetails",
  PatientDetailsNonPhi: "PatientDetailsNonPhi",
  Providers: "Providers",
  getAPSponsors: "getAPSponsors",
  GetUnits: "GetUnits",
  GetVisitTypes: "GetVisitTypes",
  AllPatientsPhiSearch: "AllPatientsPhiSearch",
  AllApplicationsPhiSearch: "AllApplicationsPhiSearch",
  VisitSchedulePhiSearch: "VisitSchedulePhiSearch",
  PendingRenewalPhiSearch: "PendingRenewalPhiSearch",
  HighPrioritiesPhiSearch: "HighPrioritiesPhiSearch",
  AllPatientsSearch: "AllPatientsSearch",
  AllApplicationsSearch: "AllApplicationsSearch",
  AllClaimsPhiSearch: "AllClaimsPhiSearch",
  AllClaimsSearch: "AllClaimsSearch",
  VisitScheduleSearch: "VisitScheduleSearch",
  PendingRenewalSearch: "PendingRenewalSearch",
  HighPrioritiesSearch: "HighPrioritiesSearch",
  PotentialSavings: "PotentialSavings",
  PotentialSavingsNonPhi: "PotentialSavingsNonPhi",
  CreateDrug: "CreateDrug",
  CountByStatus: "CountByStatus",
  PatientJourney: "PatientJourney",
  PatientJourneyNonPhi: "PatientJourneyNonPhi",
  GenericServices: "GenericServices",
  PatientAutomate: "PatientAutomate",
  ChangeDrugHiddenStatus: "ChangeDrugHiddenStatus",
  UpdateDiscontinuedDate: "UpdateDiscontinuedDate",
  UpdateOOPEstimation: "UpdateOOPEstimation",
  GetOOPEstimations: "GetOOPEstimations",
  PotentialSavingsJourney: "PotentialSavingsJourney",
  ApproveInsuranceOpportunity: "ApproveInsuranceOpportunity",
  AddAssistanceProgram: "AddAssistanceProgram",
  AssociateDrug: "AssociateDrug",
  PatientCoverages: "PatientCoverages",
  PatientUnknownAndInactiveInsuranceInfo: "PatientUnknownAndInactiveInsuranceInfo",
  Policies: "Policies",
  ApplicationAttachments: "ApplicationAttachments",
  PotentialSaving: "PotentialSaving",
  DocusignCreate: "DocusignCreate",
  ApplicationEdit: "ApplicationEdit",
  ApplicationEditHistorical: "ApplicationEditHistorical",
  ApplicationEditNonPhi: "ApplicationEditNonPhi",
  ApplicationDelete: "ApplicationDelete",
  PatientReapply: "PatientReapply",
  PatientReapplyNonPhi: "PatientReapplyNonPhi",
  RelevanceForRenewal: "RelevanceForRenewal",
  shippingOptions: "shippingOptions",
  getIDPConfig: "GetIDPConfig",
  searchSavedList: "searchSavedList",
  CreateFilterList: "CreateFilterList",
  UpdateFilterList: "UpdateFilterList",
  FindSavedListById: "FindSavedListById",
  DeleteSavedList: "DeleteSavedList",
  getAllClaims: "getAllClaims",
  CreateClaim: "CreateClaim",
  UpdateClaim: "UpdateClaim",
  CreateAttachmentClaim: "CreateAttachmentClaim",
  DeleteAttachmentClaim: "DeleteAttachmentClaim",
  ToggleClaimActiveStatus: "ToggleClaimActiveStatus",
  DuplicateClaimAttachments: "DuplicateClaimAttachments",
  FetchPapPDFMetaData: "FetchPapPDFMetaData"
});

function getAccessToken() {
  const oktaClientId = sessionStorage.getItem("oktaClientId");
  const oktaAuth = getAuthInstance(oktaConfig.redirectUri, oktaClientId);
  return oktaAuth.getAccessToken();
}

async function axiosWithAuthentication(config) {
  const token = getAccessToken();
  config.headers = authHeader(token);
  return axios(config);
}

const add = (name, action, caller = CallerType.Generic) => {
  Object.assign(ApiCallerMap, { [name]: caller });
  Object.assign(ApiMap, { [name]: (...args) => action(...args) });
};

const addAuth = (name, action) => add(name, action, CallerType.Auth);

const orderMap = {
  ascend: "ASC",
  descend: "DESC"
};

const formateSorting = (sorter) => ({
  ...(sorter && {
    sorting: {
      orderBy: sorter.columnKey,
      sort: orderMap[sorter?.order]
    }
  })
});

const parseSearchFormParams = ({ selectedView, page, limit, filters = {}, sorter, searchBy }) => {
  const skipValue = (page - 1) * PAGE_SIZE;
  const {
    multiples,
    dates,
    starredPatients,
    inActiveTreatment,
    hideDeceasedPatients,
    expressEnrollEligible,
    claimExists,
    groupPatientVisits,
    insuranceCombination,
    isUnassigned
  } = filters;

  const selectedViewField = selectedView === "high-priority" ? "top-opportunity" : selectedView;

  const numberFilters = {};
  const datesFilters = {};
  const multiplesFilters = {};
  let internalProgram = false;

  if (Object.keys(filters).length) {
    dates &&
      Object.entries(dates).forEach(([dateFilter, { fromDate, toDate, value }]) => {
        // Note: MVP-14581 Parse Dates based on the saved fromDate, toDate, and value. Value is one of the defined date range criteria we support.
        if (fromDate && toDate && !value) {
          datesFilters[dateFilter] = [
            moment(fromDate, DATE_FORMAT).startOf("day").format("YYYY-MM-DD HH:mm"),
            moment(toDate, DATE_FORMAT).endOf("day").format("YYYY-MM-DD HH:mm")
          ];
        } else if (selectedViewField !== selectedViews.visitSchedule && dateFilter === "visitScheduleDateRange") {
          datesFilters[dateFilter] = setDateRange(ALL_TIMES);
        } else datesFilters[dateFilter] = setDateRange(value || fromDate);
      });

    multiples &&
      Object.entries(multiples).forEach(([multipleFilter, selectedValues]) => {
        multiplesFilters[multipleFilter] = [];

        if (multipleFilter == "rxnormDrugs" || multipleFilter == "claimRxnormDrugs") {
          Object.values(selectedValues).forEach(({ tty, ttyCui, bnName, pinName }) => {
            multiplesFilters[multipleFilter].push({ tty, ttyCui, bnName, pinName });
          });
        } else {
          Object.entries(selectedValues).forEach(([, { value }]) => {
            multiplesFilters[multipleFilter].push(value);
          });
        }
      });

    if (multiplesFilters.potentialSavingsTypes?.length) {
      if (multiplesFilters.potentialSavingsTypes.includes(INTERNAL_PROGRAM)) {
        // Note: if user selected the internal option, then pass the flag with the payload
        internalProgram = true;
      }

      // Note: Remove the internal program filter from the priorities list since it's not related.
      multiplesFilters.potentialSavingsTypes = multiplesFilters.potentialSavingsTypes.filter(
        (value) => value !== INTERNAL_PROGRAM
      );
    }

    if (filters?.numbers && Object.keys(filters.numbers).length) {
      Object.entries(filters.numbers).forEach(([key, value]) => value && (numberFilters[key] = value));
    }
  }

  const parsedFilters = {
    ...numberFilters,
    ...datesFilters,
    ...multiplesFilters,
    selectedView: selectedViewField,
    starredPatients,
    inActiveTreatment,
    hideDeceasedPatients,
    expressEnrollEligible,
    claimExists,
    groupPatientVisits,
    insuranceCombination,
    isUnassigned,
    internalProgram
  };
  // NOTE: We remove all the filters that are empty or not defined
  const usedParsedFilters = _.pickBy(parsedFilters, (value) => {
    if (_.isArray(value)) {
      return value.length;
    } else if (_.isObject(value)) {
      return !_.isEmpty(value);
    } else {
      return !_.isNil(value);
    }
  });

  const searchData = {
    page,
    skip: skipValue,
    limit: PAGE_SIZE || limit,
    filters: usedParsedFilters,
    ...formateSorting(sorter),
    ...(searchBy && { searchBy }),
    ...(selectedView && { selectedView: selectedViewField })
  };

  return searchData;
};

const buildSearchRequest = ({ selectedView, page, limit, filters, sorter, isPhi }) => {
  const MAX_HTTP_URL_SIZE = 1500; // NOTE: We take a 500 chars buffer for the rest of the URL
  const parsedFilters = parseSearchFormParams({ selectedView, page, limit, filters, sorter });
  const queryParamsFilters = buildQueryParamsSearchStrings(parsedFilters);

  let request = {};
  const url = `${SERVER_URL}${API_VERSION_1}${PATIENTS}dashboard/${isPhi ? "phi-" : ""}search`;

  if (queryParamsFilters.length < MAX_HTTP_URL_SIZE) {
    const urlWithQueryParams = `${url}?${queryParamsFilters}`;

    request = {
      method: "GET",
      url: urlWithQueryParams
    };
  } else {
    request = {
      method: "POST",
      url,
      data: parsedFilters
    };
  }

  return request;
};

/* Note: This will parse the server filter values into the actual Filter Inputs/controls values. */
const serverFiltersDecoder = (filters, serverFormattedFilters) => {
  const decodedFilters = { ...filters };
  Object.keys(decodedFilters).forEach((key) => {
    if (key in serverFormattedFilters.dates) {
      decodedFilters[key] = serverFormattedFilters?.dates[key]?.value;
    } else if (key === "drugs") {
      decodedFilters[key] = serverFormattedFilters?.multiples?.drugsNames;
    }
  });
  return decodedFilters;
};

add(ApiNames.Login, (username, password) => {
  const data = new FormData();
  data.append("username", username);
  data.append("password", password);
  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}token/frontend`,
    data
  };
});

add(ApiNames.getIDPConfig, () => ({
  method: "GET",
  url: `${SERVER_URL}${API_VERSION_1}app/idp`
}));

addAuth(ApiNames.LoggedUser, (token) => ({
  method: "GET",
  url: `${SERVER_URL}${API_VERSION_1}${USERS}logged-user/frontend`,
  headers: authHeader(token)
}));

addAuth(ApiNames.Users, (token) => ({
  method: "GET",
  url: `${SERVER_URL}${API_VERSION_1}${USERS}frontend`,
  headers: authHeader(token)
}));

addAuth(ApiNames.Assignees, (token) => ({
  method: "GET",
  url: `${SERVER_URL}${API_VERSION_1}${USERS}assignable`,
  headers: authHeader(token)
}));

addAuth(ApiNames.UserGroups, (token) => ({
  method: "GET",
  url: `${SERVER_URL}${API_VERSION_1}userGroups`,
  headers: authHeader(token)
}));

addAuth(ApiNames.Settings, (token) => ({
  method: "GET",
  url: `${SERVER_URL}${API_VERSION_1}settings`,
  headers: authHeader(token)
}));

addAuth(ApiNames.PhiSearch, (token, selectedView, page = 1, limit = 25, filters = {}, sorter = {}) => {
  const request = buildSearchRequest({ selectedView, page, limit, filters, sorter, isPhi: true });
  return {
    ...request,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.AllPatientsPhiSearch, (token, page = 1, limit = 25, filters = {}, sorter = {}) => {
  const request = buildSearchRequest({ selectedView: "all-patients", page, limit, filters, sorter, isPhi: true });
  return {
    ...request,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.AllApplicationsPhiSearch, (token, page = 1, limit = 25, filters = {}, sorter = {}) => {
  const request = buildSearchRequest({ selectedView: "all-applications", page, limit, filters, sorter, isPhi: true });
  return {
    ...request,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.AllClaimsPhiSearch, (token, page = 1, limit = 25, filters = {}, sorter = {}) => {
  const request = buildSearchRequest({ selectedView: "all-claims", page, limit, filters, sorter, isPhi: true });
  return {
    ...request,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.VisitSchedulePhiSearch, (token, page = 1, limit = 25, filters = {}, sorter = {}) => {
  const request = buildSearchRequest({ selectedView: "visit-schedule", page, limit, filters, sorter, isPhi: true });
  return {
    ...request,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.PendingRenewalPhiSearch, (token, page = 1, limit = 25, filters = {}, sorter = {}) => {
  const request = buildSearchRequest({ selectedView: "pending-renewal", page, limit, filters, sorter, isPhi: true });
  return {
    ...request,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.HighPrioritiesPhiSearch, (token, page = 1, limit = 25, filters = {}, sorter = {}) => {
  const request = buildSearchRequest({ selectedView: "high-priority", page, limit, filters, sorter, isPhi: true });
  return {
    ...request,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.AllPatientsSearch, (token, page = 1, limit = 25, filters = {}, sorter = {}) => {
  const request = buildSearchRequest({ selectedView: "all-patients", page, limit, filters, sorter });
  return {
    ...request,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.AllApplicationsSearch, (token, page = 1, limit = 25, filters = {}, sorter = {}) => {
  const request = buildSearchRequest({ selectedView: "all-applications", page, limit, filters, sorter });
  return {
    ...request,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.VisitScheduleSearch, (token, page = 1, limit = 25, filters = {}, sorter = {}) => {
  const request = buildSearchRequest({ selectedView: "visit-schedule", page, limit, filters, sorter });
  return {
    ...request,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.PendingRenewalSearch, (token, page = 1, limit = 25, filters = {}, sorter = {}) => {
  const request = buildSearchRequest({ selectedView: "pending-renewal", page, limit, filters, sorter });
  return {
    ...request,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.HighPrioritiesSearch, (token, page = 1, limit = 25, filters = {}, sorter = {}) => {
  const request = buildSearchRequest({ selectedView: "high-priority", page, limit, filters, sorter });
  return {
    ...request,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.AllClaimsSearch, (token, page = 1, limit = 25, filters = {}, sorter = {}) => {
  const request = buildSearchRequest({ selectedView: "all-claims", page, limit, filters, sorter });
  return {
    ...request,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.Search, (token, selectedView, page = 1, limit = 25, filters = {}, sorter = {}) => {
  const request = buildSearchRequest({ selectedView, page, limit, filters, sorter });
  return {
    ...request,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.AssistanceProgram, (token, searchType, query) => {
  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}custom-dropdown/searchType=${searchType}&query=${query}`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.PhiSearchFreeText, (token, page, text, searchBy, sorter = {}) => {
  // NOTE: When searching by free text we always assume that there's PHI in the request, so we use the POST method
  const limit = PAGE_SIZE;
  const parsedSearchParams = parseSearchFormParams({ selectedView: "search-results", page, limit, sorter, searchBy });
  const urlEncodedData = buildQueryParamsSearchStrings(parsedSearchParams);
  const data = {
    query: text
  };
  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}${PATIENTS}dashboard/phi-search?${urlEncodedData}`,
    headers: authHeader(token),
    data
  };
});

addAuth(ApiNames.ChangeProviderNetwork, (token, policy_id, patient_id, journey_id, network) => {
  const data = new FormData();
  data.append("journey_id", journey_id);
  data.append("patient_id", patient_id);
  data.append("network", network);
  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}policy/${policy_id}/changeprovidernetwork`,
    headers: authHeader(token),
    data
  };
});

addAuth(ApiNames.SearchFreeText, (token, page = 1, text, searchBy, sorter = {}) => {
  // NOTE: When searching by free text we always assume that there's PHI in the request, so we use the POST method
  const limit = PAGE_SIZE;
  const parsedSearchParams = parseSearchFormParams({ selectedView: "search-results", page, limit, sorter, searchBy });
  const urlEncodedData = buildQueryParamsSearchStrings(parsedSearchParams);
  const data = {
    query: text
  };
  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}${PATIENTS}dashboard/phi-search?${urlEncodedData}`,
    headers: authHeader(token),
    data
  };
});

addAuth(ApiNames.AssistanceProgram, (token, id) => {
  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}assistancePrograms/${id}`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.CheckExpressEnrollConfigurationByApId, (token, id) => {
  return {
    method: "HEAD",
    url: `${SERVER_URL}${API_VERSION_1}assistanceprogram/${id}/expressenrollconfigurations`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.PatientDetails, (token, patientId) => {
  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}financialNavigationPatientPhis/${patientId}`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.PatientDetailsNonPhi, (token, patientId) => {
  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}financialNavigationPatients/${patientId}`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.GetUnits, (token) => ({
  method: "GET",
  url: `${SERVER_URL}${API_VERSION_1}setting/getAllUnits`,
  headers: authHeader(token)
}));

addAuth(ApiNames.GetVisitTypes, (token) => ({
  method: "GET",
  url: `${SERVER_URL}${API_VERSION_1}patients/visitTypes`,
  headers: authHeader(token)
}));

addAuth(ApiNames.Providers, (token, providerType) => ({
  method: "GET",
  url: `${SERVER_URL}${API_VERSION_1}providers`,
  params: { type: providerType },
  headers: authHeader(token)
}));

addAuth(ApiNames.getAPSponsors, (token) => {
  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}assistanceprograms/getAPSponsors`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.PotentialSavings, (token, queryIds) => {
  const data = queryIds.map((id) => ["ids[]", id]);

  const urlEncodedData = data
    .map((entry) => `${encodeURIComponent(entry[0])}=${encodeURIComponent(entry[1])}`)
    .join("&");

  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}financialNavigationPotentialSavingPhis?${urlEncodedData}`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.PotentialSavingsNonPhi, (token, queryIds) => {
  const data = queryIds.map((id) => ["ids[]", id]);

  const urlEncodedData = data
    .map((entry) => `${encodeURIComponent(entry[0])}=${encodeURIComponent(entry[1])}`)
    .join("&");

  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}financialNavigationPotentialSavings?${urlEncodedData}`,
    headers: authHeader(token)
  };
});

//TODO: Amit drug
const createDrugAPI = async (formValues, drugData, patientId, skipAutomations = false) => {
  const formData = new FormData();

  Object.entries(formValues).map(([title, value]) => {
    if (value === undefined) return;

    if (title === "dosage") {
      formData.append(`drug[dosageDisplay]`, value);
      if (typeof value === "string" && value.includes("-")) {
        value = value.split("-")[0];
      }
    }

    formData.append(`drug[${title}]`, value);
  });

  Object.entries(drugData).map(([title, value]) => {
    formData.append(`drug[drug][${title}]`, value);
  });

  formData.append("patientId", patientId);
  formData.append("skipAutomations", skipAutomations);

  const data = [...formData.entries()];

  const urlEncodedData = data
    .map((entry) => `${encodeURIComponent(entry[0])}=${encodeURIComponent(entry[1])}`)
    .join("&");

  return await axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patient/drug`,
    data: urlEncodedData
  });
};

const addDrugAPI = async ({ patientId, rxnormDrug, medicationData }) => {
  const data = {
    patientId,
    medicationData,
    rxnormDrug
  };

  return await axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patient/${patientId}/drug`,
    data
  });
};

//[DRUGS-TODO-AMIT] - check delete of non iv
const deleteMedicationAPI = async ({ phiMedicationId, patientId }) => {
  return axiosWithAuthentication({
    method: "DELETE",
    url: `${SERVER_URL}${API_VERSION_1}patient/${patientId}/drug`,
    data: { phiMedicationId }
  });
};

const editMedicationAPI = async ({ patientId, phiMedicationId, rxnormDrug, medicationData }) => {
  return await axiosWithAuthentication({
    method: "PUT",
    url: `${SERVER_URL}${API_VERSION_1}patient/${patientId}/drug`,
    data: { phiMedicationId, rxnormDrug, medicationData }
  });
};

addAuth(ApiNames.CountByStatus, (token) => {
  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}patients/countbystatus/`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.PatientJourney, (token, journeyId) => {
  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}financialNavigationPatientJourneyPhis/${journeyId}`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.PatientJourneyNonPhi, (token, journeyId) => {
  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}financialNavigationPatientJourneys/${journeyId}`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.GenericServices, (token, queryIds) => {
  const data = queryIds.map((id) => ["ids[]", id]);

  const urlEncodedData = data
    .map((entry) => `${encodeURIComponent(entry[0])}=${encodeURIComponent(entry[1])}`)
    .join("&");

  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}genericServices?${urlEncodedData}`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.PatientAutomate, (token, patientId, automateRule) => {
  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patients/automate/${patientId}`,
    headers: authHeader(token),
    data: { automateRule }
  };
});

addAuth(ApiNames.ChangeDrugHiddenStatus, (token, patientId, drugId, hide) => {
  const data = new FormData();
  data.append("patientId", patientId);
  data.append("drugId", drugId);
  data.append("hide", hide);

  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patients/change-drug-hidden-status`,
    headers: authHeader(token),
    data
  };
});

addAuth(ApiNames.UpdateDiscontinuedDate, (token, patientId, journeyEvent, discontinuedDate) => {
  const data = new FormData();
  data.append("patient", patientId);
  data.append("journeyEvent", journeyEvent);
  data.append("discontinuedDate", moment(discontinuedDate));

  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patients/update-discontinued-date`,
    headers: authHeader(token),
    data
  };
});

addAuth(ApiNames.UpdateOOPEstimation, (token, data) => {
  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}journey/updateOOPEstimation`,
    headers: authHeader(token),
    data
  };
});

addAuth(ApiNames.GetOOPEstimations, (token, data) => {
  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}journey/getOOPEstimations`,
    headers: authHeader(token),
    data
  };
});

addAuth(ApiNames.PatientCoverages, (token, patientId) => {
  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}coverages?patient=${patientId}`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.PatientUnknownAndInactiveInsuranceInfo, (token, patientId) => {
  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}coverage/${patientId}/getUnknownAndInactiveInsuranceInfoForPatient`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.Policies, (token, queryIds) => {
  const data = queryIds.map((id) => ["ids[]", id]);

  const urlEncodedData = data
    .map((entry) => `${encodeURIComponent(entry[0])}=${encodeURIComponent(entry[1])}`)
    .join("&");

  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}policies?${urlEncodedData}`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.ApplicationEdit, (token, papId, papData, applicationIdToUpdate, isInternal = false) => {
  const data = {
    "financial-navigation-potential-saving-phi": { ...papData, applicationIdToUpdate }
  };

  return {
    method: "PUT",
    url: `${SERVER_URL}${API_VERSION_1}financialNavigationPotentialSavingPhis/${papId}?isInternal=${
      isInternal === true ? "1" : "0"
    }`,
    headers: authHeader(token),
    data
  };
});

addAuth(
  ApiNames.ApplicationEditNonPhi,
  (token, papId, papData, applicationIdToUpdate, isInternal = false, associatedDrugs) => {
    const data = {
      "financial-navigation-potential-saving": { ...papData, applicationIdToUpdate, associatedDrugs }
    };

    return {
      method: "PUT",
      url: `${SERVER_URL}${API_VERSION_1}financialNavigationPotentialSavings/${papId}?isInternal=${
        isInternal === true ? "1" : "0"
      }`,
      headers: authHeader(token),
      data
    };
  }
);

addAuth(ApiNames.ApplicationEditHistorical, (token, patientId, papId, updatedApplications) => {
  const data = {
    applicationsToUpdate: updatedApplications.map((app) => ({
      ...app
    })),
    patientId
  };

  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}paps/${papId}/updateApplications/`,
    headers: authHeader(token),
    data
  };
});

addAuth(ApiNames.ApplicationDelete, (token, patientId, papId, applicationId) => {
  const data = {
    removedApplicationIDs: [applicationId],
    patientId
  };

  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}paps/${papId}/updateApplications/`,
    headers: authHeader(token),
    data
  };
});

addAuth(ApiNames.PotentialSaving, (token, papId) => {
  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}financialNavigationPotentialSavingPhis/${papId}`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.PatientReapply, (token, papId, status, createNewApplication, associatedDrugs) => {
  const data = {
    status,
    createNewApplication,
    associatedDrugs
  };
  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}financialNavigationPotentialSavingPhis/${papId}/reapply`,
    headers: authHeader(token),
    data
  };
});

addAuth(ApiNames.PatientReapplyNonPhi, (token, papId, status, createNewApplication, associatedDrugs) => {
  const data = {
    status,
    createNewApplication,
    associatedDrugs
  };
  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}financialNavigationPotentialSavings/${papId}/reapply`,
    headers: authHeader(token),
    data
  };
});

addAuth(ApiNames.RelevanceForRenewal, (token, papId, applicationId, isIrrelevant) => {
  const data = new FormData();
  data.append("isIrrelevant", isIrrelevant);
  data.append("applicationId", applicationId);

  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}financialNavigationPotentialSaving/${papId}/setIrrelevantForRenewal`,
    headers: authHeader(token),
    data
  };
});

addAuth(ApiNames.shippingOptions, (token, patientId, papId) => {
  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}autofillForms/${patientId}/get-shipping-options/${papId}`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.searchSavedList, (token, data) => {
  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}filterlists/search-filter-list`,
    headers: authHeader(token),
    data
  };
});

addAuth(ApiNames.CreateFilterList, (token, formData) => {
  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}filterlists/new-filter-list`,
    headers: authHeader(token),
    data: formData.data
  };
});

addAuth(ApiNames.CreateClaim, (token, formData) => {
  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}${CLAIMS}`,
    headers: authHeader(token),
    data: formData
  };
});

addAuth(ApiNames.CreateAttachmentClaim, (token, attachmentClaimdata) => {
  const { patient, file, claim } = attachmentClaimdata;
  const data = new FormData();

  data.append("patient", patient);
  data.append("claim", claim);
  data.append("file", file);

  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}${CLAIM_ATTACHMENTS}/upload`,
    headers: authHeader(token),
    data
  };
});

addAuth(ApiNames.DuplicateClaimAttachments, (token, duplicationPayload) => {
  const { patientId, targetClaimId, sourceClaimId, skippedAttachments = [] } = duplicationPayload;

  const data = {
    patientId,
    sourceClaimId,
    targetClaimId,
    skippedAttachments
  };

  return {
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}${CLAIM_ATTACHMENTS}/duplicate`,
    headers: authHeader(token),
    data
  };
});

addAuth(ApiNames.FetchPapPDFMetaData, (token, papId, applicationId) => {
  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}autofillForms/metadata?papId=${papId}&applicationId=${applicationId}`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.DeleteAttachmentClaim, (token, attachmentClaimId) => {
  return {
    method: "DELETE",
    url: `${SERVER_URL}${API_VERSION_1}${CLAIM_ATTACHMENTS}/` + attachmentClaimId,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.UpdateClaim, (token, data, claimId) => {
  return {
    method: "PUT",
    url: `${SERVER_URL}${API_VERSION_1}${CLAIMS}/${claimId}`,
    headers: authHeader(token),
    data: data
  };
});

addAuth(ApiNames.ToggleClaimActiveStatus, (token, patientId, claimId, isActive) => {
  const data = {
    patientId,
    claim: {
      isActive
    }
  };

  return {
    method: "PUT",
    url: `${SERVER_URL}${API_VERSION_1}${CLAIMS}/${claimId}`,
    headers: authHeader(token),
    data: data
  };
});

addAuth(ApiNames.UpdateFilterList, (token, data, filterListId) => {
  return {
    method: "PUT",
    url: `${SERVER_URL}${API_VERSION_1}filterlists/update/${filterListId}`,
    headers: authHeader(token),
    data: data
  };
});

addAuth(ApiNames.FindSavedListById, (token, filterListId) => {
  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}filterlists/${filterListId}`,
    headers: authHeader(token)
  };
});

addAuth(ApiNames.ApplicationAttachments, (token, applicationId) => ({
  method: "POST",
  headers: authHeader(token),
  url: `${SERVER_URL}${API_VERSION_1}applicationattachments/list`,
  data: {
    application: applicationId
  }
}));

addAuth(ApiNames.getAllClaims, (token, applicationId) => {
  return {
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}applications/${applicationId}/${CLAIMS}`,
    headers: authHeader(token)
  };
});

const getApplicationUsedAmountsAPI = (applicationId) =>
  axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}applicationusedamount/${applicationId}`
  });

const updateApplicationUsedAmountsAPI = (rowsToUpdate, applicationId) => {
  const data = {
    rowsToUpdate,
    applicationId
  };

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}applicationusedamount`,
    data
  });
};

const getLoginImagesAPI = () =>
  axios({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}app/login-images/frontend`
  });
const initializePendoAnonymous = () =>
  axios({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}pendo/initializePendo`,
    data: {
      user: null,
      application: process.env.REACT_APP_ANALYTICS_APPLICATION,
      isAnonymous: true
    }
  });

const searchDiagnosisIcdsAPI = (text) =>
  axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}custom-dropdown/?searchType=diagnosisCode&query=${text}`
  });

//[TODO-DRUGS-AMIT] - should be deprecated
const searchDrugNamesAPI = (text, searchType = DRUG_SEARCH_OPTIONS.SEARCH_ALL_DRUGS) =>
  axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}custom-dropdown/?searchType=${searchType}&query=${text}`
  });

const searchRxnormDrugsAPI = ({ text = "", bnCui = "", pinCui = "" }) => {
  const queryObject = _.omitBy(
    {
      query: text,
      bnCui,
      pinCui
    },
    (param) => !param
  );

  const params = new URLSearchParams(queryObject);

  return axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}rxnorm-drug/search?${params}`
  });
};

const searchRegimens = (text) => {
  const data = new FormData();
  data.append("query", text);
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}genericregimen/search`,
    data
  });
};

const searchDiagnosisNamesAPI = (text) =>
  axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}custom-dropdown/?searchType=diagnosisName&query=${text}`
  });

const getValidForEbvPayersAPI = () => {
  return axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}payers/valid-for-ebv-payers`
  });
};

const getClaimNotesAPI = (patientId, claimId) => {
  return axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}${CLAIM_NOTE}?patientId=${patientId}&claimId=${claimId}`
  });
};

const checkOtherPayers = (otherPayersNames) => {
  const formData = new FormData();
  otherPayersNames.map((otherPayerName) => formData.append("query[]", otherPayerName));
  formData.append("includeSelfPayOption", false);

  const data = [...formData.entries()];

  const urlEncodedData = data
    .map((entry) => `${encodeURIComponent(entry[0])}=${encodeURIComponent(entry[1])}`)
    .join("&");

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}payers/search-auto-complete`,
    data: urlEncodedData
  });
};

const searchIcdToDiagnoses = (text) => {
  const data = new FormData();
  data.append("query", text);
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}icdToDiagnoses/search`,
    data
  });
};

const starPatient = (patientId, starred) => {
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patient/starPatient`,
    data: `patientId=${patientId}&starred=${starred}`
  });
};

const updatePatientStatusAPI = (patientId, status) => {
  const data = new FormData();
  data.append("patient_id", patientId);
  data.append("status", status);
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patients/status/`,
    data
  });
};

const updateFollowupDateAPI = (patientId, followupDate) => {
  const data = new FormData();
  data.append("followupDate", followupDate);
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patients/${patientId}/updateFollowUpDate`,
    data
  });
};

const updatePreferredLanguageAPI = (patientId, preferredLanguage) => {
  const data = { preferredLanguage: preferredLanguage ? preferredLanguage : null };
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patients/${patientId}/updatePreferredLanguage`,
    data
  });
};

const updatePatientAssigneesAPI = (patientId, assignees) => {
  const data = {
    patient_id: patientId,
    assignee: assignees
  };
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patients/assignee`,
    data
  });
};

const updatePatientPhysician = (patientId, physicianId) => {
  const data = new FormData();

  data.append("patient_id", patientId);
  if (physicianId) {
    data.append("doctor", physicianId);
    return axiosWithAuthentication({
      method: "POST",
      url: `${SERVER_URL}${API_VERSION_1}patients/doctor`,
      data
    });
  } else {
    return axiosWithAuthentication({
      method: "POST",
      url: `${SERVER_URL}${API_VERSION_1}patients/clearPhysician`,
      data
    });
  }
};

const updatePatientFacility = (patientId, facilityId) => {
  const data = new FormData();
  data.append("patient_id", patientId);
  data.append("facility", facilityId);
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patients/facility/`,
    data
  });
};

const requestManualAnalysis = (patientId) => {
  const data = new FormData();
  data.append("patient_id", patientId);
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patients/request-analysis`,
    data
  });
};

const getPatientData = (patientId) => {
  return axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}patients/${patientId}/patient-data`
  });
};

const getPatientNotesAPI = (patientId) => {
  const data = new FormData();
  data.append("patient_id", patientId);
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}notes`,
    data
  });
};

const addPatientNoteAPI = (patientId, noteText) => {
  const data = new FormData();
  data.append("patient_id", patientId);
  data.append("note", noteText);
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}notes/add`,
    data
  });
};

const deletePatientNoteAPI = (patientId, noteId) => {
  const data = new FormData();
  data.append("patient_id", patientId);
  data.append("note_id", noteId);
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}notes/delete`,
    data
  });
};

const createPatientsTaskAPI = (taskData) => {
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}todos`,
    data: { todo: taskData }
  });
};

const getPatientsTasksAPI = (filterData, addPatientData, patientId = null) => {
  const data = { addPatientData, ...(patientId && { patientId }) };
  data.filters = filterData;
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}todos/list`,
    data
  });
};

const editPatientsTaskAPI = (todo) => {
  return axiosWithAuthentication({
    method: "PUT",
    url: `${SERVER_URL}${API_VERSION_1}todos/${todo.id}`,
    data: { todo }
  });
};

const deletePatientsTaskAPI = (taskData) => {
  const data = {};
  data.todo = { ...taskData, status: "deleted" };

  return axiosWithAuthentication({
    method: "PUT",
    url: `${SERVER_URL}${API_VERSION_1}todos/${taskData.id}`,
    data
  });
};

const listApplicationAttachmentApi = (applicationId) =>
  axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}applicationattachments/list`,
    data: { application: applicationId }
  });

const countApplicationAttachmentApi = (applicationId) => {
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}applicationattachments/count`,
    data: { application: applicationId }
  });
};

const uploadApplicationAttachmentApi = (applicationId, patientId, file) => {
  const data = new FormData();
  data.append("application", applicationId);
  data.append("patient", patientId);
  // File must be appended to the form data last. Otherwise Skipper adds arguments
  // appended after to fileSize, which breaks our uploading logic
  data.append("file", file);

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}applicationattachments/upload`,
    data
  });
};

const deleteApplicationAttachmentApi = (attachmentId, attachmentUniqueId) => {
  return axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}applicationattachments/delete/${attachmentId}/${attachmentUniqueId}`
  });
};

const viewApplicationAttachmentApi = (attachmentId, attachmentUniqueId) => {
  return axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}applicationattachments/view/${attachmentId}/${attachmentUniqueId}`
  });
};

const downloadApplicationAttachmentApi = async (attachmentId, attachmentUniqueId) => {
  const token = getAccessToken();
  const resp = await fetch(
    `${SERVER_URL}${API_VERSION_1}applicationattachments/download/${attachmentId}/${attachmentUniqueId}`,
    {
      headers: authHeader(token),
      method: "GET"
    }
  );
  return resp.blob();
};

const downloadApplicationFormAttachmentApi = async (applicationFormAttachmentId) => {
  const token = getAccessToken();
  const resp = await fetch(`${SERVER_URL}${API_VERSION_1}applicationformattachments/${applicationFormAttachmentId}`, {
    headers: authHeader(token),
    method: "GET"
  });

  if (!resp.ok) {
    throw new Error("Network response was not ok");
  }

  return await resp.blob();
};

const uploadPatientAttachmentAPI = (file, patientId) => {
  const data = new FormData();
  data.append("patient", patientId);
  data.append("file", file);

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patientattachments/upload`,
    data
  });
};

const cancelDocusignSignatureRequestAPI = async (applicationFormId) => {
  return axiosWithAuthentication({
    method: "DELETE",
    url: `${SERVER_URL}${API_VERSION_1}applicationform/${applicationFormId}/docusignSignatureRequest`
  });
};

const uploadAttachmentAPI = (file) => {
  const data = new FormData();
  data.append("file", file);

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}upload`,
    data
  });
};

const getClaimAttachmentAPI = (claimId) => {
  const data = new FormData();
  data.append("claim", claimId);

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}${CLAIM_ATTACHMENTS}/list`,
    data
  });
};

const getPatientAttachmentsAPI = (patientId, filterData) => {
  const data = {
    patient: patientId,
    options: { ...filterData, excludeCylcesSpecific: true }
  };

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patientattachments/list`,
    data
  });
};

const getPatientAttachmentsCountAPI = (patientId) => {
  const data = {
    patient: patientId,
    options: { excludeCylcesSpecific: true }
  };
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patientattachments/count`,
    data
  });
};

const getPharmacyPatientsOrdersAPI = (patientId, pap) => {
  const data = {
    patient: patientId,
    pap
  };
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}${PHARMACY_PATIENTS}/${ORDERS}`,
    data
  });
};

const deletePatientAttachmentAPI = (attachmentId, attachmentUniqueId) => {
  return axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}patientattachments/delete/${attachmentId}/${attachmentUniqueId}`
  });
};

const downloadPatientAttachmentAPI = async (attachmentId, attachmentUniqueId) => {
  const token = getAccessToken();

  return await new Promise(function (resolve, reject) {
    fetch(`${SERVER_URL}${API_VERSION_1}patientattachments/download/${attachmentId}/${attachmentUniqueId}`, {
      headers: authHeader(token),
      method: "GET"
    })
      .then((resp) => {
        return resp.blob();
      })
      .then(resolve)
      .catch(reject);
  });
};

const downloadClaimAttachmentAPI = async (attachmentId) => {
  const token = getAccessToken();

  return await new Promise(function (resolve, reject) {
    fetch(`${SERVER_URL}${API_VERSION_1}${CLAIM_ATTACHMENTS}/${attachmentId}/download`, {
      headers: authHeader(token),
      method: "GET"
    })
      .then((resp) => {
        return resp.blob();
      })
      .then(resolve)
      .catch(reject);
  });
};

const addNewPatient = (patientData) => {
  const formData = new FormData();

  Object.entries({ ...patientData, source: SOURCE }).map(([title, value]) => {
    formData.append(title, value);
  });

  const data = [...formData.entries()];

  const urlEncodedData = data
    .map((entry) => `${encodeURIComponent(entry[0])}=${encodeURIComponent(entry[1])}`)
    .join("&");

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patients/newpatient`,
    data: urlEncodedData
  });
};

const validateMrnUniqueness = async (mrn) =>
  axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patients/validateMrnUniqueness`,
    data: { mrn }
  });

const getNotificationsAPI = () =>
  axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}programNotifications/list`
  });

const markNotificationAPI = (notificationID, val) => {
  const data = new FormData();
  data.append("notificationID", notificationID);
  data.append("newState", val);
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}programNotifications/markNotification`,
    data
  });
};

const getImportedStatusesAPI = () =>
  axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}patient-data/get-imported-statuses`
  });

const getTopOpportunityPatientsAPI = (filters, sorter = {}, page = 1, limit = 25) => {
  const request = buildSearchRequest({
    selectedView: "top-opportunity",
    page,
    limit,
    filters,
    sorter,
    isPhi: true
  });
  return axiosWithAuthentication(request);
};

const getNumberOfPatientsAPI = (filters, skip = 0, limit = 25, selectedView) => {
  const data = { filters, page: 1, skip, limit, selectedView };
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}${PATIENTS}dashboard/phi-search`,
    data
  });
};

const getManagePatientDetails = (patientId) => {
  return axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}patient/${patientId}/edit/details`
  });
};

const setPatientDetailsAPI = (patientId, data, skipAutomations) => {
  //TODO: remove this, ember patch
  if (data.financial) {
    if (data.financial.filesTaxes === true) {
      data.financial.filesTaxes = "true";
    } else if (data.financial.filesTaxes === false) {
      data.financial.filesTaxes = "false";
    }
  }

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patient/edit/details`,
    data: {
      patientId,
      data,
      skipAutomations
    }
  });
};

const updatePatientMedicationsAPI = ({
  patientId,
  manualDrugEvents,
  importedDrugEvents,
  patientHiddenDrugs,
  physicianDrugs,
  uncoveredDrugs,
  skipAutomations
}) => {
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patient/${patientId}/update-patient-medications-info`,
    data: {
      manualDrugEvents,
      importedDrugEvents,
      patientHiddenDrugs,
      physicianDrugs,
      uncoveredDrugs,
      skipAutomations
    }
  });
};

const updateInsuranceAPI = (patientId, previousInsuranceRows, newInsuranceRows) =>
  axiosWithAuthentication({
    method: "PUT",
    url: `${SERVER_URL}${API_VERSION_1}patients/${patientId}/insuranceRows`,
    data: {
      previousInsuranceRows,
      newInsuranceRows
    }
  });

const updateUninsuredStatusAPI = (patientId, isUninsured) =>
  axiosWithAuthentication({
    method: "PUT",
    url: `${SERVER_URL}${API_VERSION_1}patients/${patientId}/insuranceRows/changeInsuredStatus`,
    data: { isUninsured }
  });

const createPatientInsurancesReviewAPI = (patientId, reviewedDate) =>
  axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patients/${patientId}/createNewInsuranceReview`,
    data: { reviewedDate }
  });

const resolveInsuranceConflicts = (patientId, pickedInsuranceRows, ignoredInsuranceRows) =>
  axiosWithAuthentication({
    method: "PUT",
    url: `${SERVER_URL}${API_VERSION_1}patients/${patientId}/insuranceRows/resolveConflicts`,
    data: { pickedInsuranceRows, ignoredInsuranceRows }
  });

const sendSupportTicketAPI = (subject, comment, patient = null) => {
  const data = {
    ticket: {
      subject,
      comment,
      application: "Financial Navigation",
      ...(patient ? { patient } : {})
    }
  };
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}support/create-ticket`,
    data
  });
};

const getFinancialNavigationPotentialSavingPhis = (queryIds) => {
  const data = queryIds.map((id) => ["ids[]", id]);

  const urlEncodedData = data
    .map((entry) => `${encodeURIComponent(entry[0])}=${encodeURIComponent(entry[1])}`)
    .join("&");

  return axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}financialNavigationPotentialSavingPhis?${urlEncodedData}`
  });
};

const getNotificationDataAPI = (notificationID) =>
  axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}programNotifications/getNotificationData/${notificationID}`
  });

const getDrugsNameAPI = (papId, drugName) =>
  axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}drugs?papId=${papId}&name=${drugName}`
  });

const getPapDrugCostCalculation = (papId) =>
  axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}drugs/pap-drug-cost-calculation?papId=${papId}`
  });

const putDrugCostCalculationAPI = (papId, data) =>
  axiosWithAuthentication({
    method: "PUT",
    url: `${SERVER_URL}${API_VERSION_1}paps/${papId}/drugCostCalculation`,
    data
  });

const getPatientDiagnoses = (patientId, excludeComputed = false) =>
  axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}patients/${patientId}/patientDiagnoses?excludeComputed=${excludeComputed}`
  });

const patchPatientDiagnoses = (patientId, icd, data) =>
  axiosWithAuthentication({
    method: "PATCH",
    url: `${SERVER_URL}${API_VERSION_1}patients/${patientId}/diagnoses/${icd}`,
    data
  });

const deletePatientDiagnoses = (patientId, icd) =>
  axiosWithAuthentication({
    method: "DELETE",
    url: `${SERVER_URL}${API_VERSION_1}patients/${patientId}/diagnoses/${icd}`
  });

const createDiagnosisEvent = (patientId, icd, data) => {
  return axiosWithAuthentication({
    method: "PUT",
    url: `${SERVER_URL}${API_VERSION_1}patients/${patientId}/diagnoses/${icd}`,
    data
  });
};

const ignoreDiagnosisPending = (patientId, icd) =>
  axiosWithAuthentication({
    method: "DELETE",
    url: `${SERVER_URL}${API_VERSION_1}patients/${patientId}/diagnoses/${icd}/pending`
  });

const replaceDiagnosisWithPending = (patientId, icd) =>
  axiosWithAuthentication({
    method: "PUT",
    url: `${SERVER_URL}${API_VERSION_1}patients/${patientId}/diagnoses/${icd}/pending/accepted`
  });

const rejectHighPriorityAPI = (reason, patientId) => {
  const data = { reason, patients: [patientId] };
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}opportunities/patients/exclude`,
    data
  });
};

const rejectSingleHighPriorityPapAPI = (reason, pap) => {
  const data = { rejectReason: reason, pap };
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}financialNavigationPotentialSaving/singleOpportunityExclude`,
    data
  });
};

const getPolicieServiceAPI = (policyId) => {
  return axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}policies/${policyId}/services`
  });
};

const getMedicarePolicyDrugCoverageAPI = (policyId) => {
  return axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}policies/${policyId}/medicareDrugCoverages`
  });
};

const getPatientMedicationsForManagePatientDetailsAPI = (patientId) => {
  return axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}patient/${patientId}/getPatientMedicationsForManagePatientDetails`
  });
};

const fillPdfApi = async ({
  papId,
  formApplicationShipping,
  applicationId,
  savePdf = false,
  applicationFormId = null,
  fileAttachmentName = ""
}) => {
  const data = {
    papId,
    applicationId,
    options: formApplicationShipping,
    applicationFormId
  };

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}autofillForms/fill?savePdf=${savePdf}${
      fileAttachmentName ? `&saveAsFileName=${encodeURIComponent(fileAttachmentName)}` : ""
    }`,
    data,
    responseType: "blob"
  });
};

const starFilterList = (filterId, starred) => {
  const data = { filterId, starred };
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}filterlists/star-filter-list`,
    data: data
  });
};

const deleteSavedListAPI = (listId) => {
  return axiosWithAuthentication({
    method: "DELETE",
    url: `${SERVER_URL}${API_VERSION_1}filterlists/delete/` + listId
  });
};

const automatePatientAfterUpdate = async ({
  patientId,
  treatmentMedicationUpdate = false,
  diagnosisUpdate = false,
  insuranceRowsUpdate = false,
  automateRule = MANUAL_AUTOMATION_SOURCES.MANUAL_REGULAR_AUTOMATION
}) => {
  const specificActions = [];

  if (diagnosisUpdate) {
    specificActions.unshift("diagnosisAutomation");
  }
  if (treatmentMedicationUpdate) {
    specificActions.unshift("updateIsInActiveTreatmentIfNeeded");
  }
  if (insuranceRowsUpdate) {
    specificActions.push(
      "benefitInvestigationAutomation",
      "patientInsuranceRowsAutomation",
      "cobCalculationCacheAutomation"
    );
  }

  specificActions.push("potentialSavingsAutomation", "calculationAutomation", "potentialSavingUpdateAutomation");

  await axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patients/automate/${patientId}`,
    data: {
      specificActions,
      automateRule
    }
  });

  if (treatmentMedicationUpdate) {
    await runPatientPrioritizationAPI(patientId);
  }
};

const runPatientPrioritizationAPI = (patientId) => {
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patient/${patientId}/run-patient-prioritization`
  });
};

const submitExpressEnrollFormAPI = ({ applicationId, attachmentIds, createPharmacyPatient }) => {
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}applicationform/submit`,
    data: {
      applicationId,
      attachmentIds,
      createPharmacyPatient
    }
  }).catch((error) => {
    const responseError = error.response?.data?.errors?.[0].message;
    error.message = responseError ?? error.message;

    throw error;
  });
};

const pfizerApprovalApplicationMockAPI = (applicationId, papId) => {
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}assistanceprogramapplications/aprovalmock/pfizer/${applicationId}`,
    data: { papId }
  });
};

const EEApproveApplicationMockAPI = (applicationFormId) => {
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}ExpressEnrollmentMock/${applicationFormId}`
  });
};

const addAssistanceProgramAPI = ({ patientId, selectedAssistanceProgram, selectedDrug }) => {
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}patients/createnewpap`,
    data: { patientId, selectedAssistanceProgram, selectedDrug }
  });
};

const searchAssistanceProgramAPI = (searchQuery, filters) => {
  // filtersData code copied from sagaActions.searchAssistanceProgram
  let filtersData = {};
  Object.entries(filters).map(([filterName, values]) => {
    Object.entries(values).map(([value, text], index) => {
      if (typeof text !== "object") {
        filtersData = { ...filtersData, [`filters[${filterName}][${index}][value]`]: value };
        filtersData = { ...filtersData, [`filters[${filterName}][${index}][text]`]: text };
      } else {
        const newValues = text;
        Object.entries(newValues).map(([key, newValue]) => {
          filtersData = { ...filtersData, [`filters[${filterName}][${index}][${key}]`]: newValue };
        });
      }
    });
  });

  const formData = new FormData();

  formData.append("searchQuery", searchQuery);
  formData.append("sortAlphabetically", true);

  Object.entries(filtersData).map(([filter, value]) => formData.append(filter, value));

  const data = [...formData.entries()];

  const urlEncodedData = data
    .map((entry) => `${encodeURIComponent(entry[0])}=${encodeURIComponent(entry[1])}`)
    .join("&");

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}assistanceProgram/apFrontendSearch`,
    data: urlEncodedData
  });
};

const getApplicationFormByApplicationIdApi = (applicationId) => {
  return axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}application/${encodeURIComponent(applicationId)}/applicationForm`
  });
};

const getAssistanceProgramApplicationItemsByAppId = (applicationId) => {
  return axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}application/${applicationId}/assistanceProgramApplicationItem`
  });
};

const createApplicationFormApi = (applicationForm, isAutoFill = false) => {
  const data = {
    applicationForm: { ...applicationForm }
  };

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}applicationForm/?isAutoFill=${isAutoFill}`,
    data
  });
};

const updateApplicationFormApi = (applicationForm) => {
  const data = {
    applicationForm: { ...applicationForm }
  };
  return axiosWithAuthentication({
    method: "PUT",
    url: `${SERVER_URL}${API_VERSION_1}applicationForm/${applicationForm.id}`,
    data
  });
};

const generateApplicationFormPDFApi = (applicationFormId) => {
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}applicationForm/${applicationFormId}/generatePdf`
  });
};

const generateDraftApplicationFormPDFApi = (applicationFormId) => {
  const data = {
    isDraft: true
  };

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}applicationForm/${applicationFormId}/generatePdf`,
    data
  });
};

const unSignPdfApi = async (applicationFormId, signerType) => {
  const data = {
    signerType
  };

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}applicationForm/${applicationFormId}/unSignPdf`,
    data,
    responseType: "blob"
  });
};

const deleteApplicationFormPDFApi = (applicationFormId, applicationFormAttachmentId) => {
  return axiosWithAuthentication({
    method: "DELETE",
    url: `${SERVER_URL}${API_VERSION_1}applicationForm/${applicationFormId}/applicationFormAttachment/${applicationFormAttachmentId}`
  });
};

const docusignSignatureRequestAPI = (applicationFormId, data) => {
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}applicationForm/${applicationFormId}/docusignSignatureRequest`,
    data
  });
};

const expressEnrollmentConfigurationByPapApi = (papId) => {
  return axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}applicationForm/expressEnrollmentConfigurationByPap?papId=${papId}`
  });
};

const getRequiredSignersForAP = (apId) => {
  return axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}${REQUIRED_SIGNER}?apId=${apId}`
  });
};

const getApplicationFormSignatures = (applicationFormId) => {
  return axiosWithAuthentication({
    method: "GET",
    url: `${SERVER_URL}${API_VERSION_1}applicationForm/${applicationFormId}/signatures`
  });
};

const createRemoteSignatureRequest = (data) => {
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}${REMOTE_SIGNATURE_REQUESTS}`,
    data
  });
};

const createFillNowSignature = (data) => {
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}${FILL_NOW}`,
    data
  });
};

const revokeRemoteSignatureRequest = (signatureId) => {
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}${REMOTE_SIGNATURE_REQUESTS}/revoke/${signatureId}`
  });
};

// NOTE:: This API to send reminder might need to be changed
const sendSignatureReminder = ({
  applicationForm,
  signerType,
  email,
  phone,
  assignee,
  patientId,
  linkId,
  signatureRequestId
}) => {
  const data = {
    applicationForm,
    signerType,
    email,
    phone,
    assignee,
    patientId,
    linkId
  };

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}${REMOTE_SIGNATURE_REQUESTS}/reminder/${signatureRequestId}`,
    data
  });
};

const uploadApplicationFormAttachmentApi = (file, patientId, applicationId, fileAttachmentName) => {
  const data = new FormData();
  data.append("patientId", patientId);
  data.append("applicationId", applicationId);
  data.append("file", file, fileAttachmentName);

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}applicationformattachments/uploadManually`,
    data
  });
};

const uploadAPFAttachmentForEsignApi = (file, patientId, applicationId, fileAttachmentName) => {
  const data = new FormData();
  data.append("patientId", patientId);
  data.append("applicationId", applicationId);
  data.append("file", file, fileAttachmentName);

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}applicationformattachments/uploadForESign${
      fileAttachmentName ? `?saveAsFileName=${encodeURIComponent(fileAttachmentName)}` : ""
    }`,
    data
  });
};

const uploadWetSignaturePDFApi = (file, requiredSignerType, patientId, applicationId, fileAttachmentName) => {
  const data = new FormData();
  data.append("patientId", patientId);
  data.append("applicationId", applicationId);
  data.append("signerType", requiredSignerType);
  data.append("file", file, fileAttachmentName);

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}wetsign`,
    data
  });
};

const signPdfManuallyApi = ({ file, patientId, applicationFormId, signerType }) => {
  const data = new FormData();
  data.append("patientId", patientId);
  data.append("signerType", signerType);
  data.append("file", file);

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}applicationform/${applicationFormId}/signPdfManually`,
    data
  });
};

const cancelSignatureApi = async (applicationFormId, signerType) => {
  const data = {
    signerType: signerType
  };

  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}applicationform/${applicationFormId}/cancelSignature`,
    data
  });
};

const api = (methodName) => {
  const action = ApiMap[methodName];
  return (...params) =>
    new Promise((resolve, reject) => {
      const req = action(...params);
      axios(req)
        .then((res) => resolve(res.data))
        .catch((error) => reject(Object.assign(error, { methodName })));
    });
};

const postPortalDataThroughChromeExtension = (data) => {
  return axiosWithAuthentication({
    method: "POST",
    url: `${SERVER_URL}${API_VERSION_1}accelerated-enrollment-bot/reportPortalData`,
    data
  });
};

export {
  api,
  ApiNames,
  ApiMap,
  ApiCallerMap,
  CallerType,
  ApiBaseUrl,
  SERVER_URL,
  CLAIM_ATTACHMENTS,
  getLoginImagesAPI,
  sendSupportTicketAPI,
  createDrugAPI,
  deleteMedicationAPI,
  editMedicationAPI,
  searchDiagnosisIcdsAPI,
  searchDrugNamesAPI,
  getValidForEbvPayersAPI,
  getClaimNotesAPI,
  searchDiagnosisNamesAPI,
  searchIcdToDiagnoses,
  createDiagnosisEvent,
  starPatient,
  updatePatientStatusAPI,
  updateFollowupDateAPI,
  updatePreferredLanguageAPI,
  updatePatientAssigneesAPI,
  updatePatientPhysician,
  updatePatientFacility,
  searchRegimens,
  requestManualAnalysis,
  getPatientData,
  getManagePatientDetails,
  setPatientDetailsAPI,
  updatePatientMedicationsAPI,
  addNewPatient,
  validateMrnUniqueness,
  checkOtherPayers,
  getPatientNotesAPI,
  addPatientNoteAPI,
  deletePatientNoteAPI,
  createPatientsTaskAPI,
  getPatientsTasksAPI,
  editPatientsTaskAPI,
  deletePatientsTaskAPI,
  cancelDocusignSignatureRequestAPI,
  uploadAttachmentAPI,
  getClaimAttachmentAPI,
  uploadPatientAttachmentAPI,
  getPatientAttachmentsAPI,
  getNotificationsAPI,
  markNotificationAPI,
  getNotificationDataAPI,
  deletePatientAttachmentAPI,
  downloadPatientAttachmentAPI,
  downloadClaimAttachmentAPI,
  getPatientAttachmentsCountAPI,
  getPharmacyPatientsOrdersAPI,
  getFinancialNavigationPotentialSavingPhis,
  getTopOpportunityPatientsAPI,
  getNumberOfPatientsAPI,
  getImportedStatusesAPI,
  getDrugsNameAPI,
  getPapDrugCostCalculation,
  putDrugCostCalculationAPI,
  getPatientDiagnoses,
  patchPatientDiagnoses,
  deletePatientDiagnoses,
  ignoreDiagnosisPending,
  replaceDiagnosisWithPending,
  fillPdfApi,
  rejectHighPriorityAPI,
  rejectSingleHighPriorityPapAPI,
  getPolicieServiceAPI,
  getMedicarePolicyDrugCoverageAPI,
  getPatientMedicationsForManagePatientDetailsAPI,
  parseSearchFormParams,
  serverFiltersDecoder,
  starFilterList,
  updateInsuranceAPI,
  updateUninsuredStatusAPI,
  createPatientInsurancesReviewAPI,
  resolveInsuranceConflicts,
  deleteSavedListAPI,
  automatePatientAfterUpdate,
  runPatientPrioritizationAPI,
  getApplicationUsedAmountsAPI,
  updateApplicationUsedAmountsAPI,
  pfizerApprovalApplicationMockAPI,
  EEApproveApplicationMockAPI,
  addAssistanceProgramAPI,
  searchAssistanceProgramAPI,
  listApplicationAttachmentApi,
  uploadApplicationAttachmentApi,
  deleteApplicationAttachmentApi,
  downloadApplicationAttachmentApi,
  countApplicationAttachmentApi,
  viewApplicationAttachmentApi,
  searchRxnormDrugsAPI,
  addDrugAPI,
  downloadApplicationFormAttachmentApi,
  submitExpressEnrollFormAPI,
  getApplicationFormByApplicationIdApi,
  createApplicationFormApi,
  updateApplicationFormApi,
  generateApplicationFormPDFApi,
  generateDraftApplicationFormPDFApi,
  deleteApplicationFormPDFApi,
  docusignSignatureRequestAPI,
  uploadApplicationFormAttachmentApi,
  uploadAPFAttachmentForEsignApi,
  signPdfManuallyApi,
  cancelSignatureApi,
  expressEnrollmentConfigurationByPapApi,
  getAssistanceProgramApplicationItemsByAppId,
  postPortalDataThroughChromeExtension,
  unSignPdfApi,
  getRequiredSignersForAP,
  getApplicationFormSignatures,
  createRemoteSignatureRequest,
  revokeRemoteSignatureRequest,
  sendSignatureReminder,
  createFillNowSignature,
  initializePendoAnonymous,
  uploadWetSignaturePDFApi
};
