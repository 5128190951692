import React, { useState } from "react";
import { SingleSelect } from "./CustomSelect";
import _ from "lodash";
import { UNSPECIFIED_PAYER_NAME } from "../../constant/coverages";
const { Option } = SingleSelect;

const CustomSelectAutocomplete = ({
  children,
  width,
  setIsCustomPayer,
  value,
  onChange,
  placeholder,
  showArrow = false,
  ...props
}) => {
  const [typedValue, setTypedValue] = useState();
  const handleOnSearch = (searchValue) => {
    if (!searchValue || _.isEmpty(searchValue)) return;

    setTypedValue(searchValue);
  };
  return (
    <SingleSelect
      allowClear
      size="large"
      placeholder={placeholder}
      showArrow={showArrow}
      showSearch={true}
      width={width}
      optionLabelProp="label"
      defaultValue={value}
      value={value}
      onSearch={handleOnSearch}
      onChange={(values, key) => {
        if (key === undefined) {
          setIsCustomPayer(true);
          onChange?.(UNSPECIFIED_PAYER_NAME);
        } else {
          setIsCustomPayer(_.isNil(key?.key));
          onChange?.(key.value);
        }
      }}
      {...props}
    >
      {children}
      {typedValue && <Option value={typedValue}>{`+ Add '${typedValue}'`}</Option>}
    </SingleSelect>
  );
};

export default CustomSelectAutocomplete;
