import React from "react";

import { EnrollResult } from "./EnrollResult";
import { CloseButton } from "../utils/components";
import { StyledModal } from "../utils/styledComponents";
import { EnrollFailed } from "../../../ApplicationFormEditor/components/result/EnrollFailed";
import { sizes } from "../../../../../constant/styles";

export const SubmittedFormResultModal = ({
  visible,
  onCancel,
  width = "80vh",
  error = null,
  resultId,
  hideDescription = false,
  titleStyle
}) => {
  return (
    <StyledModal
      centered
      open={visible}
      width={width}
      footer={<CloseButton key="back" onClick={onCancel} id={"EE_submitted_form_modal_close"} />}
      gap={30}
      bodyStyle={{ marginTop: sizes.xlarge }}
      closable={false}
    >
      {!error && <EnrollResult id={resultId} hideDescription={hideDescription} titleStyle={titleStyle} />}
      {!!error && <EnrollFailed error={error} padding={"0 10rem"} id={resultId} />}
    </StyledModal>
  );
};
