import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Button, Typography, Input, Space, Form, Modal } from "antd";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

import { selectShowSupport, selectUserId } from "../store/selector";
import ACTIONS from "../store/action";
import { sendSupportTicketAPI } from "../api/api";
import ErrorMessage from "./customComponent/customMessages/ErrorMessage";
import SuccessMessage from "./customComponent/customMessages/SuccessMessage";
import { ReactComponent as WarningIcon } from "../assets/svg/warning-icon-popup.svg";
import { fontWeights, sizes } from "../constant/styles";
import { GRAY_300, GRAY_400, GRAY_500, GRAY_700, GRAY_900, PRIMARY_600, WHITE } from "../constant/colors";
import { ReactComponent as ContactSupportIcon } from "../assets/svg/contact-support-modal-icon.svg";

const { Text } = Typography;
const ReachableContext = React.createContext();

const StyledTitle = styled(Text)`
  font-size: ${sizes.large};
  font-weight: ${fontWeights.semibold};
  color: ${GRAY_900};
`;

const StyledSubTitle = styled(Text)`
  font-size: ${sizes.medium};
  font-weight: ${fontWeights.regular};
  color: ${GRAY_500};
`;

const StyledDisclaimer = styled(Text)`
  font-size: ${sizes.small};
  font-weight: ${fontWeights.regular};
  color: ${GRAY_900};
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-btn-default {
    padding: 10px 16px;
    gap: 8px;
    height: 44px;
    background: ${WHITE};
    border: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    font-weight: ${fontWeights.semibold};
    font-size: ${sizes.medium_large};
    color: ${GRAY_700};
  }
  .ant-btn-primary {
    padding: 10px 16px;
    gap: 8px;
    height: 44px;
    background: ${PRIMARY_600};
    border: 1px solid ${PRIMARY_600};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    font-weight: ${fontWeights.semibold};
    font-size: ${sizes.medium_large};
    color: ${WHITE};
    min-width: 100px;
  }
  .ant-modal-close-x {
    color: ${GRAY_900} !important;
  }
`;

const StyledInput = styled(Input)`
  border-bottom: 1px solid ${GRAY_300};
  .ant-input::placeholder {
    color: ${GRAY_400};
    font-weight: ${fontWeights.regular};
    font-size: ${sizes.small};
  }
  :hover {
    border-bottom: 1px solid ${GRAY_300} !important;
  }
  .ant-input-suffix {
    position: relative;
    top: 35px;
    right: -10px;
    font-weight: ${fontWeights.regular};
    font-size: ${sizes.small};
    color: ${GRAY_900};
  }

  &.ant-input-affix-wrapper > .ant-input:not(textarea) {
    margin-left: -10px;
  }
`;

const ContactSupportComponent = ({ t, showSupport, setShowSupport, closeModal }) => {
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [isFetching, setIsFetching] = useState(false);
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");

  const onSend = async (values) => {
    const descriptionWithAP =
      showSupport.assistanceProgram && showSupport.assistanceProgram.name
        ? `Program name: ${showSupport.assistanceProgram.name} - ${values.description}`
        : values.description;

    let patientId = null;
    const hrefSplited = location.href.split("/");
    if (hrefSplited.length && hrefSplited[3] == "patient") {
      patientId = hrefSplited[4];
    }

    setIsFetching(true);
    try {
      const response = await sendSupportTicketAPI(values.subject, descriptionWithAP, patientId);
      if (response && response.data && response.data.savedTicketIdSF) {
        SuccessMessage(t("contact_support.ticket_send_successfully"));
        form.resetFields();
        setShowSupport(false);
      } else {
        ErrorMessage(t("contact_support.failed_to_send_ticket"));
      }
    } catch (error) {
      ErrorMessage(t("contact_support.failed_to_send_ticket"));
    } finally {
      setIsFetching(false);
    }
  };

  const onCancel = () => {
    const formFieldsValue = form.getFieldsValue(true);
    const hasChanges = Object.values(formFieldsValue).filter((value) => value !== "").length;
    if (hasChanges) {
      modal.confirm({
        centered: true,
        okText: t("yes_sure"),
        cancelText: t("back"),
        icon: <WarningIcon />,
        title: t("form_popups.confirmed_close_title"),
        content: (
          <>
            {t("form_popups.confirmed_close_content")}
            {t("are_you_sure_you_want_to_proceed")}
          </>
        ),
        onOk: () => {
          form.resetFields();
          setShowSupport(false);
          closeModal();
        }
      });
    } else {
      setShowSupport(false);
      closeModal();
    }
  };

  return (
    <ReachableContext.Provider value={null}>
      <StyledModal open={showSupport.visible} footer={false} onCancel={onCancel} icon={<WarningIcon />} centered>
        <Form form={form} name="contactSupport" onFinish={(values) => onSend(values)}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Space>
              <ContactSupportIcon />
            </Space>

            <Space>
              <StyledTitle>
                {showSupport.assistanceProgram
                  ? t("support.title", { name: showSupport.assistanceProgram.name })
                  : t("contact_support.title")}
              </StyledTitle>
            </Space>

            <Space>
              <StyledSubTitle> {t("support.please_provide_relevant_details_below")}</StyledSubTitle>
            </Space>
          </Space>

          <Form.Item
            name="subject"
            rules={[
              { required: true, message: t("support.subject_required") },
              {
                max: 49,
                message: t("errorMessages.should_be_less_than", { fieldName: "Subject", maxCharacter: 50 })
              }
            ]}
            style={{ marginBottom: 0, marginTop: "30px" }}
          >
            <StyledInput
              size="large"
              maxLength={50}
              placeholder={t("support.subject")}
              bordered={false}
              onChange={(value) => setSubject(value.target.value)}
              suffix={`${subject.length}/50`}
              id="contact_support_subject"
            />
          </Form.Item>

          <StyledDisclaimer>{t("support.disclaimer")}</StyledDisclaimer>

          <Form.Item
            name="description"
            rules={[
              { required: true, message: t("support.description_required") },
              {
                max: 249,
                message: t("errorMessages.should_be_less_than", { fieldName: "Description", maxCharacter: 250 })
              }
            ]}
            style={{ paddingTop: 30 }}
          >
            <StyledInput
              size="large"
              maxLength={250}
              placeholder={t("support.description")}
              bordered={false}
              onChange={(value) => setDescription(value.target.value)}
              suffix={`${description.length}/250`}
              id="contact_support_description"
            />
          </Form.Item>

          <Space style={{ display: "flex", justifyContent: "flex-end", paddingTop: 16 }}>
            <Button onClick={onCancel} id="contact_support_cancel_btn">
              {t("cancel")}
            </Button>

            <Button htmlType="submit" loading={isFetching} type="primary" id="contact_support_send_btn">
              {t("send")}
            </Button>
          </Space>
        </Form>
      </StyledModal>
      {contextHolder}
    </ReachableContext.Provider>
  );
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(ACTIONS.setShowAssistanceProgramModal(false)),
  setShowSupport: (visible) => dispatch(ACTIONS.setShowSupport(visible))
});

const mapStateToProps = createStructuredSelector({
  userId: selectUserId,
  showSupport: selectShowSupport
});

const ContactSupport = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactSupportComponent));

export { ContactSupport };
