import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";
import { Typography } from "antd";

import TableFilters from "../TableFilters";
import { AllPatientsTable } from "./AllPatientsTable";
import { VisitScheduleTable } from "./VisitScheduleTable";
import { HighPrioritiesTable } from "./HighPrioritiesTable";
import { SavedListTable } from "./SavedListTable";
import { AllApplicationsTable } from "./AllApplicationsTable";
import { selectSelectedView } from "../../store/selector";
import { selectedViews, selectedViewTypes } from "../../constant/table";
import { PendingRenewalTable } from "./PendingRenewalTable";
import { FilterListForm } from "../../component/FormPopups/FilterListForm";
import { AllClaimsTable } from "./AllClaimsTable";
import { GRAY_900 } from "../../constant/colors";
import { fontWeights, sizes } from "../../constant/styles";

const { Text } = Typography;

const TitleStyled = styled.div`
  .ant-typography {
    color: ${GRAY_900};
    font-weight: ${fontWeights.semibold};
    font-size: ${sizes.medium_xlarge};
  }
`;

const ContainerStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 10px;
  transition: all 0.3s ease;
`;

const TableSectionComponent = ({ selectedView }) => {
  const [filtersVisible, setFiltersVisible] = useState(false);
  useEffect(() => {
    setFiltersVisible(false);
  }, [selectedView]);

  const showFilters = selectedView !== selectedViews.savedList;
  return (
    <>
      <TitleStyled>
        <Text>{selectedViewTypes[selectedView]?.description}</Text>
      </TitleStyled>

      <ContainerStyled>
        {showFilters && (
          <div
            style={{ flex: "0 0 288px", maxWidth: filtersVisible ? "288px" : "0px", transition: "max-width 0.3s ease" }}
          >
            <TableFilters filtersVisible={filtersVisible} setFiltersVisible={setFiltersVisible} />
          </div>
        )}
        <div style={{ flex: "1 1 auto", overflow: "hidden" }}>
          {selectedView === selectedViews.allPatients && <AllPatientsTable isSearch={false} />}
          {selectedView === selectedViews.visitSchedule && <VisitScheduleTable />}
          {selectedView === selectedViews.pendingRenewal && <PendingRenewalTable />}
          {selectedView === selectedViews.highPriority && <HighPrioritiesTable />}
          {selectedView === selectedViews.savedList && <SavedListTable />}
          {selectedView === selectedViews.allApplications && <AllApplicationsTable />}
          {selectedView === selectedViews.allClaims && <AllClaimsTable />}
        </div>
        <FilterListForm />
      </ContainerStyled>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedView: selectSelectedView
});

const TableSection = connect(mapStateToProps)(TableSectionComponent);

export { TableSection };
