import React from "react";
import moment from "moment-timezone";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography, Space, Divider, Button, Skeleton, Row, Col } from "antd";
import styled from "styled-components";
import { displayedMRN } from "@tailormed/common-client/util/mrn";

import { Routes } from "../../constant/routes";
import { GRAY_100, GRAY_300, TEXT1, TEXT2, TEXT4, WHITE_GRAY, PRIMARY_600, ERROR_50 } from "../../constant/colors";
import {
  selectIsFetching,
  selectPatientDetails,
  selectSelectedView,
  selectFilterList,
  selectShouldReturnToRelevantPatient
} from "../../store/selector";
import { fontWeights, sizes } from "../../constant/styles";
import Male from "../../assets/svg/Male";
import Female from "../../assets/svg/Female";
import { ReactComponent as IconUnknownGender } from "../../assets/svg/unknown-gender.svg";
import { PatientStar } from "./PatientStar";
import { PatientAssignees } from "./PatientTopSection/PatientAssignees";
import { PatientPersonalDetails } from "./PatientTopSection/PatientPersonalDetails";
import { PatientDiagnoses } from "./PatientTopSection/PatientDiagnoses";
import { PatientStatus } from "./PatientTopSection/PatientStatus";
import { PatientPhysician } from "./PatientTopSection/PatientPhysician";
import { PatientFacility } from "./PatientTopSection/PatientFacility";
import { ApiNames } from "../../api/api";
import ArrowBack from "../../assets/svg/ArrowBack";
import { useGetSetting } from "../../hooks/getSetting";
import { dateFormatter } from "../../utils/date";
import { PatientFollowUpDate } from "./PatientTopSection/PatientFollowUpDate";
import { ExcludeHighPriority } from "./PatientTopSection/ExcludeHighPriority";
import { ReactComponent as HasConflictIcon } from "../../assets/svg/has-conflicts-icon.svg";
import { customerTypes } from "../../constant/customerType";
import { formatPhoneNumber } from "../../utils/formaters";
import { PatientMoreInfo } from "./PatientTopSection/PatientMoreInfo";
import { DeceasedLable } from "../DeceasedLable";
import ACTIONS from "../../store/action";

const { Text } = Typography;

const secondaryTextStyle = { fontSize: sizes.small, color: TEXT4, fontWeight: fontWeights.semibold };
const sectionJCStyle = { borderLeft: `1px solid ${GRAY_300}`, padding: "0px 12px" };
const DividedRow = styled(Row)`
  .ant-select,
  .ant-picker {
    width: 100%;
  }

  /* !important used here becuase borderless=true uses !important */
  /* TODO: Per Figma we should just make these not borderless, probably */
  .ant-select {
    &.ant-select-show-arrow,
    &.ant-select-loading {
      background-color: ${GRAY_100} !important;
    }
  }

  label {
    font-size: ${sizes.small};
    font-weight: ${fontWeights.semibold};
    padding-left: 12px;
  }

  .ant-space {
    width: 100%;
  }
`;
export const inputStyle = {
  fontSize: sizes.medium,
  color: TEXT1,
  fontWeight: fontWeights.bold,
  width: "100%",
  padding: 0,
  maxWidth: 190
};

const HeaderWrapper = styled.div`
  width: 100%;
  padding: 0px 0px 16px 0px;
`;

const PhoneNumberField = ({ mrn }) => {
  const { t } = useTranslation();
  return (
    <>
      <span style={{ fontWeight: fontWeights.semibold }}>{t("patientTopSection.phone_number")}</span>
      {`: ${formatPhoneNumber(mrn)}`}
    </>
  );
};

const MrnField = ({ mrn }) => {
  const { t } = useTranslation();
  return (
    <>
      <span style={{ fontWeight: fontWeights.semibold }}>{t("patientTopSection.mrn")}</span>
      {`: ${displayedMRN(mrn)}`}
    </>
  );
};

const PatientTopSection = () => {
  const dispatch = useDispatch();

  const selectedView = useSelector(selectSelectedView);
  const patientDetails = useSelector(selectPatientDetails);
  const isFetching = useSelector(selectIsFetching([ApiNames.PatientDetails, ApiNames.PatientDetailsNonPhi]));
  const savedFilter = useSelector(selectFilterList);
  const history = useHistory();
  const { t } = useTranslation();
  const { patientId, journeyId } = useParams();
  const {
    primaryDiagnosesForDisplay,
    importedStatus,
    hasDemographicsConflicts,
    hasDiagnosesConflicts,
    hasInsuranceConflicts
  } = patientDetails || {};

  const [prefix, showPhysician, displayOppsFe, customerType] = useGetSetting([
    "id_prefix",
    "doctorProviderMode",
    "automation.opportunities.fe-display",
    "fe-customer-type"
  ]);

  const shouldReturnToRelevantPatientAct = (shouldReturn, notificationId) =>
    dispatch(ACTIONS.setShouldReturnToRelevantPatient(shouldReturn, notificationId));

  const shouldReturnToRelevantPatient = useSelector(selectShouldReturnToRelevantPatient);
  const { notificationId } = shouldReturnToRelevantPatient;

  return (
    <HeaderWrapper>
      {isFetching && (
        <Space>
          <Skeleton.Avatar active={true} size={"large"} shape={"circle"} />
          <Skeleton.Input style={{ width: 1150 }} active={true} size={"large"} />
        </Space>
      )}
      {!isFetching && (
        <>
          <DividedRow id="main_header_frame">
            <Col span={8} style={{ paddingRight: 12 }}>
              <Space>
                <Button
                  type="text"
                  onClick={() => {
                    if (shouldReturnToRelevantPatient.shouldReturn) {
                      history.push(Routes.ROOT + `${Routes.TOP_OPPORTUNITIES}${notificationId}`, {
                        notification: notificationId
                      });
                      shouldReturnToRelevantPatientAct(false, null);
                    } else if (savedFilter?.id) {
                      history.push(`${Routes.FILTER_LIST}/${savedFilter.id}`);
                    } else {
                      history.push(selectedView ? `/?selectedView=${selectedView}` : "");
                    }
                  }}
                  style={{ padding: 0 }}
                >
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ArrowBack />
                  </div>
                </Button>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {patientDetails?.gender === "female" ? (
                    <Female />
                  ) : patientDetails?.gender === "male" ? (
                    <Male />
                  ) : (
                    <IconUnknownGender />
                  )}
                </div>
                <Space direction="vertical" size={0}>
                  <Space>
                    <Text
                      style={{
                        fontSize: sizes.large,
                        color: TEXT2,
                        fontWeight: fontWeights.semibold
                      }}
                    >
                      {(patientDetails && patientDetails.name) || "Patient"}
                    </Text>
                    <Text
                      style={{
                        fontSize: sizes.medium,
                        color: TEXT2,
                        whiteSpace: "nowrap"
                      }}
                    >
                      <span style={{ fontWeight: fontWeights.regular }}>({prefix ? prefix.toUpperCase() : "TM"}</span>
                      {`-${patientId}`})
                    </Text>
                    <PatientStar width={20} height={16} patientId={parseInt(patientId)} />
                    <PatientMoreInfo />
                  </Space>
                  <Space size={4} split={<Divider type="vertical" style={{ borderColor: TEXT4 }} />}>
                    {patientDetails && patientDetails.mrn && displayedMRN(patientDetails.mrn) && (
                      <Text
                        style={{
                          fontSize: sizes.small,
                          color: TEXT2
                        }}
                      >
                        {customerType.toLowerCase() === customerTypes.RETAIL ? (
                          <PhoneNumberField mrn={patientDetails.mrn} />
                        ) : (
                          <MrnField mrn={patientDetails.mrn} />
                        )}
                      </Text>
                    )}
                    {patientDetails && patientDetails.dob && (
                      <Text
                        style={{
                          fontSize: sizes.small,
                          color: TEXT2
                        }}
                      >
                        <span style={{ fontWeight: fontWeights.semibold }}>{t("patientTopSection.dob")}</span>
                        {`: ${dateFormatter(patientDetails.dob)} (${moment().diff(patientDetails.dob, "years")})`}
                      </Text>
                    )}

                    {patientDetails && patientDetails.deceased && <DeceasedLable />}
                  </Space>
                </Space>
              </Space>

              {(primaryDiagnosesForDisplay && primaryDiagnosesForDisplay.length && (
                <Space
                  direction="vertical"
                  size={4}
                  style={{
                    width: "100%",
                    border: `1px solid ${GRAY_300}`,
                    borderRadius: 8,
                    padding: 12,
                    marginTop: 16
                  }}
                >
                  <Text style={secondaryTextStyle}>{t("patientTopSection.diagnoses")}:</Text>
                  <PatientDiagnoses />
                </Space>
              )) ||
                null}
            </Col>
            <Col span={5} style={sectionJCStyle}>
              <PatientPersonalDetails />
            </Col>
            <Col span={5} style={{ ...sectionJCStyle, padding: "0px 5px" }}>
              <Space direction="vertical" size={8}>
                <PatientAssignees />
                <PatientFacility
                  selectStyle={{ ...inputStyle, maxWidth: "100%" }}
                  secondaryTextStyle={secondaryTextStyle}
                />
                {showPhysician && <PatientPhysician />}
              </Space>
            </Col>
            <Col span={3} style={sectionJCStyle}>
              <Space direction="vertical" size={8}>
                <PatientStatus
                  selectorId={"patient_header_status"}
                  patientId={patientId}
                  inputStyle={{ ...inputStyle, maxWidth: "120px" }}
                  secondaryTextStyle={secondaryTextStyle}
                />
                {importedStatus && (
                  <>
                    <Text style={{ ...secondaryTextStyle, maxWidth: "120px" }}>
                      {t("patientTopSection.import_status")}
                    </Text>
                    <Text style={{ maxWidth: "120px" }}>{importedStatus}</Text>
                  </>
                )}
                <PatientFollowUpDate
                  patientId={patientId}
                  inputStyle={inputStyle}
                  secondaryTextStyle={secondaryTextStyle}
                />
              </Space>
            </Col>
            <Col span={3}>
              <Space direction="vertical" size={8}>
                <Button
                  id="manage_patient_details"
                  type="text"
                  style={{
                    background: WHITE_GRAY,
                    backgroundColor: PRIMARY_600,
                    width: "150px",
                    display: "flex",
                    justifyContent: "center"
                  }}
                  onClick={() =>
                    history.push(`/patient/${patientId}/journey/${journeyId}/edit`, {
                      path: location.pathname
                    })
                  }
                >
                  <Text
                    style={{
                      paddingTop: 2,
                      color: WHITE_GRAY,
                      fontSize: sizes.small,
                      fontWeight: fontWeights.semibold
                    }}
                  >
                    {t("manage_patient_details")}
                  </Text>
                </Button>
                {(hasDemographicsConflicts || hasDiagnosesConflicts || hasInsuranceConflicts) && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: ERROR_50,
                      borderRadius: 32,
                      padding: "4px 5px",
                      gap: 5
                    }}
                  >
                    <HasConflictIcon />
                    <Text id="patient-has-coverage" style={{ fontSize: 9, color: "#F04438" }}>
                      {t("patientTopSection.data_conflicts")}
                    </Text>
                  </div>
                )}
                <Space direction="vertical" size={4}>
                  {patientDetails?.isOpportunity && displayOppsFe && <ExcludeHighPriority />}
                </Space>
              </Space>
            </Col>
          </DividedRow>
        </>
      )}
    </HeaderWrapper>
  );
};

export default PatientTopSection;
