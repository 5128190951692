export const formPopupsContentKeys = Object.freeze({
  ADD_DRUG: "ADD_DRUG",
  ADD_DRUG_SETTING: "Add Drug",
  ADD_ASSISTANCE_PROGRAM: "ADD_ASSISTANCE_PROGRAM",
  ADD_ASSISTANCE_PROGRAM_SETTING: "Add Assistance Program",
  ADD_NEW_LIST: "Add New List",
  TASKS: "TASKS",
  NOTIFICATIONS: "NOTIFICATIONS",
  PATIENT_TASKS: "PATIENT_TASKS"
});
