import { defaultRelevantPatientsFilters } from "./filters";

export const OOP_MET_STATUS = "oop-met";
export const RELEVANT_PATIENTS_TABLE_CONFIG = "RelevantPatientsTableConfig";

export const getRelevantPatientsTableConfigFromLocalStorage = () => {
  const relevantPatientsTableConfig = localStorage.getItem(RELEVANT_PATIENTS_TABLE_CONFIG);

  const defaultRelevantPatientsTableConfig = {
    id: 0,
    filters: {
      ...defaultRelevantPatientsFilters,
      statuses: [
        {
          value: "awaiting-assistance",
          children: "Awaiting Assistance"
        }
      ]
    },
    sorter: {},
    currentPage: 1
  };

  if (!relevantPatientsTableConfig) {
    localStorage.setItem(RELEVANT_PATIENTS_TABLE_CONFIG, JSON.stringify(defaultRelevantPatientsTableConfig));
    return defaultRelevantPatientsTableConfig;
  }

  return JSON.parse(relevantPatientsTableConfig);
};

export const saveRelevantPatientsTableConfigToLocalStorage = (filters) => {
  localStorage.setItem(RELEVANT_PATIENTS_TABLE_CONFIG, JSON.stringify(filters));
};
