import React from "react";
import { Typography } from "antd";

import { fontWeights, sizes } from "../../constant/styles";
import { GRAY_500 } from "../../constant/colors";
import { StyledModal } from "../Patient/PatientApplications/enrollTab/utils/styledComponents";

const { Text } = Typography;

export const BigIconModal = ({ icon, title, description, children, visible, width }) => (
  <StyledModal centered open={visible} footer={null} bodyStyle={{ padding: "24px" }} width={width} closable={false}>
    <div style={{ marginBottom: 12 }}>{icon}</div>
    <div style={{ marginBottom: 8 }}>
      <Text style={{ fontWeight: fontWeights.semibold, fontSize: sizes.large }}>{title} </Text>
    </div>
    <div style={{ marginBottom: 8 }}>
      <Text style={{ fontWeight: fontWeights.regular, fontSize: sizes.medium, color: GRAY_500 }}>{description} </Text>
    </div>
    {children}
  </StyledModal>
);
