import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Form, Input, Row, Col } from "antd";

import { CardContainer } from "../../component/customComponent/CardContainer";
import { FormFields } from "./FormFields";

const { TextArea } = Input;

const StyledTextArea = styled(TextArea)`
  textarea {
    border-radius: 4px;
  }
`;

const NumberInput = styled(Input)`
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  border-radius: 4px;
  // width: 316px;
`;

const ManagePatientDetailsMedical = ({ t }) => {
  return (
    <CardContainer>
      <Row
        justify="center"
        gutter={[0, 4]}
        style={{ display: "flex", justifyContent: "flex-start", gap: 20 }}
        id="manage-patient-details-medical-information"
      >
        <Form.Item
          style={{ width: "120px" }}
          name={[FormFields.patientHeightFeet]}
          colon="false"
          label={t("managePatientDetails.patient_height")}
        >
          <NumberInput
            style={{ width: "100%" }}
            min={0}
            onFocus={(e) => (e.target.placeholder = "")}
            onBlur={(e) => (e.target.placeholder = t("managePatientDetails.patient_height_placeholder"))}
            type={"number"}
            placeholder={0}
            suffix="ft"
          />
        </Form.Item>
        <Form.Item name={[FormFields.patientHeightInch]} colon="false" label=" " style={{ width: "120px" }}>
          <NumberInput
            style={{ width: "100%" }}
            min={0}
            onFocus={(e) => (e.target.placeholder = "")}
            onBlur={(e) => (e.target.placeholder = t("managePatientDetails.patient_height_placeholder"))}
            type={"number"}
            placeholder={0}
            suffix="in"
          />
        </Form.Item>
        <Form.Item
          name={[FormFields.patientWeight]}
          colon="false"
          label={t("managePatientDetails.patient_weight")}
          style={{ width: "120px" }}
        >
          <NumberInput
            style={{ width: "100%" }}
            min={0}
            onFocus={(e) => (e.target.placeholder = "")}
            onBlur={(e) => (e.target.placeholder = t("managePatientDetails.patient_weight_placeholder"))}
            type={"number"}
            placeholder={0}
            suffix="lbs"
          />
        </Form.Item>
      </Row>

      <Row justify="center">
        <Col style={{ width: "100%" }}>
          <Form.Item
            name={[FormFields.patientAllergies]}
            colon="false"
            label={t("managePatientDetails.patient_allergies")}
          >
            <StyledTextArea
              placeholder={t("managePatientDetails.patient_allergies_placeholder")}
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) => (e.target.placeholder = t("managePatientDetails.patient_allergies_placeholder"))}
              showCount
              maxLength={800}
              autoSize={{ minRows: 6 }}
            />
          </Form.Item>
        </Col>
      </Row>
    </CardContainer>
  );
};

export default compose(withTranslation())(ManagePatientDetailsMedical);
