import { useDispatch, useSelector } from "react-redux";
import { selectEnvironment, selectSelectedView, selectUser } from "../../store/selector";
import { trackPendoEvent } from "./pendoUtils";

export const useDashboardBucketsTrackingEvent = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const account = useSelector(selectEnvironment);
  const selectedView = useSelector(selectSelectedView);

  const trackEvent = (eventName, extraData = {}) => {
    try {
      trackPendoEvent(dispatch, eventName, {
        user: user?.email,
        account,
        selectedView,
        ...extraData
      });
    } catch (errorText) {
      console.error("Error dispatching dashboard filters Pendo event:", errorText);
    }
  };
  return { trackEvent };
};
