import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { createStructuredSelector } from "reselect";
import { Popover, Space, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { take } from "lodash";
import { selectPatientDetails, selectTier } from "../../../store/selector";
import { fontWeights, sizes } from "../../../constant/styles";
import { BLACK, GRAY_600, GRAY_900, WHITE } from "../../../constant/colors";
import { translatePlanMarket, PLAN_MARKETS } from "../../../constant/planMarkets";
import { UNSPECIFIED_PAYER_NAME } from "../../../constant/coverages";
import { MoreDetailsTextStyle } from "./styles";

const { Text } = Typography;

const MAX_LENGTH = 2;

const primaryTextStyle = {
  fontSize: sizes.medium,
  color: GRAY_900,
  fontWeight: fontWeights.regular,
  maxWidth: 190,
  cursor: "pointer"
};

const secondaryTextStyle = { fontSize: sizes.small, color: GRAY_600, fontWeight: fontWeights.semibold };

const PatientCoverageDetailsComponent = ({ patientDetails }) => {
  const { t } = useTranslation();
  const [coverages, setCoverages] = useState([]);

  const { patientInsurances = [] } = patientDetails || [];

  useEffect(() => {
    if (patientInsurances) {
      setCoverages(take(patientInsurances, MAX_LENGTH));
    }
  }, [patientInsurances]);

  const renderCoverages = () => {
    return coverages.map(({ payerName, coverageType, planMarket }, index) => {
      const firstLetterInsuranceType = coverageType.slice(0, 1).toUpperCase();
      const hasPayerName = payerName && payerName !== UNSPECIFIED_PAYER_NAME;

      return planMarket === PLAN_MARKETS.Uninsured ? (
        <Text key={index}>{`${translatePlanMarket(planMarket)}`}</Text>
      ) : (
        <Tooltip
          key={index}
          title={
            <Space direction="vertical" size={2}>
              <Text style={{ fontWeight: fontWeights.semibold }}>{hasPayerName ? payerName : "--"}</Text>
              <Text>{translatePlanMarket(planMarket)}</Text>
            </Space>
          }
          color={WHITE}
          overlayInnerStyle={{ color: BLACK }}
        >
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <Text
              style={{
                ...primaryTextStyle,
                maxWidth: "100%"
              }}
              ellipsis={true}
            >{`${hasPayerName ? payerName : translatePlanMarket(planMarket)}`}</Text>
            <Text
              style={{
                ...primaryTextStyle,
                maxWidth: "none",
                marginLeft: "4px",
                whiteSpace: "nowrap",
                position: "relative",
                bottom: 1
              }}
            >{` (${firstLetterInsuranceType})`}</Text>
          </div>
        </Tooltip>
      );
    });
  };

  return (
    <>
      <Space direction="vertical" size={0}>
        <Text style={{ ...secondaryTextStyle, color: GRAY_900 }}>{t("patientTopSection.insurance")}:</Text>
        {renderCoverages()}
      </Space>
      <Space direction="vertical" size={2}>
        {patientInsurances.length > MAX_LENGTH && (
          <Popover
            placement="rightTop"
            overlayInnerStyle={{
              minWidth: "220px",
              padding: "0px 12px",
              borderRadius: "8px",
              boxShadow: " 0px 4px 4px 0px #00000040"
            }}
            content={
              <Space size={2} direction="vertical">
                <>
                  <Text style={{ ...secondaryTextStyle, color: GRAY_600 }}>{t("patientTopSection.insurance")}:</Text>
                  {patientInsurances.map(({ payerName, planMarket, coverageType }) => (
                    <Space key={payerName} size={2} direction="horizontal">
                      {payerName != UNSPECIFIED_PAYER_NAME && (
                        <Text style={secondaryTextStyle}>{`${payerName} - `}</Text>
                      )}
                      <Text style={primaryTextStyle}>
                        {`${translatePlanMarket(planMarket)} (${coverageType.slice(0, 1).toUpperCase()})`}
                      </Text>
                    </Space>
                  ))}
                </>
              </Space>
            }
            color={WHITE}
            trigger={"click"}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            <MoreDetailsTextStyle id="more_insurance">{`+${patientInsurances.length - MAX_LENGTH} ${t(
              t("patientTopSection.more_insurance")
            )}`}</MoreDetailsTextStyle>
          </Popover>
        )}
      </Space>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  patientDetails: selectPatientDetails,
  tier: selectTier
});

const PatientCoverageDetails = compose(withTranslation(), connect(mapStateToProps))(PatientCoverageDetailsComponent);

export { PatientCoverageDetails };
