import { actions as commonActions, actionTypes as commonActionTypes } from "@tailormed/common-client/store/action";

const Types = {
  LOGOUT: "LOGOUT",
  SET_ENVIRONMENT: "SET_ENVIRONMENT",
  SET_TIER: "SET_TIER",
  SET_USER: "SET_USER",
  SET_USERS: "SET_USERS",
  SET_NETWORK_ACTION: "SET_NETWORK_ACTION",
  SEARCH_ASSISTANCE_PROGRAM_ACTION: "SEARCH_ASSISTANCE_PROGRAM_ACTION",
  SEARCH_ASSISTANCE_PROGRAM_BY_ID_ACTION: "SEARCH_ASSISTANCE_PROGRAM_BY_ID_ACTION",
  SET_SELECTED_ASSISTANCE_PROGRAM_BY_ID: "SET_SELECTED_ASSISTANCE_PROGRAM_BY_ID",
  CLEAR_SELECTED_ASSISTANCE_PROGRAM_BY_ID: "CLEAR_SELECTED_ASSISTANCE_PROGRAM_BY_ID",
  SET_IS_PHI_ALLOWED: "SET_IS_PHI_ALLOWED",
  SET_IS_SIDE_BAR_COLLAPSED: "SET_IS_SIDE_BAR_COLLAPSED",
  SET_SHOULD_RETURN_TO_RELEVANT_PATIENT: "SET_SHOULD_RETURN_TO_RELEVANT_PATIENT",
  SET_SHOW_ASSISTANCE_PROGRAM_MODAL: "SET_SHOW_ASSISTANCE_PROGRAM_MODAL",
  SET_SHOW_EXPRESS_ENROLL_MODAL: "SET_SHOW_EXPRESS_ENROLL_MODAL",
  SET_SHOW_SUPPORT: "SET_SHOW_SUPPORT",
  SET_ASSISTANCE_PROGRAMS: "SET_ASSISTANCE_PROGRAMS",
  SET_SELECTED_ASSISTANCE_PROGRAM: "SET_SELECTED_ASSISTANCE_PROGRAM",
  CLEAR_ASSISTANCE_PROGRAMS: "CLEAR_ASSISTANCE_PROGRAMS",
  SET_SETTINGS: "SET_SETTINGS",
  SEARCH_PATIENTS_ACTION: "SEARCH_PATIENTS_ACTION",
  CHANGE_PROVIDER_NETWORK_ACTION: "CHANGE_PROVIDER_NETWORK_ACTION",
  SET_SEARCHED_PATIENTS: "SET_SEARCHED_PATIENTS",
  UPDATE_SEARCHED_PATIENT: "UPDATE_SEARCHED_PATIENT",
  CLEAR_SEARCHED_PATIENTS: "CLEAR_SEARCHED_PATIENTS",
  SET_TABLE_COLUMNS: "SET_TABLE_COLUMNS",
  CLEAR_SELECTED_VIEW: "CLEAR_SELECTED_VIEW",
  SET_SELECTED_VIEW: "SET_SELECTED_VIEW",
  SET_PATIENTS: "SET_PATIENTS",
  SET_TABLE_DATA_ACTION: "SET_TABLE_DATA_ACTION",
  PAGINATE_SORT_CHANGE: "PAGINATE_SORT_CHANGE",
  SET_TABLES_DATA: "SET_TABLES_DATA",
  SET_SELECTED_FILTER_LIST_TYPE: "SET_SELECTED_FILTER_LIST_TYPE",
  SET_PATIENT_TABLE_RECORD_DATA: "SET_PATIENT_TABLE_RECORD_DATA",
  SET_SAVED_LISTS_TABLE_RECORD_DATA: "SET_SAVED_LISTS_TABLE_RECORD_DATA",
  SET_ASSIGNEES: "SET_ASSIGNEES",
  SET_LIST_NAME_QUERY_ACTION: "SET_LIST_NAME_QUERY_ACTION",
  SET_SAVED_LIST_SORT_BY_ACTION: "SET_SAVED_LIST_SORT_BY_ACTION",
  SET_SAVED_LIST_ORDER_BY_ACTION: "SET_SAVED_LIST_ORDER_BY_ACTION",
  INIT_SAVED_LIST: "INIT_SAVED_LIST",
  SET_USER_GROUPS: "SET_USER_GROUPS",
  GET_PATIENT_ACTION: "GET_PATIENT_ACTION",
  GET_POTENTIAL_SAVINGS: "GET_POTENTIAL_SAVINGS",
  GET_PATIENT_DETAILS_ACTION: "GET_PATIENT_DETAILS_ACTION",
  GET_PATIENT_JOURNEY_ACTION: "GET_PATIENT_JOURNEY_ACTION",
  UPDATE_PATIENT_DETAILS_ACTION: "UPDATE_PATIENT_DETAILS_ACTION",
  CLEAR_PATIENT: "CLEAR_PATIENT",
  PATIENT_DETAILS: "PATIENT_DETAILS",
  PROVIDERS_ACTION: "PROVIDERS_ACTION",
  SET_FILTER_LIST: "SET_FILTER_LIST",
  PROVIDERS: "PROVIDERS",
  GET_AP_SPONSORS: "GET_AP_SPONSORS",
  SET_CALCULATED_SAVED_LISTS_RECORDS: "SET_CALCULATED_SAVED_LISTS_RECORDS",
  SET_UNITS: "SET_UNITS",
  SET_VISITS_TYPES: "SET_VISITS_TYPES",
  POTENTIAL_SAVINGS: "POTENTIAL_SAVINGS",
  SET_FORM_POPUPS: "SET_FORM_POPUPS",
  SET_IS_CLAIM_FORM_VIEW: "SET_IS_CLAIM_FORM_VIEW",
  SET_APPLICATION_CLAIMS: "SET_APPLICATION_CLAIMS",
  CLEAR_APPLICATION_CLAIMS: "CLEAR_APPLICATION_CLAIMS",
  ADD_DRUG_ACTION: "ADD_DRUG_ACTION",
  SET_FILTERS: "SET_FILTERS",
  SET_FILTERS_ACTION: "SET_FILTERS_ACTION",
  CLEAR_FILTERS: "CLEAR_FILTERS",
  SET_FILTERS_INPUTS: "SET_FILTERS_INPUTS",
  APPLY_SERVER_FILTERS_ACTION: "APPLY_SERVER_FILTERS_ACTION",
  SET_MULTIPLE_FILTERS_INPUTS: "SET_MULTIPLE_FILTERS_INPUTS",
  CLEAR_FILTERS_INPUTS: "CLEAR_FILTERS_INPUTS",
  RESET_SAVED_LIST_VIEW_FILTERS: "RESET_SAVED_LIST_VIEW_FILTERS",
  CLEAR_SAVED_LIST_RELATED_DATA_ACTION: "CLEAR_SAVED_LIST_RELATED_DATA_ACTION",
  SET_SUMMARY_COUNTERS: "SET_SUMMARY_COUNTERS",
  GET_SUMMARY_COUNTERS: "GET_SUMMARY_COUNTERS",
  PATIENT_JOURNEY: "PATIENT_JOURNEY",
  GENERIC_SERVICES_ACTION: "GENERIC_SERVICES_ACTION",
  GENERIC_SERVICES: "GENERIC_SERVICES",
  PATIENT_AUTOMATE_ACTION: "PATIENT_AUTOMATE_ACTION",
  PATIENT_OPPORTUNITY: "PATIENT_OPPORTUNITY",
  UPDATE_DISCONTINUED_DATE_ACTION: "UPDATE_DISCONTINUED_DATE_ACTION",
  UPDATE_OOP_ESTIMATION: "UPDATE_OOP_ESTIMATION",
  GET_OOP_ESTIMATION: "GET_OOP_ESTIMATION",
  SET_OOP_ESTIMATIONS: "SET_OOP_ESTIMATIONS",
  SET_FORM_ITEMS_DIAGNOSES: "SET_FORM_ITEMS_DIAGNOSES",
  POTENTIAL_SAVINGS_JOURNEY_ACTION: "POTENTIAL_SAVINGS_JOURNEY_ACTION",
  POTENTIAL_SAVINGS_JOURNEY: "POTENTIAL_SAVINGS_JOURNEY",
  SET_CURRENT_APPLICATION: "SET_CURRENT_APPLICATION",
  SET_COPY_PASTE_POPOVER: "SET_COPY_PASTE_POPOVER",
  SET_EXPRESS_ENROLL_FORM_OPEN: "SET_EXPRESS_ENROLL_FORM_OPEN",
  SET_SELECTED_JOURNEY_EVENT_HANDLER: "SET_SELECTED_JOURNEY_EVENT_HANDLER",
  APPROVE_INSURANCE_OPPORTUNITY_ACTION: "APPROVE_INSURANCE_OPPORTUNITY_ACTION",
  ADD_ASSISTANCE_PROGRAM_WITH_DRUG_ACTION: "ADD_ASSISTANCE_PROGRAM_WITH_DRUG_ACTION",
  PATIENT_COVERAGES_ACTION: "PATIENT_COVERAGES_ACTION",
  PATIENT_UNKNOWN_AND_INACTIVE_INSURANCE_ACTION: "PATIENT_UNKNOWN_AND_INACTIVE_INSURANCE_ACTION",
  SET_PATIENT_UNKNOWN_AND_INACTIVE_INSURANCE: "SET_PATIENT_UNKNOWN_AND_INACTIVE_INSURANCE",
  PATIENT_COVERAGES: "PATIENT_COVERAGES",
  POLICIES_ACTION: "POLICIES_ACTION",
  POLICIES: "POLICIES",
  PATIENT_SHIPPING_OPTIONS: "PATIENT_SHIPPING_OPTIONS",
  UPDATE_APPLICATION_RELEVANCE: "UPDATE_APPLICATION_RELEVANCE",
  GET_SHIPPING_OPTIONS: "GET_SHIPPING_OPTIONS",
  FILL_APPLICATION_FORM: "FILL_APPLICATION_FORM",
  SET_APPLICATION_FORM: "SET_APPLICATION_FORM",
  SET_EXPRESS_ENROLLMENT_CONFIGURATION: "SET_EXPRESS_ENROLLMENT_CONFIGURATION",
  SET_APPLICATION_ATTACHMENTS: "SET_PATIENT_APPLICATION_ATTACHMENTS",
  GET_APPLICATION_ATTACHMENTS: "GET_APPLICATION_ATTACHMENTS",
  DOCUSIGN_CREATE_ACTION: "DOCUSIGN_CREATE_ACTION",
  APPLICATION_EDIT_ACTION: "APPLICATION_EDIT_ACTION",
  APPLICATION_EDIT_HISTORICAL_ACTION: "APPLICATION_EDIT_HISTORICAL_ACTION",
  APPLICATION_DELETE_ACTION: "APPLICATION_DELETE_ACTION",
  POTENTIAL_SAVING_ACTION: "POTENTIAL_SAVING_ACTION",
  POTENTIAL_SAVING: "POTENTIAL_SAVING",
  PATIENT_REAPPLY_ACTION: "PATIENT_REAPPLY_ACTION",
  PATIENT_CREATE_APPLICATION_ACTION: "PATIENT_CREATE_APPLICATION_ACTION",
  APPLICATION_RELEVANCE_FOR_RENEWAL: "APPLICATION_RELEVANCE_FOR_RENEWAL",
  SET_IDP_CONFIG: "SET_IDP_CONFIG",
  SEARCH_SAVED_LIST_BY_ID: "SEARCH_SAVED_LIST_BY_ID",
  CLEAR_SORTER: "CLEAR_SORTER",
  SEARCH_SAVED_LIST_ACTION: "SEARCH_SAVED_LIST_ACTION",
  CREATE_FILTER_LIST_ACTION: "CREATE_FILTER_LIST_ACTION",
  UPDATE_FILTER_LIST_ACTION: "UPDATE_FILTER_LIST_ACTION",
  MARK_SAVED_FILTER_AS_CHANGED: "MARK_SAVED_FILTER_AS_CHANGED",
  UPDATE_SAVED_FILTER_VIEW_PARAMETERS: "UPDATE_SAVED_FILTER_VIEW_PARAMETERS",
  GET_ALL_CLAIMS: "GET_ALL_CLAIMS",
  CREATE_CLAIM_ACTION: "CREATE_CLAIM_ACTION",
  UPDATE_CLAIM_ACTION: "UPDATE_CLAIM_ACTION",
  TOGGLE_CLAIM_ACTIVE_STATUS_ACTION: "TOGGLE_CLAIM_ACTIVE_STATUS_ACTION",
  DUPLICATE_CLAIM_ACTION: "DUPLICATE_CLAIM_ACTION",
  SET_FILTER_BY_CLAIMS_STATUS: "SET_FILTER_BY_CLAIMS_STATUS",
  GET_POTENTIAL_SAVING: "GET_POTENTIAL_SAVING",
  SET_OKTA_CONFIG: "SET_OKTA_CONFIG",
  UPDATE_ENROLLMENT_STORE: "UPDATE_ENROLLMENT_STORE",
  RESET_ENROLLMENT_STORE: "RESET_ENROLLMENT_STORE",
  FETCH_APPLICATION_FORM_SIGNATURES: "FETCH_APPLICATION_FORM_SIGNATURES",
  FETCH_PAP_PDF_METADATA: "FETCH_PAP_PDF_METADATA",
  SET_APPLICATION_FORM_SIGNATURE: "SET_APPLICATION_FORM_SIGNATURE",
  RESET_APPLICATION_FORM_SIGNATURE: "RESET_APPLICATION_FORM_SIGNATURE",
  REFETCH_ALL_APPLICATIONS: "REFETCH_ALL_APPLICATIONS",
  SET_SHOW_SECURE_UPLOAD: "SET_SHOW_SECURE_UPLOAD",
  SET_APPLICATION_FORM_MODAL_VISIBILITY: "SET_APPLICATION_FORM_MODAL_VISIBILITY",
  UPDATE_FILTER_LIST_STATUSES: "UPDATE_FILTER_LIST_STATUSES",
  SET_BADGE_COUNTS: "SET_BADGE_COUNTS",
  ...commonActionTypes
};

const logout = () => ({
  type: Types.LOGOUT
});

const setEnvironment = (environment) => ({
  type: Types.SET_ENVIRONMENT,
  environment
});

const setTier = (tier) => ({
  type: Types.SET_TIER,
  tier
});

const setUser = (user) => ({
  type: Types.SET_USER,
  user
});

const setUsers = (users) => ({
  type: Types.SET_USERS,
  users
});

const setAssignees = (assignees) => ({
  type: Types.SET_ASSIGNEES,
  assignees
});

const setUserGroups = (userGroups) => ({
  type: Types.SET_USER_GROUPS,
  userGroups
});

const clearSelectedView = () => ({
  type: Types.CLEAR_SELECTED_VIEW
});

const setSelectedView = (selectedView) => ({
  type: Types.SET_SELECTED_VIEW,
  selectedView
});

const markSavedFilterAsChanged = (filterListID, filterListStatus) => ({
  type: Types.MARK_SAVED_FILTER_AS_CHANGED,
  filterListID,
  filterListStatus
});

const updateFilterListStatuses = (filterListID, canModify, showDiscardModal, nextSelectedView = "") => ({
  type: Types.UPDATE_FILTER_LIST_STATUSES,
  filterListID,
  canModify,
  showDiscardModal,
  nextSelectedView
});

const updateSavedFilterViewParameters = (filterListID, selectedPage, sorter) => ({
  type: Types.UPDATE_SAVED_FILTER_VIEW_PARAMETERS,
  filterListID,
  selectedPage,
  sorter
});

const searchAssistanceProgramAction = (searchQuery, filters) => ({
  type: Types.SEARCH_ASSISTANCE_PROGRAM_ACTION,
  searchQuery,
  filters
});

const searchAssistanceProgramByIdAction = (id) => ({
  type: Types.SEARCH_ASSISTANCE_PROGRAM_BY_ID_ACTION,
  id
});

const setSelectedAssistanceProgramById = (assistanceProgram) => ({
  type: Types.SET_SELECTED_ASSISTANCE_PROGRAM_BY_ID,
  assistanceProgram
});

const clearSelectedAssistanceProgramById = () => ({
  type: Types.CLEAR_SELECTED_ASSISTANCE_PROGRAM_BY_ID
});

const setShowAssistanceProgramModal = (showAssistanceProgramModal) => ({
  type: Types.SET_SHOW_ASSISTANCE_PROGRAM_MODAL,
  showAssistanceProgramModal
});

const setShowExpressEnrollModal = (showExpressEnrollModal) => ({
  type: Types.SET_SHOW_EXPRESS_ENROLL_MODAL,
  showExpressEnrollModal
});

const setShowSupport = (visible, assistanceProgram = null) => ({
  type: Types.SET_SHOW_SUPPORT,
  visible,
  assistanceProgram
});

const setShowSecureUpload = (visible) => ({
  type: Types.SET_SHOW_SECURE_UPLOAD,
  visible
});

const setAssistancePrograms = (assistancePrograms) => ({
  type: Types.SET_ASSISTANCE_PROGRAMS,
  assistancePrograms
});

const setNetworkAction = (actionType, isFetching, msg = "", code = undefined) => ({
  type: Types.SET_NETWORK_ACTION,
  actionType,
  isFetching,
  msg,
  code
});

const setIsPhiAllowed = (isPhiAllowed) => ({
  type: Types.SET_IS_PHI_ALLOWED,
  isPhiAllowed
});

const setIsSidebarCollapsed = (isSidebarCollapsed) => ({
  type: Types.SET_IS_SIDE_BAR_COLLAPSED,
  isSidebarCollapsed
});

const setShouldReturnToRelevantPatient = (shouldReturn, notificationId) => ({
  type: Types.SET_SHOULD_RETURN_TO_RELEVANT_PATIENT,
  shouldReturn,
  notificationId
});

const setTableColumns = (tableColumns) => ({
  type: Types.SET_TABLE_COLUMNS,
  tableColumns
});

const setSettings = (settings) => ({
  type: Types.SET_SETTINGS,
  settings
});

const searchPatientsAction = (page, text, searchBy, sorter = {}) => ({
  type: Types.SEARCH_PATIENTS_ACTION,
  page,
  text,
  searchBy,
  sorter
});

const changeProviderNetworkAction = (policyId, patientId, journeyId, network) => ({
  type: Types.CHANGE_PROVIDER_NETWORK_ACTION,
  policyId,
  patientId,
  journeyId,
  network
});

const setSearchedPatients = (patients, selectedPage = 1, sorter = {}) => ({
  type: Types.SET_SEARCHED_PATIENTS,
  searchedPatients: patients,
  selectedPage,
  sorter
});

const clearSearchedPatients = () => ({
  type: Types.CLEAR_SEARCHED_PATIENTS
});

const setPatients = (patients) => ({
  type: Types.SET_PATIENTS,
  patients
});

const setTableDataAction = (page, selectedView, sorter = {}) => ({
  type: Types.SET_TABLE_DATA_ACTION,
  page,
  selectedView,
  sorter
});

const paginateSortChange = (page, selectedView, sorter) => ({
  type: Types.PAGINATE_SORT_CHANGE,
  page,
  selectedView,
  sorter
});

const setTablesData = (tableData, selectedPage, selectedView, sorter = {}) => ({
  type: Types.SET_TABLES_DATA,
  tableData,
  selectedPage,
  selectedView,
  sorter
});

const setSelectedFilterListType = (listType) => ({
  type: Types.SET_SELECTED_FILTER_LIST_TYPE,
  listType
});

const setPatientTableRecordData = (patientId, sharedFields) => ({
  type: Types.SET_PATIENT_TABLE_RECORD_DATA,
  patientId,
  sharedFields
});
const updateSearchedPatient = (patientId, sharedFields) => ({
  type: Types.UPDATE_SEARCHED_PATIENT,
  patientId,
  sharedFields
});
const setSavedListsTableRecordData = (recordData) => ({
  type: Types.SET_SAVED_LISTS_TABLE_RECORD_DATA,
  recordData
});

const setFiltersAction = (filters, tableName = undefined, page = 1, sorter = {}) => ({
  type: Types.SET_FILTERS_ACTION,
  filters,
  tableName,
  page,
  sorter
});

const setListNameQueryAction = (query) => ({
  type: Types.SET_LIST_NAME_QUERY_ACTION,
  query
});

const updateSavedListSortBy = (sortByDirection) => ({
  type: Types.SET_SAVED_LIST_SORT_BY_ACTION,
  sortBy: sortByDirection
});

const updateSavedListOrderBy = (columnName) => ({
  type: Types.SET_SAVED_LIST_ORDER_BY_ACTION,
  orderBy: columnName
});

const setFilters = (filters) => ({
  type: Types.SET_FILTERS,
  filters
});

const clearFilters = () => ({
  type: Types.CLEAR_FILTERS
});

const setFiltersInputs = (filterName, value) => ({
  type: Types.SET_FILTERS_INPUTS,
  filterName,
  value
});

const setMultipleFiltersInputs = (filters) => ({
  type: Types.SET_MULTIPLE_FILTERS_INPUTS,
  filters
});

const applyServerFiltersAction = () => ({
  type: Types.APPLY_SERVER_FILTERS_ACTION
});

const clearFiltersInputs = () => ({
  type: Types.CLEAR_FILTERS_INPUTS
});

const clearSavedListRelatedDataAction = () => ({
  type: Types.CLEAR_SAVED_LIST_RELATED_DATA_ACTION
});

const resetSavedListViewFilters = () => ({
  type: Types.RESET_SAVED_LIST_VIEW_FILTERS
});

const getPatientAction = (patientId, journeyId) => ({
  type: Types.GET_PATIENT_ACTION,
  patientId,
  journeyId
});

const getPatientDetailsAction = (patientId) => ({
  type: Types.GET_PATIENT_DETAILS_ACTION,
  patientId
});

const getPatientJourneyAction = (journeyId) => ({
  type: Types.GET_PATIENT_JOURNEY_ACTION,
  journeyId
});

const updatePatientDetailsAction = (patientDetails) => ({
  type: Types.UPDATE_PATIENT_DETAILS_ACTION,
  patientDetails
});

const clearPatient = () => ({
  type: Types.CLEAR_PATIENT
});

const patientDetails = (patientData) => ({
  type: Types.PATIENT_DETAILS,
  patientData
});

const providersAction = (providerType) => ({
  type: Types.PROVIDERS_ACTION,
  providerType
});

const provider = (providerType, providerData) => ({
  type: Types.PROVIDERS,
  providerData,
  providerType
});

const APSponsors = (data) => ({
  type: Types.GET_AP_SPONSORS,
  data
});

const setVisitTypes = (visitType) => ({
  type: Types.SET_VISITS_TYPES,
  visitType
});

const setUnits = (units) => ({
  type: Types.SET_UNITS,
  units
});

const setFilterList = (filterList) => ({
  type: Types.SET_FILTER_LIST,
  filterList
});

const setPatientUnknownAndInactiveInsuranceInfo = (unknownAndInactiveInsuranceInfo) => ({
  type: Types.SET_PATIENT_UNKNOWN_AND_INACTIVE_INSURANCE,
  unknownAndInactiveInsuranceInfo
});

const potentialSavings = (potentialSavings) => ({
  type: Types.POTENTIAL_SAVINGS,
  potentialSavings
});

const setFormPopups = (visible, contentKey) => ({
  type: Types.SET_FORM_POPUPS,
  visible,
  contentKey
});

const setBadgeCounts = (key, value) => ({
  type: Types.SET_BADGE_COUNTS,
  key,
  value
});

const setApplicationClaims = (claims) => ({
  type: Types.SET_APPLICATION_CLAIMS,
  claims
});

const clearApplicationClaims = () => ({
  type: Types.CLEAR_APPLICATION_CLAIMS
});

const setFilterByClaimStatus = (filterByClaimsStatus) => ({
  type: Types.SET_FILTER_BY_CLAIMS_STATUS,
  filterByClaimsStatus
});

const addDrugAction = (formValues, drugData, patientId, journeyId) => ({
  type: Types.ADD_DRUG_ACTION,
  formValues,
  drugData,
  patientId,
  journeyId
});

const setSummaryCounters = (summaryCounters) => ({
  type: Types.SET_SUMMARY_COUNTERS,
  summaryCounters
});

const getSummaryCounters = () => ({
  type: Types.GET_SUMMARY_COUNTERS
});

const patientJourney = (journeyData) => ({
  type: Types.PATIENT_JOURNEY,
  journeyData
});

const genericServicesAction = (queryIds) => ({
  type: Types.GENERIC_SERVICES_ACTION,
  queryIds
});

const genericServices = (genericServices) => ({
  type: Types.GENERIC_SERVICES,
  genericServices
});

const patientAutomateAction = (patientId, automateRule) => ({
  type: Types.PATIENT_AUTOMATE_ACTION,
  patientId,
  automateRule
});

const patientOpportunity = (data) => ({
  type: Types.PATIENT_OPPORTUNITY,
  data
});

const updateDiscontinuedDateAction = (patientId, journeyId, journeyEvent, discontinuedDate, history) => ({
  type: Types.UPDATE_DISCONTINUED_DATE_ACTION,
  patientId,
  journeyId,
  journeyEvent,
  discontinuedDate,
  history
});

const updateOOPEstimation = (data, journey) => ({
  type: Types.UPDATE_OOP_ESTIMATION,
  data,
  journey
});

const getOOPEstimations = (journey) => ({
  type: Types.GET_OOP_ESTIMATION,
  journey
});

const setOOPEstimations = (data) => ({
  type: Types.SET_OOP_ESTIMATIONS,
  data
});

const setFormItemsDiagnoses = (data) => ({
  type: Types.SET_FORM_ITEMS_DIAGNOSES,
  data
});

const potentialSavingsJourneyAction = (journeyId) => ({
  type: Types.POTENTIAL_SAVINGS_JOURNEY_ACTION,
  journeyId
});

const potentialSavingsJourney = (data) => ({
  type: Types.POTENTIAL_SAVINGS_JOURNEY,
  data
});

const setCurrentApplication = (currentApplication) => ({
  type: Types.SET_CURRENT_APPLICATION,
  currentApplication
});

const setIsCopyPastePopoverOpen = (isCopyPastePopoverOpen) => ({
  type: Types.SET_COPY_PASTE_POPOVER,
  isCopyPastePopoverOpen
});

const setIsExpressEnrollFormOpen = (isExpressEnrollFormOpen) => ({
  type: Types.SET_EXPRESS_ENROLL_FORM_OPEN,
  isExpressEnrollFormOpen
});

const setSelectedJourneyEvent = (selectedJourneyEvent) => ({
  type: Types.SET_SELECTED_JOURNEY_EVENT_HANDLER,
  selectedJourneyEvent
});

const approveInsuranceOpportunityAction = (insuranceId, reason) => ({
  type: Types.APPROVE_INSURANCE_OPPORTUNITY_ACTION,
  insuranceId,
  reason
});

const patientCoveragesAction = (patientId) => ({
  type: Types.PATIENT_COVERAGES_ACTION,
  patientId
});

const patientUnknownAndInactiveInsuranceInfo = (patientId) => ({
  type: Types.PATIENT_UNKNOWN_AND_INACTIVE_INSURANCE_ACTION,
  patientId
});

const patientCoverages = (coverages) => ({
  type: Types.PATIENT_COVERAGES,
  coverages
});

const policiesAction = (queryIds) => ({
  type: Types.POLICIES_ACTION,
  queryIds
});

const policies = (policies) => ({
  type: Types.POLICIES,
  policies
});

const shippingOptions = (shippingOptions) => ({
  type: Types.PATIENT_SHIPPING_OPTIONS,
  shippingOptions
});

const docusignCreateAction = (data, applicationFormId, applicationId) => ({
  type: Types.DOCUSIGN_CREATE_ACTION,
  data,
  applicationFormId,
  applicationId
});

const setApplicationForm = (applicationForm) => ({
  type: Types.SET_APPLICATION_FORM,
  applicationForm
});

const fetchApplicationFormSignatures = (applicationFormId) => ({
  type: Types.FETCH_APPLICATION_FORM_SIGNATURES,
  applicationFormId
});

const refetchAllApplications = () => ({
  type: Types.REFETCH_ALL_APPLICATIONS
});

const fetchPapPDFMetaData = (potentialSavingId, applicationId) => ({
  type: Types.FETCH_PAP_PDF_METADATA,
  potentialSavingId,
  applicationId
});

const setExpressEnrollmentConfiguration = (expressEnrollmentConfiguration) => ({
  type: Types.SET_EXPRESS_ENROLLMENT_CONFIGURATION,
  expressEnrollmentConfiguration
});

const setApplicationAttachments = (applicationAttachments) => ({
  type: Types.SET_APPLICATION_ATTACHMENTS,
  applicationAttachments
});

const getApplicationAttachments = (applicationId) => ({
  type: Types.GET_APPLICATION_ATTACHMENTS,
  applicationId
});

const applicationEditAction = ({
  papId,
  papData,
  patientId,
  journeyId,
  apaId,
  afterApplicationUpdate,
  applicationForm
}) => ({
  type: Types.APPLICATION_EDIT_ACTION,
  papId,
  papData,
  patientId,
  journeyId,
  apaId,
  afterApplicationUpdate,
  applicationForm
});

const applicationEditHistoricalAction = (patientId, journeyId, papId, applications) => ({
  type: Types.APPLICATION_EDIT_HISTORICAL_ACTION,
  patientId,
  journeyId,
  papId,
  applications
});
const applicationDeleteAction = (papData, patientId, journeyId, papId, applicationId) => ({
  type: Types.APPLICATION_DELETE_ACTION,
  papData,
  patientId,
  journeyId,
  papId,
  applicationId
});

const searchSavedListAction = (page) => ({
  type: Types.SEARCH_SAVED_LIST_ACTION,
  page
});

const getAllClaimsAction = (applicationId) => ({
  type: Types.GET_ALL_CLAIMS,
  applicationId
});

const initSavedListAction = () => ({
  type: Types.INIT_SAVED_LIST
});

const searchSavedListByIdAction = (data) => ({
  type: Types.SEARCH_SAVED_LIST_BY_ID,
  data
});

const clearSorterAction = (view) => ({
  type: Types.CLEAR_SORTER,
  view
});

const createFilterListAction = (data) => ({
  type: Types.CREATE_FILTER_LIST_ACTION,
  data
});

const createClaimAction = (data, attachmentsList, journeyId, potentialSavingId) => ({
  type: Types.CREATE_CLAIM_ACTION,
  data,
  attachmentsList,
  journeyId,
  potentialSavingId
});

const duplicateClaimAction = (data, journeyId, potentialSavingId, attachmentsList) => ({
  type: Types.DUPLICATE_CLAIM_ACTION,
  data,
  journeyId,
  potentialSavingId,
  attachmentsList
});

const updateFilterListAction = (data, filterListId) => ({
  type: Types.UPDATE_FILTER_LIST_ACTION,
  data,
  filterListId
});

const updateClaimAction = (data, claimId, attachmentsList, journeyId, potentialSavingId) => ({
  type: Types.UPDATE_CLAIM_ACTION,
  data,
  claimId,
  attachmentsList,
  journeyId,
  potentialSavingId
});

const archiveClaimAction = (patientId, claim) => ({
  type: Types.TOGGLE_CLAIM_ACTIVE_STATUS_ACTION,
  patientId,
  claim,
  isActive: false
});

const restoreClaimAction = (patientId, claim) => ({
  type: Types.TOGGLE_CLAIM_ACTIVE_STATUS_ACTION,
  patientId,
  claim,
  isActive: true
});

const potentialSaving = (papData, appId) => ({
  type: Types.POTENTIAL_SAVING,
  papData,
  appId
});

const patientReapplyAction = (papData, patientId, journeyId) => ({
  type: Types.PATIENT_REAPPLY_ACTION,
  papData,
  patientId,
  journeyId
});

const patientCreateApplicationAction = (papData, patientId, journeyId, status) => ({
  type: Types.PATIENT_CREATE_APPLICATION_ACTION,
  papData,
  patientId,
  journeyId,
  status
});

const applicationRelevanceForRenewal = (papId, applicationId, isIrrelevant) => ({
  type: Types.APPLICATION_RELEVANCE_FOR_RENEWAL,
  papId,
  applicationId,
  isIrrelevant
});

const updateApplicationRelevanceAction = (papId, isIrrelevantForRenewal) => ({
  type: Types.UPDATE_APPLICATION_RELEVANCE,
  papId,
  isIrrelevantForRenewal
});

const getShippingActionAction = (patientId, papId) => ({
  type: Types.GET_SHIPPING_OPTIONS,
  patientId,
  papId
});

const setIDPConfig = (idpConfig) => ({
  type: Types.SET_IDP_CONFIG,
  idpConfig
});

const getPotentialSavings = () => ({
  type: Types.GET_POTENTIAL_SAVINGS
});

const getPotentialSaving = (papId, appId) => ({
  type: Types.GET_POTENTIAL_SAVING,
  papId,
  appId
});

const setOktaConfig = (oktaClientId) => ({
  type: Types.SET_OKTA_CONFIG,
  oktaClientId
});

const updateEnrollmentStore = (key, value) => ({
  type: Types.UPDATE_ENROLLMENT_STORE,
  key,
  value
});
const resetEnrollmentStore = () => ({
  type: Types.RESET_ENROLLMENT_STORE
});

const resetApplicationFormSignature = (signerType) => ({
  type: Types.RESET_APPLICATION_FORM_SIGNATURE,
  signerType
});

const setApplicationFormSignature = (signerType, applicationFormSignature) => ({
  type: Types.SET_APPLICATION_FORM_SIGNATURE,
  signerType,
  applicationFormSignature
});

const setIsApplicationFormModalVisible = (isVisible) => ({
  type: Types.SET_APPLICATION_FORM_MODAL_VISIBILITY,
  isVisible
});

export default {
  logout,
  setEnvironment,
  setTier,
  setUser,
  setUsers,
  setNetworkAction,
  searchAssistanceProgramAction,
  searchAssistanceProgramByIdAction,
  setSelectedAssistanceProgramById,
  clearSelectedAssistanceProgramById,
  setAssistancePrograms,
  setShowAssistanceProgramModal,
  setShowExpressEnrollModal,
  setShowSupport,
  setTableColumns,
  clearSelectedView,
  setSelectedView,
  markSavedFilterAsChanged,
  updateSavedFilterViewParameters,
  setIsPhiAllowed,
  setSettings,
  searchPatientsAction,
  changeProviderNetworkAction,
  setSearchedPatients,
  clearSearchedPatients,
  setPatients,
  setTableDataAction,
  paginateSortChange,
  setTablesData,
  setSelectedFilterListType,
  setPatientTableRecordData,
  updateSearchedPatient,
  setSavedListsTableRecordData,
  setAssignees,
  setUserGroups,
  setFilterList,
  searchSavedListByIdAction,
  clearSorterAction,
  getPatientAction,
  getPatientDetailsAction,
  getPatientJourneyAction,
  updatePatientDetailsAction,
  clearPatient,
  patientDetails,
  providersAction,
  provider,
  APSponsors,
  setUnits,
  setPatientUnknownAndInactiveInsuranceInfo,
  setVisitTypes,
  potentialSavings,
  setFormPopups,
  setApplicationClaims,
  clearApplicationClaims,
  setFilterByClaimStatus,
  addDrugAction,
  setFilters,
  setFiltersAction,
  setListNameQueryAction,
  updateSavedListSortBy,
  updateSavedListOrderBy,
  clearFilters,
  setFiltersInputs,
  applyServerFiltersAction,
  setMultipleFiltersInputs,
  clearFiltersInputs,
  clearSavedListRelatedDataAction,
  resetSavedListViewFilters,
  setSummaryCounters,
  getSummaryCounters,
  patientJourney,
  genericServicesAction,
  genericServices,
  patientAutomateAction,
  patientOpportunity,
  updateFilterListStatuses,
  updateDiscontinuedDateAction,
  potentialSavingsJourneyAction,
  potentialSavingsJourney,
  setCurrentApplication,
  setIsCopyPastePopoverOpen,
  setIsExpressEnrollFormOpen,
  setSelectedJourneyEvent,
  approveInsuranceOpportunityAction,
  patientCoveragesAction,
  patientUnknownAndInactiveInsuranceInfo,
  patientCoverages,
  policiesAction,
  policies,
  setIsSidebarCollapsed,
  setShouldReturnToRelevantPatient,
  shippingOptions,
  getShippingActionAction,
  setApplicationForm,
  setExpressEnrollmentConfiguration,
  setApplicationAttachments,
  getApplicationAttachments,
  docusignCreateAction,
  applicationEditAction,
  applicationEditHistoricalAction,
  applicationDeleteAction,
  searchSavedListAction,
  getAllClaimsAction,
  createFilterListAction,
  createClaimAction,
  duplicateClaimAction,
  updateFilterListAction,
  updateClaimAction,
  archiveClaimAction,
  restoreClaimAction,
  potentialSaving,
  patientReapplyAction,
  patientCreateApplicationAction,
  applicationRelevanceForRenewal,
  updateApplicationRelevanceAction,
  updateOOPEstimation,
  getOOPEstimations,
  setOOPEstimations,
  setFormItemsDiagnoses,
  setIDPConfig,
  initSavedListAction,
  getPotentialSavings,
  getPotentialSaving,
  setOktaConfig,
  updateEnrollmentStore,
  resetEnrollmentStore,
  fetchApplicationFormSignatures,
  fetchPapPDFMetaData,
  resetApplicationFormSignature,
  setApplicationFormSignature,
  refetchAllApplications,
  setShowSecureUpload,
  setIsApplicationFormModalVisible,
  setBadgeCounts,
  Types,
  ...commonActions
};
