import styled from "styled-components";
import { GRAY_300, GRAY_900, PRIMARY_50 } from "../../constant/colors";
import { fontWeights, sizes } from "../../constant/styles";

export const StyledNewPatientHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: ${PRIMARY_50};
  border-radius: 12px 12px 0px 0px;
  border-color: ${GRAY_300};
  border-bottom: 1px solid ${GRAY_300};
  font-size: ${sizes.large};
  font-weight: ${fontWeights.semibold};

  .ant-typography {
    margin: 0;
    color: ${GRAY_900};
  }
`;
