import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { createStructuredSelector } from "reselect";
import { Typography } from "antd";
import { CloseOutlined, FolderOutlined } from "@ant-design/icons";
import { useParams } from "react-router";

import { Uploader } from "../Uploader";
import { selectPatientDetails } from "../../store/selector";
import { getPatientAttachmentsCountAPI } from "../../api/api";
import { Popover } from "../UI/Antd/Popover/Popover";
import { sizes } from "../../constant/styles";
import { getPopupPlacement } from "../../utils/patient";

const { Text } = Typography;

const PatientAttachmentsMenu = ({ t, patientDetails }) => {
  const { patientId } = useParams();

  const [count, setCount] = useState(null);
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("bottomRight");

  useEffect(() => {
    getAttachmentsCount();
  }, []);

  const getAttachmentsCount = async () => {
    try {
      const res = await getPatientAttachmentsCountAPI(patientId);
      if (res && res.data && res.data.attachmentsCount) {
        setCount(res.data.attachmentsCount);
      }
    } catch (error) {
      console.log(`Get attachments count failed`);
    }
  };

  const handleOpenChange = (visible) => {
    if (visible) {
      getPopupPlacement(setPlacement, "patient_attachments");
    }
    setVisible(visible);
  };

  return (
    <Popover
      trigger="click"
      placement={placement}
      id="patient_attachments_popover"
      open={visible}
      onOpenChange={handleOpenChange}
      title={
        <div>
          <Text style={{ fontSize: sizes.medium_large }}>{`Attachments for ${
            patientDetails ? patientDetails.name : ""
          }`}</Text>
          <CloseOutlined id="close_patient_attachments_popover" onClick={() => setVisible(false)} />
        </div>
      }
      content={
        <Uploader
          getAttachmentsCount={getAttachmentsCount}
          showTopLogo={false}
          formStyle={{ border: "none", boxShadow: "none", width: "100%" }}
          wrapperStyle={{ width: "100%" }}
          isModalMode={false}
        />
      }
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      <div
        id="patient_attachments"
        style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
      >
        <FolderOutlined style={{ fontSize: 16, paddingRight: 3 }} />
        <Text>{`${t("patientRSActions.attachments")}${count ? ` (${count})` : ""}`}</Text>
      </div>
    </Popover>
  );
};

const mapStateToProps = createStructuredSelector({
  patientDetails: selectPatientDetails
});

const Attachments = compose(withTranslation(), connect(mapStateToProps))(PatientAttachmentsMenu);
export { Attachments };
