import React from "react";
import { useTranslation } from "react-i18next";

import ReactExport from "react-export-excel";
import { Button, Tooltip, Typography } from "antd";

import { dateTimeFormatter, DATE_FORMAT, DATE_TIME_FILTERS_FORMAT } from "../../utils/date";
import { GRAY_700, PRIMARY_600 } from "../../constant/colors";
import { ExportOutlined } from "@ant-design/icons";
import { formatAssignee } from "../../utils/formaters";
import { useSelector } from "react-redux";
import { selectAssignees } from "../../store/selector";
import { fontWeights, sizes } from "../../constant/styles";
import styled from "styled-components";

const ExcelFile = ReactExport.ExcelFile;
const { ExcelSheet, ExcelColumn } = ExcelFile;
const { Text } = Typography;
const CHECKED = "checked";

const StyledButton = styled(Button)`
  background: unset;

  :active,
  :hover {
    background: unset;
  }
`;

const ExportTasksData = ({ data, isPatientView }) => {
  const { t } = useTranslation();
  const assignees = useSelector(selectAssignees);

  return (
    <ExcelFile
      filename={`${t("patientsTasks.export.fileNamePrefix")}_[${dateTimeFormatter(Date(), DATE_TIME_FILTERS_FORMAT)}]`}
      element={
        !isPatientView && (
          <Tooltip
            title={
              <Text style={{ color: GRAY_700, fontSize: sizes.small, fontWeight: fontWeights.semibold }}>
                {t("patientsTasks.export.download_tasks")}
              </Text>
            }
            color={"white"}
            placement="right"
          >
            <StyledButton id="export_tasks_button" type="text">
              <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                <ExportOutlined style={{ color: PRIMARY_600 }} />
              </div>
            </StyledButton>
          </Tooltip>
        )
      }
    >
      <ExcelSheet data={data} name={t("patientsTasks.export.sheetName")}>
        <ExcelColumn
          label={t("patientsTasks.export.due_date")}
          value={({ dueDate }) => (dueDate ? dateTimeFormatter(dueDate, DATE_FORMAT) : null)}
        />
        <ExcelColumn
          label={t("patientsTasks.export.assignee")}
          value={({ assignee }) => assignees[assignee] && formatAssignee(assignees[assignee])}
        />
        <ExcelColumn label={t("patientsTasks.export.task")} value="text" />
        <ExcelColumn label={t("patientsTasks.export.patient_id")} value="patient" />
        <ExcelColumn label={t("patientsTasks.export.patient_mrn")} value="mrn" />
        <ExcelColumn label={t("patientsTasks.export.patient_name")} value="patientFullName" />
        <ExcelColumn label={t("patientsTasks.export.assistance_program")} value="assistanceProgram" />
        <ExcelColumn
          label={t("patientsTasks.export.status")}
          value={({ status }) => t(status === CHECKED ? "patientsTasks.export.done" : "patientsTasks.export.not_done")}
        />
        <ExcelColumn
          label={t("patientsTasks.export.updated_at")}
          value={({ updatedAt }) => (updatedAt ? dateTimeFormatter(updatedAt, DATE_FORMAT) : null)}
        />
        <ExcelColumn label={t("patientsTasks.export.updated_by")} value="updatedBy" />

        <ExcelColumn
          label={t("patientsTasks.export.created_at")}
          value={({ createdAt }) => (createdAt ? dateTimeFormatter(createdAt, DATE_FORMAT) : null)}
        />
        <ExcelColumn label={t("patientsTasks.export.created_by")} value="createdBy" />
        <ExcelColumn
          label={t("patientsTasks.export.completed_at")}
          value={({ completedAt }) => (completedAt ? dateTimeFormatter(completedAt, DATE_FORMAT) : null)}
        />
        <ExcelColumn label={t("patientsTasks.export.completed_by")} value="completedBy" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ExportTasksData;
