import React from "react";

import SectionPart from "../../../../components/SectionPart";
import { formSectionsController, vendors } from "../../../../formSectionsController";
import { ConfigurableRow, ROW_OPTIONS } from "../../../../ConfigurableRow";
import { novartisSectionsController } from "../../novartisSectionsController";
import { cosentyxController } from "../../cosentyxController";
import { getConditionOptions } from "../conditionOptions";

export default function NovartisCopayCondition() {
  const conditionFormComponents = formSectionsController().getSectionFieldsByVendor(vendors.NOVARTIS_COPAY)().condition;
  const controller = cosentyxController();
  const sectionsController = novartisSectionsController();
  const associatedDrug = sectionsController.associatedDrug;
  return (
    <SectionPart
      sectionId="condition"
      headerTranslationKey="application_form_editor.novartis.copay.condition.labels.header"
    >
      <ConfigurableRow config={ROW_OPTIONS.EQUAL}>
        {[
          conditionFormComponents.primaryCondition({
            required: true,
            radioOptions: getConditionOptions(associatedDrug, controller.isPatientBelow18)
          })
        ]}
      </ConfigurableRow>
    </SectionPart>
  );
}
